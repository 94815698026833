import React from 'react'
import styled from 'styled-components'
import { ChevronDownIcon,Button, useModal,Modal, RemoveIcon } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import RomveDlg from './RomveDlg'



interface RemoveBtnProps {
  deprecated: boolean
  pairAddress?: string
  ids:any
  removedAmounts:any
  amount:string
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 0px;
  }
`



const RemoveBtn: React.FC<RemoveBtnProps> = ({deprecated, ids,removedAmounts,pairAddress,amount }) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const [onPresentSettings] = useModal(<RomveDlg deprecated={deprecated} amount={amount} ids={ids} pairAddress={pairAddress} removedAmounts={removedAmounts}  />)

  return (<>
           {!isDesktop? <span title={TranslateString(11134,"Remove")}><RemoveIcon style={{cursor:'pointer',width:!isDesktop?'12px':""}} onClick={onPresentSettings}/></span>:
            <Button  variant="secondary"  size="sm" onClick={onPresentSettings}> 
            {TranslateString(11134,"Remove")}
          </Button>
         }
  </>
  )
}

export default RemoveBtn
