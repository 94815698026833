import { createReducer } from '@reduxjs/toolkit'
import setRefreshlist from './actions'

export interface griDexSwapState {
  readonly refreshLiquidity: boolean
}

const initialState: griDexSwapState = {
  refreshLiquidity:false
}

export default createReducer<griDexSwapState>(initialState, (builder) =>
  builder
    .addCase(setRefreshlist, (state, { payload:{refreshLiquidityState}}) => {
      return {...state,refreshLiquidity:refreshLiquidityState}
    })
)
