import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { BscConnector } from './bsc/bscConnector'

const rpcNodes = {
  '0': process.env.REACT_APP_NETWORK_URL,
  '1': process.env.REACT_APP_NETWORK_URL_BACKUP1,
  '2': process.env.REACT_APP_NETWORK_URL_BACKUP2,
  '3': process.env.REACT_APP_NETWORK_URL_BACKUP3,
  '4': process.env.REACT_APP_NETWORK_URL_BACKUP4,
  '5': process.env.REACT_APP_NETWORK_URL_BACKUP5
}
const DEFAULT_RPC = rpcNodes['2'];

const rpcNodeCacheKey = "USER_SELECTED_RPC_NODE_23081823";
const getOverride = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const p = urlParams.get('rpcnode');
  if (p && rpcNodes[p]) {
      localStorage.setItem(rpcNodeCacheKey, p);
      return p;
  }
  return localStorage.getItem(rpcNodeCacheKey);
}
export const getNodeUrl = () => {
  const q = getOverride();
  if (q && rpcNodes[q]) return rpcNodes[q];
  return DEFAULT_RPC;
}

const NETWORK_URL = getNodeUrl()
console.log("[RPC]", NETWORK_URL);
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [10000],
})

export const bsc = new BscConnector({ supportedChainIds: [10000] })

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 10000: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
})
