import { getAvggerAddress, getBchAddress } from 'utils/addressHelpers';
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { useWeb3React } from '@web3-react/core';
import { useActiveWeb3React } from 'hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import Web3 from 'web3';
import {Subscription} from 'web3-core-subscriptions';
import {BlockHeader} from 'web3-eth';
import { AbiItem } from 'web3-utils'
import { getNodeUrl } from 'connectors/index';
import getLimitExResult, { limitExSwap } from 'utils/limitex/limitex';
import BigNumber from 'bignumber.js';
import aggregateDexAbi from '../../../constants/abis/aggregateDex.json'



const NetWorkUrl = getNodeUrl()

const useCreateSmartSwap = () => {
    const { account,library, chainId} = useWeb3React()
    const web3 = new Web3(Number(chainId) === ChainId.MAINNET && library?.provider || new Web3.providers.HttpProvider(NetWorkUrl));
    const myContract=useMemo(()=>{
        return new web3.eth.Contract((aggregateDexAbi as unknown) as AbiItem,getAvggerAddress())
    },
    [web3.eth.Contract])

    const refTimer = useRef<any>();
    const subBlock = useCallback((refFuc):Subscription<BlockHeader> => {
         const  subscription=web3.eth.subscribe('newBlockHeaders',(error, result)=>{
            if (!error) {
                console.log("新区块：：",result);
                if (refTimer.current) {
                    clearTimeout(refTimer.current);
                }
                refTimer.current = setTimeout(() => {
                    refFuc();
                }, 1000);

                return;
            }
            console.error(error);
         })
      
         return subscription;
      },
      [web3.eth],
    )

    const getExpectedReturn = useCallback(
        async (fromToken:string,destToken:string,amount:string,formDecimals,toDecimals) => {
            try {
                const res:any=await getLimitExResult(fromToken, destToken, formDecimals, toDecimals, amount)
                return {
                    ...res,
                    amountIn:res?.amountIn||'0',
                    dealOrders:res?.dealOrders||[],
                    tranAmountOut:res?.amountOut?new BigNumber(res?.amountOut).div(new BigNumber(10).pow(toDecimals)).toFixed():""
                }
            } catch (e) {
                return false
            }
        },
        [],
    )
    const swap = useCallback(
         async (
            expectedData:{
            pairAddr:string,
            amountOut:string,
            orderIdList: Array<any>,
            availableAllownances: any,
            amountIn: string,
            tranAmountOut:string
         },
         sureFuc:(hash:string)=>void,
         allowPartDeal:boolean) => {
            try {
                const txHash:any=await limitExSwap(account,expectedData.pairAddr,expectedData.amountOut, expectedData.orderIdList, expectedData.availableAllownances,allowPartDeal,sureFuc)
                return [true,txHash]
            } catch (e) {
                return [false,e]
            }
        },
        [account],
    )
    return {getExpectedReturn,swap,subBlock};
}
export default useCreateSmartSwap
