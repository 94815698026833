import { BigNumber, ethers } from "ethers";
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import Web3 from 'web3'
import BigNumber_ from 'bignumber.js'
import toPrecision from "utils/getPrecision";
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { abi as IUniswapV2Factory } from '@uniswap/v2-core/build/IUniswapV2Factory.json'
import { FACTORY } from "constants/factory";
import ABI from './abi.json'
import { isBch, PriceBase, WBCH } from '../gidex/gridex';
import { getNodeUrl } from '../../connectors/index';

function getWeb3() {
    return new Web3(Number(window.web3?.currentProvider?.chainId) === ChainId.MAINNET && window.web3.currentProvider || new Web3.providers.HttpProvider(getNodeUrl()));
}

export function packPrice(price: string | number) {
    switch (typeof price) {
        case 'string': return packPrice_(ethers.utils.parseUnits(price, 18));
        case "number": return packPrice_(ethers.utils.parseUnits(price.toString(), 18));
        default: throw new Error(`invalid price: "${price}`);
    }
}
export function unpackPrice(price: any) {
    return unpackPrice_(ethers.BigNumber.from(price));
}



function packPrice_(price: any) {
    let effBits = 1
    while (!price.mask(effBits).eq(price)) {
        effBits += 1
    }
    const twoPow48 = ethers.BigNumber.from(2).pow(48)
    if (effBits <= 49) {
        return price
    }
    const shift = effBits - 49
    const shiftBN = ethers.BigNumber.from(2).pow(shift)
    const low48 = price.div(shiftBN).sub(twoPow48)
    const high8 = ethers.BigNumber.from(shift).add(1).mul(twoPow48)
    return high8.add(low48)
}

function unpackPrice_(packed: any) {
    const twoPow48 = ethers.BigNumber.from(2).pow(48)
    const low48 = packed.mod(twoPow48)
    const shift = packed.div(twoPow48)
    if (shift.isZero()) {
        return low48
    }
    const shiftBN = ethers.BigNumber.from(2).pow(shift.sub(1))
    return low48.add(twoPow48).mul(shiftBN)
}

export const UNIT = ethers.constants.WeiPerEther

const FACTORIES = Object.entries(FACTORY).map(([_, { address }]) => address)
const MID_TOKENS = [
    "0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04",
    "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B",
    "0x0b00366fBF7037E9d75E4A569ab27dAB84759302",
    "0x73BE9c8Edf5e951c9a0762EA2b1DE8c8F38B5e91",
    "0x5fA664f69c2A4A3ec94FaC3cBf7049BD9CA73129",
]

export async function getPrice(tokenA: string, tokenB: string, tokenADecimals: number, tokenBDecimals: number) {
    if (isBch(tokenA)) {
        tokenA = WBCH
    }
    if (isBch(tokenB)) {
        tokenB = WBCH
    }
    const price = await new Promise((resolve, reject) => {
        const promise = getPriceWithoutMidtoken(tokenA, tokenB, tokenADecimals, tokenBDecimals).then(p => p && resolve(p)).catch(reject)
        Promise.all([promise, getPriceByMidtokens(tokenA, tokenB, tokenADecimals, tokenBDecimals)]).then(([_, p]) => resolve(p)).catch(reject)
    })
    if (Number(price) !== 0) {
        return price
    }
    for (let index = 0; index < MID_TOKENS.length; index++) {
        const midToken = MID_TOKENS[index]
        const [p1, p2] = await Promise.all([getPriceByMidtokens(tokenA, midToken, tokenADecimals, 18), getPriceWithoutMidtoken(midToken, tokenB, 18, tokenBDecimals)])
        if (Number(p1) !== 0 && Number(p2) !== 0) {
            return Number(p1) * Number(p2)
        }
    }
    return "0"
}
async function getPriceWithoutMidtoken(tokenA, tokenB, tokenADecimals, tokenBDecimals) {
    const maxReserves = await getMaxReserves(tokenA, tokenB)
    if (!maxReserves) {
        return null
    }
    return new BigNumber_(maxReserves?.tokenBReserve).div(maxReserves?.tokenAReserve).dividedBy(10 ** (tokenBDecimals - tokenADecimals)).toString()
}

async function getPriceByMidtokens(tokenA, tokenB, tokenADecimals, tokenBDecimals) {
    const validMidTokens = MID_TOKENS.filter(midToken => ![tokenA, tokenB].includes(midToken))
    let maxReservesArrByMidTokens = await Promise.all(
        validMidTokens.map(async midToken => {
            const [maxReserves0, maxReserves1] = await Promise.all([getMaxReserves(tokenA, midToken), getMaxReserves(midToken, tokenB)])
            if (!maxReserves0 || !maxReserves1) {
                return null
            }
            return {
                maxReserves0, maxReserves1
            }
        }))
    maxReservesArrByMidTokens = maxReservesArrByMidTokens.filter(v => v)
    if (!maxReservesArrByMidTokens.length) {
        return 0
    }
    const maxTokenAReserve = BigNumber_.max.apply(null, maxReservesArrByMidTokens.map(v => v!.maxReserves0?.tokenAReserve)).toString()
    const maxReserves = maxReservesArrByMidTokens.find(x => x!.maxReserves0?.tokenAReserve === maxTokenAReserve)
    const price = new BigNumber_(maxReserves?.maxReserves1.tokenBReserve).dividedBy(maxReserves?.maxReserves1.tokenAReserve)
        .dividedBy(new BigNumber_(maxReserves?.maxReserves0.tokenAReserve).dividedBy(maxReserves?.maxReserves0.tokenBReserve))
    return price.dividedBy(10 ** (tokenBDecimals - tokenADecimals)).toString()
}

async function getMaxReserves(tokenA, tokenB) {
    const reservesArr = await getReservesArr(tokenA, tokenB)
    if (!reservesArr.length) {
        return null
    }
    const maxReserve0 = BigNumber_.max.apply(null, reservesArr.map((v: any) => v.tokenAReserve)).toString()
    const maxReserves = reservesArr.find(x => x.tokenAReserve === maxReserve0)
    return maxReserves
}

async function getReservesArr(tokenA, tokenB) {
    const web3 = getWeb3();
    let pairs = await Promise.all(FACTORIES.map(async factory => {
        const contract = new web3.eth.Contract(IUniswapV2Factory as any, factory)
        const pair = await contract.methods.getPair(tokenA, tokenB).call({ gas: 10000000000000000 })
        return pair
    }))
    pairs = pairs.filter(pair => pair !== ethers.constants.AddressZero)

    const needSort = tokenA.toLowerCase() > tokenB.toLowerCase()
    const data = await Promise.all(pairs.map(async pair => {
        const contract = new web3.eth.Contract(IUniswapV2PairABI as any, pair)
        const result = await contract.methods.getReserves().call({ gas: 10000000000000000 })
        const { reserve0, reserve1 } = result
        return !needSort ? { tokenAReserve: reserve0, tokenBReserve: reserve1 } : { tokenAReserve: reserve1, tokenBReserve: reserve0 }
    }))
    return data
}

// getPrice("0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B", "0x73BE9c8Edf5e951c9a0762EA2b1DE8c8F38B5e91", 18, 18).then(data => console.log("data", data)).catch(console.error)