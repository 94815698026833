import { Card, Flex,Text,Button} from '@pancakeswap-libs/uikit'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from 'hooks'
import useRefresh from 'hooks/useRefresh'
import  useGetLiquidityList, { useGetGridInfos }  from './hooks/useGetLiquidityList'
import ListContent from './component/ListContent'
import { useGriLiquidityListState, useGriLiquidityListStateHandlers } from '../../state/griLiquidityList/hooks'





const StylePanle = styled(Flex)`
      /* background-color:${({ theme })=>theme.colors.background}; */
      border-radius: 10px;
      border:1px solid ${({ theme }) => theme.colors.borderColor};
      overflow: hidden;
`
const StyleRow20 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:20%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`
const StyleRow25 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:25%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`
const StyleDown = styled(Text)`
    display: flex;
    align-items: center;
    width:5%;
`
const ChildenTh = styled(Flex)`
   background-color:${({ theme })=>theme.colors.background};
`
const StyleTh = styled(Flex)`
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   background-color:${({ theme })=>theme.colors.background};
`
const StyleRow35 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:35%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`

const LiquidityCard: React.FC= () => {
    const TranslateString = useI18n();
    const { fastRefresh } = useRefresh();
    const { isDesktop } = useMatchBreakpoints();
    const {refreshLiquidity} = useGriLiquidityListState();
    const {onRefreshLiquidity} = useGriLiquidityListStateHandlers();
    const { data } = useGetLiquidityList(refreshLiquidity,fastRefresh);
    const { gridInfos} = useGetGridInfos(refreshLiquidity,data);

    const { account } = useActiveWeb3React();


//     useEffect(()=>{
//       onRefreshLiquidity(true);
//       setTimeout(()=>{onRefreshLiquidity(false);},1000)
//    },[account,onRefreshLiquidity])

//     useEffect(()=>{
//        console.log("LiquidityList",data)
//     },[data])

    return <>
                      <Flex p={2}>
                          <Text fontSize='14px'>{TranslateString(11129,"Your Positions")} ({account ? (data ? data.length : `${TranslateString(10130,"Loading")}...`):0})</Text>
                      </Flex>
                      <Flex p={2} mt={2}>
                      <StylePanle flex={1} flexDirection="column" >
                              <StyleTh flex={1} p={2}>
                                    <StyleDown/>
                                    <StyleRow35 style={{width:isDesktop?"40%":""}} isMobile={!isDesktop}>{TranslateString(11130,"Trade Pair")}</StyleRow35>
                                    <StyleRow25 style={{width:isDesktop?"10%":""}} isMobile={!isDesktop}>{TranslateString(11131,"Fee")}</StyleRow25>
                                    <StyleRow20 style={{width:isDesktop?"25%":""}} isMobile={!isDesktop}>{TranslateString(11132,"Amount")}</StyleRow20>
                                    <StyleRow20 style={{width:isDesktop?"30%":""}} isMobile={!isDesktop}>{TranslateString(11133,"Operation")}</StyleRow20>
                              </StyleTh>
                              {data && data.length>0  && data.map((t,idx)=>{
                                    // const infos:Array<any>=gridInfos.filter(f=>f.address===t.address);
                                    // // infos[3].dayAPY=0.05;
                                    // // infos[3].weekAPY=0.07;
                                    // // infos[3].yearAPY=0.08;
                                    // // console.log("infos",infos)
                                    return <ListContent gridInfos={gridInfos} key={`id-${t.stock}-${t.money}`} item={t}/>
                              })}
                        </StylePanle>
                      </Flex>
    </>
    
    
 
}
export default LiquidityCard



