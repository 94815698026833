import { getAvggerAddress, getBchAddress, getGridexAvggerAddress } from 'utils/addressHelpers';
import { useWeb3React } from '@web3-react/core';
import { useActiveWeb3React } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import 'utils/limitex/limitex';
import { ERC20_ABI } from 'constants/abis/erc20';
import multicall from 'utils/multicall';
import { ethers } from 'ethers';
import { useLimitListState } from 'state/limit/hooks';
import useWeb3 from 'hooks/useWeb3';
import { useERC20, useLimitExContract, useLimitFactoryContract, useP2pEx, useP2pFactoryEx } from '../../../hooks/useContractV2';
import { useAllowance } from '../../../hooks/useAllowance';
import { useTokenApprove } from '../../../hooks/useApprove';
import { UNIT, getPrice, packPrice } from '../../../utils/limitex/price';
import toPrecision from '../../../utils/getPrecision';
import { useP2pListState } from '../../../state/p2pEx/hooks';




export const useGetPrice = (tokenA?: string, tokenB?: string,tokenADecimals?:number,tokenBDecimals?:number) => {
    const [rate, setRate] = useState<any>(0);
    const [isPedding, setIsPedding] = useState<boolean>(false);
    const getPriceFuc = useCallback(async () => {
        setIsPedding(true);
        const res = await getPrice(tokenA||'', tokenB||'',tokenADecimals||0,tokenBDecimals||0);
        setRate(toPrecision(new BigNumber(res as string), 2));
        setIsPedding(false);
    }, [tokenA, tokenB,tokenADecimals,tokenBDecimals])
    useEffect(() => {
        if (tokenA && tokenB) {
            getPriceFuc();
        }
    }, [tokenA, tokenB, getPriceFuc])

    return { rate,isPedding, getPriceFuc, setRate };
}


export const useGetOrderInfo = (orderId:string): { data: any,isMoreStatus:boolean, orderPedding: boolean,readMorePedding:boolean } => {
    const web3 = useWeb3();
    const { account } = useWeb3React();
    const [isMoreStatus, setIsReadMore] = useState<boolean>(false);
    const [readMorePedding, setReadMorePedding] = useState<boolean>(true);
    const [orderPedding, setOrderPedding] = useState<boolean>(true);

    const [data, setData] = useState<Array<any>>([]);
    const { refresh } = useP2pListState();
    const filter=useRef<string>("");

    useEffect(() => {
        if ((orderId && orderId!=='') ||  refresh === true || account) {
            setOrderPedding(false);


            fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-p2pex`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "query": `{orders(orderDirection: desc,orderBy: createBlockNumber,
                    where:{orderId:"${web3.utils.toHex(orderId)}"}
                    ){\n    target,\n orderId,taker,   amount,\n    filled,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n,stock,money     }\n   }\n}`,
                    "variables": null,
                    "operationName": null
                })
            })
            .then(res => res.json())
                .then(async (r: any) => {
                    const orders = r?.data?.orders || []
                    const tokens = Array.from(new Set([].concat(...orders.map(order => [order.pair.stock, order.pair.money]))))
                    const decimals = (await multicall(ERC20_ABI, tokens.map((v) => ({
                        address: v,
                        name: 'decimals',
                    })))).map((v) => parseInt(v))

                    for (let index = 0; index < orders.length; index++) {
                        const order = orders[index];
                        const fromTokenDecimals = decimals[tokens.findIndex(x => x === order.pair.stock)]
                        const destTokenDecimals = decimals[tokens.findIndex(x => x === order.pair.money)]

                        let priceMul: any = 1;
                        let priceDiv: any = 1;
                        if (destTokenDecimals >= fromTokenDecimals) {
                            priceMul = (10 ** (destTokenDecimals - fromTokenDecimals)).toString();
                        } else {
                            priceDiv = (10 ** (fromTokenDecimals - destTokenDecimals)).toString();
                        }
                        const reutrnAmount = ethers.BigNumber.from(order.price).mul(priceMul).mul(order.amount).div(UNIT.mul(priceDiv)).toString()
                 

                        order.moneyAmount = new BigNumber(order.amount).div(new BigNumber(10).pow(fromTokenDecimals)).toString();
                        order.stockAmount=new BigNumber(reutrnAmount).div(new BigNumber(10).pow(destTokenDecimals)).toString() ;
            
                        
                    }
                    // console.log("orders", orders);
                    // console.log("111111111",getP2PExResult("0x0031b97dc1cb93f70db674b4d005b10c2caad6450xccb22ec0300b9c8fd30de563e5b0af9cd31764b40dbc16d674ec8000647d908b",2,18))
                    setData(orders[0]);
                    setOrderPedding(true);
            })
        }
    }, [account,web3,orderId,refresh])

    return { data, orderPedding,readMorePedding,isMoreStatus }
}

export const useP2pExFuc = (pairAddress: string | null) => {
    const p2pExContract = useP2pEx(pairAddress || "");
    const { account } = useWeb3React();
    // create order
    const createOrder = useCallback(async (price: string | number, time: string, amount: string,target:string) => {
        try {
            console.log("packPrice", packPrice(price))
            const txHash = await p2pExContract.methods.createOrder(packPrice(price), time, amount,'0x0000000000000000000000000000000000000000').send({ ...{}, from: account });
            return { status: true, res: txHash };
        } catch (error: any) {
            return { status: false, res: error.message ? error.message : error.toString() };
        }

    }, [p2pExContract, account])

    return { createOrder };
}
export const useApprovefuc = (tokenAddress: string, spenderAddress: string,amount:string,decimals:number) => {

    const [approved, setApproved] = useState(0);
    const tokenContract = useERC20(tokenAddress);
    const tokenAllowance = useAllowance(tokenContract, spenderAddress, approved);
    const onTokenApprove = useTokenApprove(tokenContract, spenderAddress);
    const [requestedApproval, setRequestedApproval] = useState(false);
    // const tokenAllowedStatus = tokenAllowance && !tokenAllowance.isLessThan(new BigNumber(10).pow(77));
    const tokenAllowedStatus =useMemo(()=>
    tokenAllowance && !tokenAllowance.isLessThan(
     new BigNumber(amount).times(new BigNumber(10).pow(decimals))
    ),
    [amount,tokenAllowance,decimals]) ;
    // 授权
    const handleApprove = useCallback(async () => {
        try {
            setRequestedApproval(true)
            const txHash = await onTokenApprove()
            setRequestedApproval(false)
            if (txHash) { setApproved(approved + 1); }
        } catch (e) {
            console.error(e)
        }
    }, [onTokenApprove, approved])

    return { approved,setApproved,handleApprove, tokenAllowedStatus, requestedApproval }
}


export default  useApprovefuc;