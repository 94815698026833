import { useWeb3React } from "@web3-react/core";
import useWeb3 from "hooks/useWeb3";
import { useEffect, useRef, useState } from "react";
import { useBlockNumber } from "state/application/hooks";
import { getShares } from "utils/gidex";
import { getGridInfos } from "utils/gidex/share";

const useGetLiquidityList = (refState: boolean,dependency?): { data: Array<any>} => {
  const { account } = useWeb3React();
  const [data, setData] = useState<any>();
  useEffect(() => {
    // 初始化
    const fetchData = async () => {
      console.log("refState",refState)
      try {
        const importToken = JSON.parse(window.localStorage.getItem('importToken') || "[]");
        const res = await getShares(account, importToken);
        setData(res);
      } catch (error) {
        console.log("erro", error)
      }
    }
    if (refState === true || account || dependency) { fetchData(); }
  }, [account, refState,dependency])

  return { data}
}
export const useGetGridInfos = (refState: boolean,res:any): {gridInfos:any } => {
  const { account } = useWeb3React();
  const [gridInfos, setGetGridInfos] = useState<any>();
  const latestBlockNumber = useBlockNumber();
  useEffect(() => {
    // 初始化
    const fetchData = async () => {
      try {
        const ret=await getGridInfos(res, latestBlockNumber)
        setGetGridInfos(ret);
        // console.log('ret',ret)
      } catch (error) {
        console.log("erro", error)
      }
    }
    if (res && ( refState || account)) { fetchData(); }

  },
  [account,res,refState,latestBlockNumber])

  return { gridInfos}
}

export default useGetLiquidityList