import BigNumber from 'bignumber.js'
import multicall from "utils/multicall"
import { ethers } from 'ethers';
import { getBenswapGridexRouter, getBenswapGridexRouterHelper, isBch, PriceBase } from "./gridex"
import GridexLogic from '../../constants/abis/gridex-logic.json'

export const WBCH = '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04'
export default async function searchShares(account, importedSharesInfos: Array<[string, string]> = []) {
  // await new Promise((resolve) => {
  //   setTimeout(resolve, 2000)
  // })
  const whiteImpAddres = (await getBenswapGridexRouterHelper().getBenswapGridexLogicImplInfos("0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000000")).map(v => v.implAddress.toLowerCase())

  let data: any = []
  try {
    const resultGraph = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-gridex", {
      // eslint-disable-next-line
      "body": `{\"query\":\"{\\n  pools (where: {value_gt:0,owner:\\\"${account}\\\"}){\\n  id\\n     owner,\\n    gridId,\\n    value,\\n    pair{\\n      id\\n      impl\\n      money\\n      stock\\n    }\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
      "method": "POST",
    }).then(res => res.json()).then(res => res.data.pools);
    const addresses = Array.from(new Set(resultGraph.map(v => v.pair.id)))
    data = addresses.map(address => {
      const pools = resultGraph.filter(x => x.pair.id === address)
      return {
        address,
        ids: pools.map(x => x.gridId),
        stock: pools[0].pair.stock, // pairToken
        money: pools[0].pair.money, // pairToken
        impl: pools[0].pair.impl,
        deprecated: !whiteImpAddres.includes(pools[0].pair.impl.toLowerCase()),
        amounts: pools.map(x => x.value) // 数量
      }
    })
  } catch (error) {
    console.log(error)
  }
  {
    importedSharesInfos = importedSharesInfos.map(([tokenFrom, tokenTo]) => ([isBch(tokenFrom) ? WBCH : tokenFrom, isBch(tokenTo) ? WBCH : tokenTo]))
    let importedData = await Promise.all(importedSharesInfos.map(async ([tokenFrom, tokenTo]) => {
      const benswapGridexRouter = getBenswapGridexRouterHelper()
      const infos = await benswapGridexRouter.getBenswapGridexLogicImplInfos(tokenFrom, tokenTo)
      const gridexType = infos.find(x => x.recommended).gridexType
      const impl = infos.find(x => x.recommended).implAddress
      const address = await benswapGridexRouter.getBenswapGridexPairAddress(tokenFrom, tokenTo, gridexType);
      if (address === "0x0000000000000000000000000000000000000000" || data.some(v => v.address.toLowerCase() === address.toLowerCase())) {
        return { address, ids: [] }
      }
      const { lowGrid, highGrid } = await benswapGridexRouter.getLowGridAndHighGrid(address);
      // eslint-disable-next-line
      if (lowGrid == 16384 || highGrid == 16384) {
        return { address, impl, ids: [] }
      }
      return { address, impl, deprecated: !whiteImpAddres.includes(impl.toLowerCase()), ids: new Array(highGrid - lowGrid + 1).fill(0).map((_, i) => parseInt(lowGrid) + i) }
    }))
    importedData = importedData.filter(v => v.ids.length)
    const calls: any = importedData.map((v: any) => v.ids.map(id => ({
      address: v.address,
      name: 'balanceOf',
      params: [account, id]
    })))
    const amounts = (await multicall(GridexLogic, [].concat(...calls))).map(v => v.toString())
    const paramses = await multicall(GridexLogic, importedData.map((v) => ({
      address: v.address,
      name: 'loadParams'
    })))
    importedData = importedData.map((v, i) => ({
      ...v,
      stock: paramses[i][0].stock, // pairToken
      money: paramses[i][0].money, // pairToken
      amounts: amounts.splice(0, v.ids.length) // 数量
    }))
    data = data.concat(importedData)
  }

  const paramsArr = (await multicall(GridexLogic, data.map((v) => ({
    address: v.address,
    name: 'loadParams'
  })))).map(([params,]) => ({ priceDiv: params.priceDiv, priceMul: params.priceMul, fee: params.fee.toNumber() }))

  const idsArr = data.map(({ ids }) => {
    ids = ids.map(id => parseInt(id))
    const allIds = ids.map((id, i) => {
      if (i !== (ids.length - 1) && (ids[i + 1] - ids[i] === 1)) {
        return id
      }
      return [id, id + 1]
    })
    return [].concat(...allIds)
  })
  const calls: any = data.map(({ address }, i) => {
    return idsArr[i].map(id => ({
      address,
      name: 'grid2price',
      params: [id]
    }))
  })
  const prices = (await multicall(GridexLogic, [].concat(...calls))).map(v => new BigNumber(v).dividedBy(PriceBase)).map(v => v.toString())
  const result = data.map((v, i) => {
    const curPrices = prices.splice(0, idsArr[i].length)
    return {
      ...v,
      prices: curPrices.map((_, j) => ({ id: idsArr[i][j], price: curPrices[j] })), // 价格
      ...paramsArr[i]
    }
  })
  result.forEach(v => {
    v.ids = v.ids.filter((_, i) => v.amounts[i] !== "0")
    v.prices = v.ids.map(id => {
      id = parseInt(id)
      const minPrice = v.prices.find(p => id === p.id).price
      const maxPrice = v.prices.find(p => (id + 1) === p.id).price
      return [minPrice, maxPrice]
    })
    v.amounts = v.amounts.filter((vv) => vv !== "0")
  })
  return result.filter(v => v.ids.length !== 0)
}




export async function getGridInfos(sharedata, latestBlockNumber) {
  let data = sharedata.map(pair => pair.ids.map((id, i) => ({
    id: `${pair.address}0x${ethers.BigNumber.from(id).toHexString().replace("0x", "").replace(/\b(0+)/gi, "")}`,
    amount: pair.amounts[i],
    prices: pair.prices[i],
    priceDiv: pair.priceDiv,
    priceMul: pair.priceMul,
    address: pair.address
  })))
  data = [].concat(...data)
  const ids = data.map(v => v.id)
  // const ids = ["0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7", "0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7"]
  const latestResult = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-gridex", {
    // eslint-disable-next-line
    "body": `{\"query\":\"{\\n  grids (where: {id_in:[${ids.map(v => '\\"' + v + '\\"').join(',')}]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    // "body": `{\"query\":\"{\\n  grids (block: {number: 7493743},where: {id_in:[\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\",\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\"]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    "method": "POST",
  }).then(res => res.json()).then(res => res.data.grids);

  const dayResult = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-gridex", {
    // eslint-disable-next-line
    "body": `{\"query\":\"{\\n  grids (block: {number: ${latestBlockNumber - 24 * 60 * 60 / 5}},where: {id_in:[${ids.map(v => '\\"' + v + '\\"').join(',')}]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    // "body": `{\"query\":\"{\\n  grids (block: {number: 7493743},where: {id_in:[\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\",\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\"]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    "method": "POST",
  }).then(res => res.json()).then(res => res.data.grids);

  const weekResult = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-gridex", {
    // eslint-disable-next-line
    "body": `{\"query\":\"{\\n  grids (block: {number: ${latestBlockNumber - 7 * 24 * 60 * 60 / 5}},where: {id_in:[${ids.map(v => '\\"' + v + '\\"').join(',')}]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    // "body": `{\"query\":\"{\\n  grids (block: {number: 7493743},where: {id_in:[\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\",\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\"]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    "method": "POST",
  }).then(res => res.json()).then(res => res.data.grids);

  const yearResult = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-gridex", {
    // eslint-disable-next-line
    "body": `{\"query\":\"{\\n  grids (block: {number: ${latestBlockNumber - 365 * 24 * 60 * 60 / 5}},where: {id_in:[${ids.map(v => '\\"' + v + '\\"').join(',')}]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    // "body": `{\"query\":\"{\\n  grids (block: {number: 7493743},where: {id_in:[\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\",\\"0x047872b49ebe2adabb54fdae55f3cb9dee234a100xc7\\"]}) {\\n    id,\\n   gridId\\n    soldRatio\\n    totalStock\\n    totalShares\\n  }\\n}\",\"variables\":null,\"operationName\":null}`,
    "method": "POST",
  }).then(res => res.json()).then(res => res.data.grids);



  const PriceBase_ = ethers.BigNumber.from((2 ** 68).toString())
  const RatioBase_ = ethers.BigNumber.from((10 ** 19).toString())

  const grids = latestResult.map(grid => {
    const latestValue = new BigNumber(grid.totalStock).div(grid.totalShares).toNumber()
    const yearGrid = yearResult.find(x => x.id === grid.id)
    const yearValue = yearGrid && new BigNumber(yearGrid.totalStock).div(yearGrid.totalShares).toNumber() || 1
    const yearAPY = (1 + (latestValue / yearValue - 1) / 365) ** 365 - 1
    const weekGrid = weekResult.find(x => x.id === grid.id)
    const weekValue = weekGrid && new BigNumber(weekGrid.totalStock).div(weekGrid.totalShares).toNumber() || 1
    const weekAPY = (1 + (latestValue / weekValue - 1) / 7) ** 365 - 1
    const dayGrid = dayResult.find(x => x.id === grid.id)
    const dayValue = dayGrid && new BigNumber(dayGrid.totalStock).div(dayGrid.totalShares).toNumber() || 1
    const dayAPY = (1 + (latestValue / dayValue - 1) / 1) ** 365 - 1

    const { amount, prices, priceMul, priceDiv, address } = data.find(x => x.id === grid.id)
    const priceLo = ethers.BigNumber.from(PriceBase.multipliedBy(prices[0]).toFixed(0)).mul(priceMul);
    const priceHi = ethers.BigNumber.from(PriceBase.multipliedBy(prices[1]).toFixed(0)).mul(priceMul);
    const soldStock = ethers.BigNumber.from(grid.totalStock).mul(grid.soldRatio).div(RatioBase_);
    const leftStock = ethers.BigNumber.from(grid.totalStock).sub(soldStock)
    const price = priceHi.mul(grid.soldRatio).add(priceLo.mul(RatioBase_.sub(grid.soldRatio))).div(RatioBase_)
    const gotMoney = price.add(priceLo).mul(soldStock).div(PriceBase_.mul(priceDiv).mul(2))
    return {
      address, yearAPY, weekAPY, dayAPY, ...grid,
      userLeftStock: leftStock.mul(amount).div(grid.totalShares).toString(),
      userGotMoney: gotMoney.mul(amount).div(grid.totalShares).toString(),
      yearGrid, weekGrid, dayGrid
    }
  })

  const pairs = sharedata.map(({ address }) => {
    const numerator = grids.map(v => new BigNumber(v.totalStock)).reduce((x, y) => x.plus(y), new BigNumber(0))
    let yearAPY
    {
      const denominator = grids.map(v => new BigNumber(v.totalStock).multipliedBy(v.yearAPY)).reduce((x, y) => x.plus(y), new BigNumber(0))
      yearAPY = denominator.div(numerator).toString()
    }
    let weekAPY
    {
      const denominator = grids.map(v => new BigNumber(v.totalStock).multipliedBy(v.weekAPY)).reduce((x, y) => x.plus(y), new BigNumber(0))
      weekAPY = denominator.div(numerator).toString()
    }
    let dayAPY
    {
      const denominator = grids.map(v => new BigNumber(v.totalStock).multipliedBy(v.dayAPY)).reduce((x, y) => x.plus(y), new BigNumber(0))
      dayAPY = denominator.div(numerator).toString()
    }
    return {
      yearAPY, weekAPY, dayAPY, address
    }
  })

  return { grids, pairs }
}