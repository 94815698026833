import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall';
import { ethers } from 'ethers';
import { getPrice as getPrice_ } from 'utils/limitex/price';
import { isBch, WBCH, getBenswapGridexRouter, PriceBase, getGridexLogic, getBenswapGridexRouterHelper, RatioBase } from './gridex';
import { getStockAndMoney, getTruePrice, soreGrids_addAmounts } from "./util"
import GridexLogic from '../../constants/abis/gridex-logic.json'
import searchShares from './share';
import ERC20_ABI from '../../constants/abis/erc20.json';

// eslint-disable-next-line
const { getResult } = require("./price_util");

// 获取当前价格
export async function getPrice(tokenFrom, tokenTo, gridexType: 16 | 64 | 256) {
  if (isBch(tokenFrom)) {
    tokenFrom = WBCH
  }
  if (isBch(tokenTo)) {
    tokenTo = WBCH
  }
  const { stock, money } = await getStockAndMoney(tokenFrom, tokenTo)
  // eslint-disable-next-line
  let { v2Price, gridexPrice, midGrid, midGridSoldRatio } = await getBenswapGridexRouterHelper().getBenswapGridexInfo(stock, money, gridexType)
  midGrid = parseInt(midGrid)
  const decimals = (await multicall(ERC20_ABI, [tokenFrom, tokenTo].map((v) => ({
    address: v,
    name: 'decimals',
  })))).map((v) => parseInt(v))
  let priceMul = 1;
  let priceDiv = 1;
  if (decimals[1] >= decimals[0]) {
    priceMul = (10 ** (decimals[1] - decimals[0]));
  } else {
    priceDiv = (10 ** (decimals[0] - decimals[1]));
  }
  const params = {
    stock, money, priceMul, priceDiv
  }

  let price = gridexPrice !== "0" ? gridexPrice : v2Price
  const { grid2price, price2grid }: any = getResult(gridexType)
  if (Number(price) === 0) {
    const p = await getPrice_(stock, money, decimals[0], decimals[1])
    price = PriceBase.multipliedBy(p as string).toNumber()
    midGrid = price2grid(price)
    const priceLo = grid2price(midGrid);
    const priceHi = grid2price(midGrid + 1);
    midGridSoldRatio = RatioBase.multipliedBy(price).minus(RatioBase.multipliedBy(priceLo)).dividedBy(priceHi - priceLo).toFixed(0)
  }
  return {
    price: getTruePrice(params, tokenFrom, tokenTo, price).toString(),   // 显示使用price
    migGridinitialized: gridexPrice !== "0",
    result: {
      params,
      gridexPrice: price,
      midGrid,
      midGridSoldRatio
    },  // getPoolsData传入的price使用gridexPrice
    onlyTokenFromPrice: getTruePrice(params, tokenFrom, tokenTo, grid2price(midGrid + 1)).toString(),  // minPrice大于等于这个值则只能输入tokenFrom的数量
    onlyTokenToPrice: getTruePrice(params, tokenFrom, tokenTo, grid2price(midGrid)).toString(), // maxPrice小于等于这个值则只能输入tokenTo的数量
  }
}
// console.log("getPrice", getPrice("0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04", "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B", 64).then((({ price, gridexPrice }) => console.log(price.toString(), gridexPrice.toString()))).catch(console.error))

// 上面的token固定为tokenFrom 下面的token固定位tokenTo 
// 上面输入时tokenFrom传输入的值. tokenTo传0，返回值为tokenTo的数量；  反之tokenFrom传0，返回tokenFrom的数量
// slippage会滑点 千分位
export async function getGridexPairTokenReturn(tokenFrom, tokenTo, gridexType, { params, gridexPrice, midGrid, midGridSoldRatio }, minGrid, maxGrid, tokenFromAmount, tokenToAmount) {
  const { grids_addAmounts, addAmounts, stockAmounts, moneyAmounts } = await getBenswapGridexRouterHelper().getBenswapResultOfBatchAddLiquidity(
    tokenFrom, tokenTo, gridexType, [minGrid, midGrid, maxGrid], midGridSoldRatio, [tokenFromAmount, tokenToAmount])
  const maxMoney = moneyAmounts.map(v => ethers.BigNumber.from(v)).reduce((x, y) => x.add(y)).toString()
  return {
    sharesResult: { grids_addAmounts: soreGrids_addAmounts(minGrid, midGrid, maxGrid, grids_addAmounts), minGrid, midGrid, maxGrid },
    amount: maxGrid < midGrid ? 0 : maxMoney
  }
}

// 获取当前流动性分布图数据
export async function getPoolsData(tokenFrom, tokenTo, gridexType: 16 | 64 | 256, { params, gridexPrice }) {
  if (isBch(tokenFrom)) {
    tokenFrom = WBCH
  }
  if (isBch(tokenTo)) {
    tokenTo = WBCH
  }
  const { stock, money } = await getStockAndMoney(tokenFrom, tokenTo)
  const { grid2price, price2grid }: any = getResult(gridexType)
  const grid = price2grid(gridexPrice);
  const address = await getBenswapGridexRouterHelper().getBenswapGridexPairAddress(stock, money, gridexType)
  const length = 201; // 上下200个
  // eslint-disable-next-line
  let beginGrid = grid - parseInt(length / 2 + "");
  if (beginGrid < 1) {
    beginGrid = 1;
  }
  if (address === "0x0000000000000000000000000000000000000000") {
    const data = new Array(length).fill(0).map((_, i) => {
      const curGrid = beginGrid + i
      let pPrice = grid2price(curGrid)
      pPrice = getTruePrice(params, tokenFrom, tokenTo, pPrice)
      return {
        grid: curGrid,
        price: pPrice.toString(),
        total: new BigNumber(0),
      }
    })
    return data
  }
  // 获取pools 
  const gridexLogic = getGridexLogic(address)
  const pools = await gridexLogic.getPools(beginGrid, length)
  const data = pools.map(({ totalShares, totalStock }, i) => {
    const curGrid = beginGrid + i
    let pPrice = new BigNumber(grid2price(curGrid))
    if (tokenFrom !== stock) { // 顺序换了
      const temp = PriceBase.multipliedBy(PriceBase)
      pPrice = temp.div(pPrice);
    }
    return {
      grid: curGrid,
      price: pPrice.div(PriceBase).toString(),
      total: new BigNumber(totalStock.toString()).toString()
    }
  })
  return data
}
// console.log("getPoolsData", getPoolsData("0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04", "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B", 64, new BigNumber(2.932613074812756819052087e+24)).then(((pools) => console.log(pools.map(({ price, total }) => ({ price: price.toString(), total: total.toFixed(0) }))))).catch(console.error))

// 合约创建后再调用 
export async function addShares(account, sureFuc, tokenFrom, tokenTo, gridexType: number, tokenFromAmount, tokenToAmount, { params, gridexPrice }, { grids_addAmounts, minGrid, midGrid, maxGrid }) {
  const { stock, money } = await getStockAndMoney(tokenFrom, tokenTo)
  const infos = await getBenswapGridexLogicImplInfos(tokenFrom, tokenTo)
  let bchAmount = 0;
  let stockReal = stock;
  let moneyReal = money;
  const bch = "0x0000000000000000000000000000000000002711"
  if ((isBch(tokenFrom) || isBch(tokenTo)) && stock === WBCH) { // 正常顺序 
    bchAmount = tokenFromAmount
    stockReal = bch
  } else if ((isBch(tokenFrom) || isBch(tokenTo)) && money === WBCH) {
    bchAmount = tokenToAmount
    moneyReal = bch
  }
  const benswapGridexRouter = await getBenswapGridexRouter()
  await benswapGridexRouter.batchAddLiquidity(account, sureFuc, bchAmount, stockReal, moneyReal,
    infos.find(v => v.gridexType === gridexType).implAddress, [midGrid - 2, midGrid + 2], midGrid, tokenFromAmount, tokenToAmount, grids_addAmounts)
}

// 获取流动池份额
// importedSharesInfos为用户导入的币对  结构为Array<[tokenFrom, tokenTo]>
export async function getShares(account, importedSharesInfos: Array<[string, string]> = []) {
  // const decimals = (await multicall(ERC20_ABI, [tokenFrom, tokenTo].map((v) => ({
  //   address: v,
  //   name: 'decimals',
  // })))).map((v) => parseInt(v))
  // todo
  return searchShares(account, importedSharesInfos)
}

// 获取不同的交易类型  
export async function getBenswapGridexLogicImplInfos(tokenFrom, tokenTo) {
  const infos = await getBenswapGridexRouterHelper().getBenswapGridexLogicImplInfos(tokenFrom, tokenTo);
  return infos.map(v => ({
    fee: { "16": 30, "64": 10, "256": 5 }[v.gridexType], // 手续费(万分为)
    gridexType: parseInt(v.gridexType),
    implAddress: v.implAddress,
    recommended: v.recommended,  // 是否是默认推荐
  }))

}

// 移除流动性
export async function removeShares(
  deprecated,
  account,
  sureFuc,
  pairAddress,// getShares中的返回值
  ids, // getShares中的返回值或用户选择的值
  removedAmounts // getShares中的返回值或用户选择的值
) {
  ids = ids.map(v => parseInt(v))
  const gridexLogic = getGridexLogic(pairAddress);
  await gridexLogic.batchRemoveLiquidity(deprecated, account, sureFuc, ids, removedAmounts)
}

// 转移流动性
export async function transformShares(
  account,
  to, // 转移的目标地址
  sureFuc,
  pairAddress,// getShares中的返回值
  ids, // getShares中的返回值或用户选择的值
  amounts // getShares中的返回值或用户选择的值
) {
  const gridexLogic = getGridexLogic(pairAddress);
  await gridexLogic.transferBatch(account, to, sureFuc, ids, amounts)
}

// isValidPair为false，则界面显示“仅支持stock为tokenTo, money为tokenFrom的交易对创建”
export async function isValidPair_(tokenFrom, tokenTo) {
  const { isValidPair } = await getStockAndMoney(tokenFrom, tokenTo)
  return isValidPair
}

// 调用示例
(async function () {
  // const tokenFrom = "0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04";
  // const tokenTo = "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B";
  // const infos = await getBenswapGridexLogicImplInfos(tokenFrom, tokenTo)
  // console.log("getBenswapDefaultGridexLogicType", infos)
  // const gridexType = infos.find(x => x.recommended).gridexType;
  // const { price, gridexPrice, onlyTokenFromPrice, onlyTokenToPrice } = await getPrice(tokenFrom, tokenTo, gridexType);
  // console.log("price, gridexPrice", price, gridexPrice, onlyTokenFromPrice, onlyTokenToPrice)
  // const pools = await getPoolsData(tokenFrom, tokenTo, gridexType, gridexPrice);
  // console.log("getPoolsData", pools)
  // const minPrice = "1663";
  // const maxPrice = "1668";
  // const tokenFromAmount = "100000000";
  // const { amount, sharesResult } = getGridexPairTokenReturn(tokenFrom, tokenTo, gridexType, 5, gridexPrice, minPrice, maxPrice, tokenFromAmount, 0)
  // console.log("getGridexPairTokenReturn", amount, sharesResult)
  // const account = "0xf29c9eF6496A482b94BDB45ABA93d661F082922C";
  // await addShares(account, console.log, tokenFrom, tokenTo, gridexType, tokenFromAmount, amount, gridexPrice, sharesResult)
  // const shares = await getShares(account)
  // console.log("shares", shares)
  // await removeShares(account, console.log, shares[0].address, shares[0].ids, shares[0].amounts)
  // const remocedShares = await getShares(account)
  // console.log("remocedShares", remocedShares)
})().catch(console.error)

// testnet
// (async function () {
//   const tokenFrom = "0x17F4FCF5b6E0A95D4eE331c8529041896A073F9b";
//   const tokenTo = "0x77beB0D017C743eCa0d22951A3b051A17D50f108";
//   const gridexType = 64;
//   const { price, gridexPrice } = await getPrice(tokenFrom, tokenTo, gridexType);
//   console.log("price, gridexPrice", price, gridexPrice)
//   const pools = await getPoolsData(tokenFrom, tokenTo, gridexType, gridexPrice);
//   console.log("getPoolsData", pools)
//   const minPrice = "8000";
//   const maxPrice = "9000";
//   const tokenFromAmount = "100000000";
//   const { amount, sharesResult } = getGridexPairTokenReturn(tokenFrom, tokenTo, gridexType, gridexPrice, minPrice, maxPrice, tokenFromAmount, 0)
//   console.log("getGridexPairTokenReturn", amount, sharesResult)
//   const account = "0xf29c9eF6496A482b94BDB45ABA93d661F082922C";
//   addShares(account, console.log, tokenFrom, tokenTo, gridexType, 5, tokenFromAmount, amount, gridexPrice, sharesResult).then(console.log).catch(console.error)

// })().catch(console.error)

