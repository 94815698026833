import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useMemo, useState,useRef  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBenswapGridexLogicImplInfos, getPoolsData, getPrice, isValidPair_ } from 'utils/gidex'
import { useTokenInfo } from '../../hooks/Tokens'
import { toFormatPrice } from '../../utils/getPrecision'
import { AppDispatch, AppState } from '../index'
import { setClearAll,recordSharesResult,tokenAamountValue,tokenBamountValue,Fields, selectToken,tokenAmount,switchToken, typeLeftRangeInput, typeRightRangeInput, setFeeTier, setPriceInfo, setAllrange} from './actions'
// 获取gri 全局变量
export function useGriLiquidityState(): AppState['griDexLiquidity'] {
  return useSelector<AppState, AppState['griDexLiquidity']>((state) => state.griDexLiquidity)
}
// 设置gri 全局变量
export function useGriLiquidityActionHandlers(): {
    onSetAllrange:(typedValue: Array<string>) => void,
    onSetClearAll:(typedValue: boolean) => void,
    onSetPriceInfo:(priceInfoValue: {
      price:string,
      migGridinitialized:boolean,
      result:any,
      onlyTokenFromPrice:string,
      onlyTokenToPrice:string})=> void,
    onSetFeeTier:(typedValue: {fee:number,gridexType:16|64|256,impAddress:string,recommended:boolean}) => void,
    onRecordSharesResult:(sharesResultVal: any)=>void,
    onTokenAamountValue:(typedValue: string) => void,
    onTokenBamountValue:(typedValue: string) => void,
    onLeftRangeInput:(typedValue: string) => void,
    onRightRangeInput:(typedValue: string) => void,
    onTokenSwitch:()=>void,
    onSelectToken:(token:{[Fields.TOKEN_A]:string,[Fields.TOKEN_B]:string})=>void,
    onTokenAmount:(amount:{tokenA:string,tokenB:string})=>void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onSelectToken = useCallback(async (token:{[Fields.TOKEN_A]:string,[Fields.TOKEN_B]:string}) => {
        dispatch(selectToken(token))
  }, [dispatch])



  const onTokenAmount = useCallback((amount:{tokenA:string,tokenB:string}) => {
    dispatch(tokenAmount(amount))
  }, [dispatch])

  // 范围修改
  const onTokenAamountValue = useCallback((typedValue: string) => {
    dispatch(tokenAamountValue({typedValue}))
  }, [dispatch])

  const onTokenBamountValue = useCallback((typedValue: string) => {
    dispatch(tokenBamountValue({typedValue}))
  }, [dispatch])

  const onTokenSwitch = useCallback(() => {
    dispatch(switchToken())
  }, [dispatch])

  // 范围修改
  const onLeftRangeInput = useCallback(
    (typedValue: string) => {
      dispatch(typeLeftRangeInput({ typedValue }))
    },
    [dispatch]
  )
  const onRightRangeInput = useCallback(
    (typedValue: string) => {
      dispatch(typeRightRangeInput({ typedValue }))
    },
    [dispatch]
  )
  const onRecordSharesResult = useCallback(
    (sharesResultVal:any) => {
      dispatch(recordSharesResult({ sharesResultVal }))
    },
    [dispatch]
  )
  // 费率
  const onSetFeeTier = useCallback(
    (typedValue: {fee:number,gridexType:16|64|256,impAddress:string,recommended:boolean}) => {
      dispatch(setFeeTier({ typedValue }))
    },
    [dispatch]
  )
  
  const onSetPriceInfo= useCallback(
    (priceInfoValue: {
      price:string,
      result:any,
      migGridinitialized:boolean,
      onlyTokenFromPrice:string,
      onlyTokenToPrice:string
    }) => {
      dispatch(setPriceInfo({ priceInfoValue }))
    },
    [dispatch]
  )
  const onSetClearAll= useCallback(
    (typedValue:boolean) => {
      dispatch(setClearAll({ typedValue }))
    },
    [dispatch]
  )
  const onSetAllrange= useCallback(
    (typedValue:Array<string>) => {
      dispatch(setAllrange({ typedValue }))
    },
    [dispatch]
  )
  

  
  return {
    onSetAllrange,
    onSelectToken,
    onTokenAmount,
    onTokenAamountValue,
    onTokenBamountValue,
    onTokenSwitch,
    onLeftRangeInput,
    onRightRangeInput,
    onRecordSharesResult,
    onSetFeeTier,
    onSetPriceInfo,
    onSetClearAll
  }
}


// 价格范围初始化
export function getVicinity(val:string,charts:Array<any>,isMinPrice:boolean):string{
  const inputVal=new BigNumber(val||0);
  const arrts=JSON.parse(JSON.stringify(charts));
  arrts.sort(function(a,b){
      const  aprice0=new BigNumber(a.price0).minus(inputVal).toNumber();
      const  bprice0=new BigNumber(b.price0).minus(inputVal).toNumber();
      return Math.abs(aprice0)-Math.abs(bprice0);
  })
  let res:any=null;
  if(isMinPrice){
    res=arrts.find(f=>f.price0<inputVal);
  }else{
    res=arrts.find(f=>f.price0>inputVal);
  }

  const price=res?(res as any).price0:arrts[0].price0;
  return `${price}`;
}
export function initPirceRange(charts:Array<any>,gridexTypeVal:16|64|256,price:string):{
  leftRangVal:string,
  rightRangVal:string
}{
  let minPrice="0";
  let maxPrice="0";
  let leftVal="0"
  let rightVal="0"
  // 100%
  if(gridexTypeVal===16){
    minPrice=new BigNumber(price).times(new BigNumber(0.5)).toString();
    maxPrice=new BigNumber(price).times(new BigNumber(1.5)).toString();
    leftVal=getVicinity(minPrice,charts,true);
    rightVal=getVicinity(maxPrice,charts,false);
  }
  // 50%
  if(gridexTypeVal===64){
    minPrice=new BigNumber(price).times(new BigNumber(0.7)).toString();
    maxPrice=new BigNumber(price).times(new BigNumber(1.3)).toString();
    leftVal=getVicinity(minPrice,charts,true);
    rightVal=getVicinity(maxPrice,charts,false);
  }
  // 10%
  if(gridexTypeVal===256){
    minPrice=new BigNumber(price).times(new BigNumber(0.9)).toString();
    maxPrice=new BigNumber(price).times(new BigNumber(1.1)).toString();
    leftVal=getVicinity(minPrice,charts,true);
    rightVal=getVicinity(maxPrice,charts,false);
  }
  
  return {leftRangVal:leftVal,rightRangVal:rightVal}

}


// 获取价格chardata
export function useTokenEchangeInfo(
  tokenA:string,
  tokenB:string
  ):{
state:boolean,
chartData:any,
isValidPairState:boolean
}{
  const interTime=useRef<any>();
  const isPendding=useRef<any>();
  const {onSetAllrange,onSetPriceInfo,onLeftRangeInput,onRightRangeInput}=useGriLiquidityActionHandlers();
  const [state,setState]=useState(false);
  const [isValidPairState,setIsValidPairState]=useState(true);
  const [chartData, setChartdata] = useState<any>([]);
  const tokenBSymbol=useTokenInfo(tokenB);
  const tokenASymbol=useTokenInfo(tokenA);
  const {leftRangeTypedValue,rightRangeTypedValue}=useGriLiquidityState();
  
  const leftRangeRef=useRef<any>("0");
  const rightRangeRef=useRef<any>("0");
  useEffect(()=>{
    leftRangeRef.current=leftRangeTypedValue;
    rightRangeRef.current=rightRangeTypedValue;
  },[leftRangeTypedValue,rightRangeTypedValue])


  useEffect(()=>{
    // 初始化DATA
    const initData=()=>{
        setChartdata([]);
        onSetPriceInfo({
          migGridinitialized:true,
          price:"",
          result:{},
          onlyTokenFromPrice:"",
          onlyTokenToPrice:""
        })
        onLeftRangeInput("0");
        onRightRangeInput("0");
    }
    // 初始化状态
    const initStatus=()=>{
      isPendding.current=false;
      setState(true);
    }
    // -------------获取gridexType-------------
    const getGridexLogicImplInfos=async(tokenA_address,tokenB_address)=>{
          const ret =await getBenswapGridexLogicImplInfos(tokenA_address, tokenB_address);
          const gridexType=(ret.find(t=>t.recommended===true)).gridexType;
          return gridexType;
    }
    // -------------获取当前汇率价格-------------
    const getPriceFuc=async(tokenA_address,tokenB_address,gridexType)=>{
      let res:any=null;
      try {
        res= await getPrice(tokenA_address, tokenB_address, gridexType as any);
      } catch (error:any) {
        if((error.toString()).indexOf("execution reverted: invalid-grid")!==-1){
          res={ price:'-1' };
        }
      }
      // 全局记录price 价格
      if(res!=null){
        onSetPriceInfo(res);
      }
      if(!res || (res!=null && res?.price==="0") || (res!=null && res?.price==="-1")){
        return {result:null,price:null};
      }
      return   {result:res.result,price:res.price};
    }
    // -------------获取价格图-------------------
    const getPoolFuc=async(tokenA_address,tokenB_address,gridexType,res,isRefresh)=>{
      const chartRes=await getPoolsData(tokenA_address, tokenB_address, gridexType as any, res.result)
      // poolchart 排序处理
      chartRes.push({grid:-1,total:-1,price:res.price})
      chartRes.sort(function(a,b){return parseFloat(a.price)-parseFloat(b.price);})
      const chartResArray=chartRes.map(t=>({
        grid:t.grid,
        activeLiquidity:t.total===-1?'-1':new BigNumber(t.total).div(new BigNumber(10).pow(tokenASymbol?.decimals||0)).toFixed(2),
        price0:t.price
      }))

      // test Refresh
      // if(isRefresh){
      //   chartResArray.push({"activeLiquidity":"20","price0":"1"});
      // }

      // 初始化图表数据
      setChartdata(chartResArray)
      // 初始化图表范围
      if(!isRefresh){
        const {leftRangVal,rightRangVal}=initPirceRange(chartResArray,gridexType as any,res?.price);
        onLeftRangeInput(leftRangVal)
        onRightRangeInput(rightRangVal)
        onSetAllrange([leftRangVal,rightRangVal])
      }
      return true;
    }

    const fetchData = async (isRefresh?:boolean) => {
      if(tokenA==="" || tokenB===""){return}
      setState(false);
      isPendding.current=true;
      if(!isRefresh){
         initData();
      }

      try {
        const isValid=await isValidPair_(tokenA, tokenB);
        setIsValidPairState(isValid);
        if(!isValid){initStatus();return}
        // -------------获取gridexType-------------
        const gridexType=await getGridexLogicImplInfos(tokenA,tokenB);
        // -------------获取当前汇率价格-------------
        const res=await getPriceFuc(tokenA, tokenB, gridexType as any);
        if(res.result===null){initStatus();return;}
        // -------------获取价格图-------------------
        const chartStatus=await getPoolFuc(tokenA, tokenB, gridexType as any,res,isRefresh);
        initStatus();
      } catch (error) {
        initStatus();
      }
      
    
      // try{
      //  isValidPair_(tokenA, tokenB).then(async (isValid)=>{
      //     setIsValidPairState(isValid);
      //     if(!isValid){
      //       initStatus();
      //       return
      //     }
      //     // -------------获取gridexType-------------
      //     const ret =await getBenswapGridexLogicImplInfos(tokenA, tokenB);
      //     const gridexType=(ret.find(t=>t.recommended===true)).gridexType;
      //     // -------------获取当前汇率价格-------------
      //     let res:any=null;
      //     try {
      //       res= await getPrice(tokenA, tokenB, gridexType as any);
      //     } catch (error:any) {
      //       if((error.toString()).indexOf("execution reverted: invalid-grid")!==-1){
      //          res={ price:'-1' };
      //       }
      //     }
      //     // 全局记录price 价格
      //     onSetPriceInfo(res);
      //     if(!res || (res && res?.price==="0") || (res && res?.price==="-1")){
      //       initStatus();
      //       return
      //     }

      //     // -------------获取 chart Data----------------
      //     getPoolsData(tokenA, tokenB, gridexType as any, res.result).then(async (chartRes)=>{
      //       // poolchart 排序处理
      //       chartRes.push({grid:-1,total:-1,price:res.price})
      //       chartRes.sort(function(a,b){
      //         return parseFloat(a.price)-parseFloat(b.price);
      //       })
           
      //       const chartResArray=chartRes.map(t=>({
      //         grid:t.grid,
      //         activeLiquidity:t.total===-1?'-1':new BigNumber(t.total).div(new BigNumber(10).pow(tokenASymbol?.decimals||0)).toFixed(2),
      //         price0:t.price
      //       }))

      //       // test Refresh
      //       // if(isRefresh){
      //       //   chartResArray.push({"activeLiquidity":"20","price0":"1"});
      //       // }
            
      //       // 初始化图表数据
      //       setChartdata(chartResArray)
      //       // 初始化图表范围
      //       if(!isRefresh){
      //         const {leftRangVal,rightRangVal}=initPirceRange(chartResArray,gridexType as any,res?.price);
      //         onLeftRangeInput(leftRangVal)
      //         onRightRangeInput(rightRangVal)
      //         onSetAllrange([leftRangVal,rightRangVal])
      //       }
      
      //       initStatus();
      //     })
      //  });
      // }catch(error){ 
      //   console.log("erro",error) 
      //   initStatus();
      // }

     
    }

    
    fetchData(false);
    // 定时20秒刷新
    if (interTime.current) clearInterval(interTime.current);
    interTime.current=setInterval(()=>{
          if(isPendding.current===false){
            fetchData(true);
          }       
    },20000)                                           
  },
  // eslint-disable-next-line
  [ tokenA,
    tokenB,
    onSetPriceInfo,
    onLeftRangeInput,
    onRightRangeInput])
  
  // 销毁定时器
  useEffect(()=>{
    return ()=>{clearInterval(interTime.current)}
  },[])
  return {state,chartData,isValidPairState}
}

export function useTokenFeeInfo(tokenA:string,tokenB:string):{
    item:Array<any>
}{
  const [item,setItem]=useState<any>();
  useEffect(()=>{
    // 初始化
    const fetchData = async () => {
      setItem([]);
      try{
        const res= await getBenswapGridexLogicImplInfos(tokenA, tokenB);
        setItem(res)
      }catch(error){console.log("erro",error)}
    }

    if(tokenA!=="" && tokenB!==""){
      fetchData();
    }

  },[tokenA, tokenB])

  return {item}
}
