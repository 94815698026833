import { LangType } from '@pancakeswap-libs/uikit';
import { allLanguages, EN, ZH } from 'constants/localisation/languageCodes'
import React, { useEffect, useState } from 'react'
import { TranslationsContext } from '../../hooks/TranslationsContext';

export interface LanguageObject {
  code: string
  language: string
}
export interface LanguageState {
  selectedLanguage: LangType
  setSelectedLanguage: (langObject: LangType) => void
  translatedLanguage: LangType
  setTranslatedLanguage: React.Dispatch<React.SetStateAction<LangType>>
}


const LanguageContext = React.createContext({
  selectedLanguage: EN,
  setSelectedLanguage: () => undefined,
  translatedLanguage: EN,
  setTranslatedLanguage: () => undefined,
} as LanguageState)

// const LanguageContext = createContext(defaultLanguageState as LanguageState)
const CACHE_KEY = 'pancakeSwapLanguage'
const getStoredLangCode = () => {
  const storedLangCode = localStorage.getItem(CACHE_KEY);
  return storedLangCode && allLanguages.some((language) => {
    return language.code === storedLangCode
  })? storedLangCode : 'en';
}

const LANGCONFIG = {
  'zh':ZH,
  "zh-cn":ZH,
  'en':EN
};

const getDefaultLan = () => {
  const code = (getStoredLangCode() || navigator.language || 'en').toLowerCase();
  return LANGCONFIG[code] || EN;
}

const LANGUAGEDATACACHE:any = {};
const fetchLanguageData = async(code) => {
  if (LANGUAGEDATACACHE[code]) return LANGUAGEDATACACHE[code];
  console.log(`/i18n/${code}.json`);
  return fetch(`/i18n/${code}.json`)
    .then(r=>{
      console.log(r);
      const data = r.json();
      LANGUAGEDATACACHE[code] = data;
      return data;
    })
}

const LanguageContextProvider= ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(getDefaultLan())
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(getDefaultLan())
  const [translations, setTranslations] = useState<Array<any>>([])

  useEffect(() => {
    if (selectedLanguage) {
      fetchLanguageData(selectedLanguage.code)
        .then((translationApiResponse) => {
          if (translationApiResponse.data.length < 1) {
            setTranslations(['error'])
          } else {
            setTranslations(translationApiResponse.data)
          }
        })
        .then(() => setTranslatedLanguage(selectedLanguage))
        .catch((e) => {
          console.error("ERROR");
          console.error(e);
          setTranslations(['error'])
        })
    }
  }, [selectedLanguage, setTranslations])

  const handleLanguageSelect = (langObject: LangType) => {
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
  }

  return (
        <LanguageContext.Provider
          value={{ selectedLanguage, setSelectedLanguage: handleLanguageSelect, translatedLanguage, setTranslatedLanguage }}>
          <TranslationsContext.Provider value={{ translations, setTranslations }}>{children}</TranslationsContext.Provider>
        </LanguageContext.Provider>
   )
}

export { LanguageContext, LanguageContextProvider }




