import { getAvggerAddress, getBchAddress, getGridexAvggerAddress } from 'utils/addressHelpers';
import { useWeb3React } from '@web3-react/core';
import { useActiveWeb3React } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Web3 from 'web3';
import { Subscription } from 'web3-core-subscriptions';
import { BlockHeader } from 'web3-eth';
import { AbiItem } from 'web3-utils'
import BigNumber from 'bignumber.js';
import 'utils/limitex/limitex';
import aggregateDexAbi from '../../../constants/abis/gridex-AggregateAudit.json'
import { useERC20, useLimitExContract, useLimitFactoryContract, useP2pEx, useP2pFactoryEx } from '../../../hooks/useContractV2';
import { useAllowance } from '../../../hooks/useAllowance';
import { useTokenApprove } from '../../../hooks/useApprove';
import { getPrice, packPrice } from '../../../utils/limitex/price';
import toPrecision from '../../../utils/getPrecision';

export const useGetPrice = (tokenA: string, tokenB: string,tokenADecimals:number,tokenBDecimals:number) => {
    const [rate, setRate] = useState<any>(0);
    const [isPedding, setIsPedding] = useState<boolean>(false);
    const getPriceFuc = useCallback(async () => {
        setIsPedding(true);
        const res = await getPrice(tokenA, tokenB,tokenADecimals,tokenBDecimals);
        setRate(toPrecision(new BigNumber(res as string), 2));
        setIsPedding(false);
    }, [tokenA, tokenB,tokenADecimals,tokenBDecimals])
    useEffect(() => {
        if (tokenA && tokenB) {
            getPriceFuc();
        }
    }, [tokenA, tokenB, getPriceFuc])

    return { rate,isPedding, getPriceFuc, setRate };
}


export const useP2pExFactory = (tokenA: string, tokenB: string) => {
    const [pairAddress, setPairAddress] = useState(null);
    const [createStatus, setCreateStatus] = useState(false);
    const p2pContract = useP2pFactoryEx();
    const { account } = useWeb3React();
    const [refPairStatus, setRefPairStatus] = useState(0);
    // get Pari status
    const getPairFuc = useCallback(async () => {
        const ret = await p2pContract.methods.getPair(tokenA, tokenB).call();
        if (ret !== "0x0000000000000000000000000000000000000000") {
            setPairAddress(ret);
        } else {
            setPairAddress(null);
        }
    }, [tokenA, tokenB, p2pContract])

    // create pair
    const createPair = useCallback(async () => {
        try {
            setCreateStatus(true);
            const txHash = await p2pContract.methods.create(tokenA, tokenB).send({ ...{}, from: account });
            setCreateStatus(false);
            if (txHash) { setRefPairStatus(refPairStatus + 1); }
        } catch (error) {
            console.log(error);
            setCreateStatus(false);
        }

    }, [tokenA, tokenB, p2pContract, account, refPairStatus])


    useEffect(() => {
        if (tokenA && tokenB && tokenA !== '' && tokenB !== '') {
            getPairFuc();
        }
    }, [tokenA, tokenB, getPairFuc, refPairStatus])
    return { pairAddress, createPair, createStatus };
}
export const useP2pExFuc = (pairAddress: string | null) => {
    const p2pExContract = useP2pEx(pairAddress || "");
    const { account } = useWeb3React();
    // create order
    const createOrder = useCallback(async (price: string | number, time: string, amount: string,target:string) => {
        try {
            const txHash = await p2pExContract.methods.createOrder(packPrice(price), time, amount,target).send({ ...{}, from: account });
            return { status: true, res: txHash };
        } catch (error: any) {
            return { status: false, res: error.message ? error.message : error.toString() };
        }

    }, [p2pExContract, account])

    return { createOrder };
}
export const useApprovefuc = (tokenAddress: string, spenderAddress: string,amount:string,decimals:number) => {

    const [approved, setApproved] = useState(0);
    const tokenContract = useERC20(tokenAddress);
    const tokenAllowance = useAllowance(tokenContract, spenderAddress, approved);
    const onTokenApprove = useTokenApprove(tokenContract, spenderAddress);
    const [requestedApproval, setRequestedApproval] = useState(false);
    // const tokenAllowedStatus = tokenAllowance && !tokenAllowance.isLessThan(new BigNumber(10).pow(77));
    const tokenAllowedStatus =useMemo(()=>
    tokenAllowance && !tokenAllowance.isLessThan(
     new BigNumber(amount).times(new BigNumber(10).pow(decimals))
    ),
    [amount,tokenAllowance,decimals]) ;
    // 授权
    const handleApprove = useCallback(async () => {
        try {
            setRequestedApproval(true)
            const txHash = await onTokenApprove()
            setRequestedApproval(false)
            if (txHash) { setApproved(approved + 1); }
        } catch (e) {
            console.error(e)
        }
    }, [onTokenApprove, approved])

    return { approved,setApproved,handleApprove, tokenAllowedStatus, requestedApproval }
}


export default useApprovefuc;