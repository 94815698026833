import { Token } from '@pancakeswap-libs/sdk-v2';
// eslint-disable-next-line
import { select } from 'd3';
import { createReducer } from '@reduxjs/toolkit'
import { setAllrange,setClearAll,setPriceInfo,setFeeTier,recordSharesResult,tokenAamountValue,tokenBamountValue,Fields, selectToken,tokenAmount,switchToken, typeLeftRangeInput, typeRightRangeInput} from './actions'

export type FullRange = true
export interface griDexLiquidityState {
  readonly selectToken: {[Fields.TOKEN_A]:string,[Fields.TOKEN_B]:string};
  readonly tokenAamountValue: string;
  readonly tokenBamountValue: string;
  readonly tokenAmount: {[Fields.TOKEN_A]:string,[Fields.TOKEN_B]:string};
  readonly leftRangeTypedValue: string;
  readonly rightRangeTypedValue: string;
  readonly sharesResult: any
  readonly feeTier:{
    fee: number
    gridexType: 0|16|64|256
    impAddress: string
    recommended: boolean
  };
  readonly priceInfo:{
    price:string;
    result:any;
    migGridinitialized:boolean;
    onlyTokenFromPrice:string;
    onlyTokenToPrice:string;
  }
  readonly clearState:boolean;
  readonly rangeAllvalue:Array<any>;
}

const initialState: griDexLiquidityState = {
  selectToken:{[Fields.TOKEN_A]:"",[Fields.TOKEN_B]:""},
  tokenAamountValue:"",
  tokenBamountValue:"",
  tokenAmount:{[Fields.TOKEN_A]:"",[Fields.TOKEN_B]:""},
  // 这个和chart图有关所以初始化为 0 字符串
  leftRangeTypedValue: '0',
  rightRangeTypedValue: '0',

  sharesResult: {},
  feeTier:{
    fee: 0,
    gridexType: 0,
    impAddress: "",
    recommended: false
  },

  priceInfo:{
    price:"",
    result:"",
    migGridinitialized:true,
    onlyTokenFromPrice:"",  // minPrice大于等于这个值则只能输入tokenFrom的数量
    onlyTokenToPrice: "", // maxPrice小于这个值则只能输入tokenTo的数量
  },
  clearState:false,
  rangeAllvalue:["0","0"]
}
// dexs:['MISTSWAP','TANGOSWAP','LAWSWAP','COWSWAP','ONEBCH','TROPICALFINANCE','VERSEDEX','EMBERSWAP']

export default createReducer<griDexLiquidityState>(initialState, (builder) =>
  builder
    .addCase(selectToken, (state, { payload}) => {
      return {...state,selectToken:{...payload}}
    })
    .addCase(tokenAmount, (state, { payload}) => {
      return {...state,tokenAmount:{...payload}}
    })
    .addCase(tokenAamountValue, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        tokenAamountValue: typedValue,
      }
    })
    .addCase(tokenBamountValue, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        tokenBamountValue: typedValue,
      }
    })

    .addCase(switchToken, (state) => {
      return {
        ...state,
        selectToken:{
          [Fields.TOKEN_A]: state.selectToken[Fields.TOKEN_B],
          [Fields.TOKEN_B]: state.selectToken[Fields.TOKEN_A]
        }
      }
    })
    .addCase(typeLeftRangeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        leftRangeTypedValue: typedValue,
      }
    })
    .addCase(typeRightRangeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        rightRangeTypedValue: typedValue,
      }
    })

    .addCase(recordSharesResult, (state, { payload: { sharesResultVal } }) => {
      return {
        ...state,
        sharesResult: sharesResultVal,
      }
    })

    .addCase(setFeeTier, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        feeTier: typedValue,
      }
    })

    .addCase(setPriceInfo, (state, { payload: { priceInfoValue } }) => {
      return {
        ...state,
        priceInfo: priceInfoValue,
      }
    })
    .addCase(setClearAll, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        ...initialState,
        clearState: typedValue,
      }
    })

    .addCase(setAllrange, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        rangeAllvalue: typedValue,
      }
    })


    
)
