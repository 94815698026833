import { Card, Flex,Text,Button, Spinner, ChevronDownIcon} from '@pancakeswap-libs/uikit'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from 'hooks'

import ListContent from './component/ListContent'
import { useGriLiquidityListState, useGriLiquidityListStateHandlers } from '../../state/griLiquidityList/hooks'
import SwitchBtn from './SwitchBtn'
import useGetOrderData from './hooks/useOrder'
import Pagination from '../../components/Pagination/Pagination'
import Loading from '../../components/Loading'
import HistorySwitchBtn from './HistorySwitchBtn'
import BuySwitchBtn from './BuySwitchBtn'




const StylePanle = styled(Flex)`
      /* background-color:${({ theme })=>theme.colors.background}; */
      /* border-radius: 10px; */
      /* border:1px solid ${({ theme }) => theme.colors.borderColor}; */
      /* overflow: hidden; */
`
const StyleRow20 = styled(Flex)<{isMobile:boolean,width?:string}>`
      display: flex;
      width:${props => props.width ? props.width : '20%'};
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
      justify-content: center;
`
const StyleRow25 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:25%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`
const StyleDown = styled(Text)`
    display: flex;
    align-items: center;
    width:2%;
`

const StyleTh = styled(Flex)`
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   /* background-color:${({ theme })=>theme.colors.background}; */
`
const SpinnerOuterContainer = styled.div`
  padding: 100px;
  padding-bottom: 200px;
  text-align: center;
`
const SpinnerInnerContainer = styled.span`
  display: inline-block;
  position: relative;
  width: 64px;
`
const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 20px;
  cursor:pointer;
`
const StyleLoadingPanel = styled(Flex)`
   width:200px;
`

const OrderList: React.FC= () => {
    const TranslateString = useI18n();
    const { isDesktop,isMobile } = useMatchBreakpoints();

    const defaultPageSize=15;

    const initPage=0;
    const [activeTab,setActiveTab]=useState<number>(1);
    const [pageNumber,setPageNumber]=useState<number>(initPage);

    const { data,isMoreStatus,orderPedding,readMorePedding} = useGetOrderData(activeTab,pageNumber,defaultPageSize);


    
    
    const onSelecth=(select:number)=>{
        setActiveTab(select);
        setPageNumber(initPage);
    }
    
    const isBuyTab=useMemo(()=>activeTab===2 || activeTab===5,[activeTab])
    return <>
    
                      <Flex p={2} >
                          <Flex flex={1}>
                            <SwitchBtn  onChange={onSelecth} status={activeTab}/>
                          </Flex>
                          
                      </Flex>
                      <Flex p={2} mt={2} key="111" flexDirection='column'>
                        {/* BUY */}
                        {isBuyTab && <Flex mb={4}><BuySwitchBtn onChange={onSelecth} status={activeTab}/></Flex>}
                        {/* History */}
                        {(activeTab===3 || activeTab===4) && <Flex mb={4}><HistorySwitchBtn onChange={onSelecth} status={activeTab}/></Flex>}
                        
                        {orderPedding?<>
                        <StylePanle flex={1} flexDirection="column" >
                            {isDesktop?
                            <StyleTh flex={1} p={2}>   
                                <StyleRow20 width='15%'   isMobile={!isDesktop}>{TranslateString(13013,"Maker Pays")}</StyleRow20>
                                <StyleRow20 width='15%'  isMobile={!isDesktop}>{TranslateString(13014,"Maker Receives")}</StyleRow20>
                                <StyleRow20 width='20%'   isMobile={!isDesktop}>{TranslateString(13015,"Rate")}</StyleRow20>
                                <StyleRow20 width={activeTab===3?"29%":"20%"}  isMobile={!isDesktop}>{TranslateString(13016,"Valid Until")}</StyleRow20>

                                {  (activeTab===1 || activeTab===4) && <StyleRow20 width={activeTab===4?"18%":"20%"} isMobile={!isDesktop}>{TranslateString(13057,"Taker Address")}</StyleRow20> }
                                {  (isBuyTab || activeTab===3) && <StyleRow20 width="20%" isMobile={!isDesktop}>{TranslateString(13070,"Maker Address")}</StyleRow20> }
                                {  activeTab===4  && <StyleRow20   width='10%'  isMobile={!isDesktop}>{TranslateString(13017,"Status")}</StyleRow20>}

                                {  (activeTab===1 || activeTab===2) &&  <StyleRow20 width='10%'  isMobile={!isDesktop}/>}
                               

                            </StyleTh>
                            :""}
                            {data && data?.length>0 && data.map((t,idx)=>{
                                // const res=data.pairs.find((p:any)=>p.id===t.pair.id);
                                return <Flex key={`key-${t.id}`} mt={2} ><ListContent activeTab={activeTab} pair={t.pair} item={t}/></Flex>
                            })}
                            {/* <Pagination current={currentPage} count={count} onClick={(evt) => { console.log("ss",evt)} } name='historybenlockss' /> */}
                        </StylePanle>
                        
                        { isMoreStatus ?
                            <Flex alignItems='center' pt={2} flexDirection='column'>
                                    {readMorePedding?
                                    <Button variant="text" onClick={()=>{setPageNumber(pre=>pre+defaultPageSize)}}>
                                        {TranslateString(13042,'Load More')}
                                        <ArrowIcon color="primary" toggled={false} />
                                    </Button>
                                    :<StyleLoadingPanel pt={2}>
                                        <Loading/>
                                    </StyleLoadingPanel>
                                    }
                           </Flex>:""
                        }
                      
                        
             
                      

                        </>
                        :
                        <Flex flex={1}  justifyContent='center' alignItems='center'>
                            <SpinnerOuterContainer><SpinnerInnerContainer><Spinner/></SpinnerInnerContainer></SpinnerOuterContainer>
                        </Flex>
                        }
                       
                  
                      </Flex>
    </>
    
    
 
}
export default OrderList

