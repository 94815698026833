import { Currency, Trade, TradeType } from '@pancakeswap-libs/sdk-v2'
import React, { useMemo, useState } from 'react'
import { Text , Button, Flex } from '@pancakeswap-libs/uikit'
import { TranslateString } from 'utils/translateTextHelpers'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import { BigNumber } from 'bignumber.js'
import { Repeat } from 'react-feather'
import { StyledBalanceMaxMini } from 'components/swap/styleds'
import toPrecision from 'utils/getPrecision'
import { useUserSlippageTolerance } from 'state/user/hooks'

interface HelpInfoProps {
    outputCurrency:Currency;
    outputAmount:string;
}
const HelpInfo: React.FC<HelpInfoProps> = ({
  outputCurrency,
  outputAmount,
})=> {
const [userSlippageTolerance] = useUserSlippageTolerance()
const big=(str:string | number)=>{
      return new BigNumber(str)
}
 const minReturn =()=>{
    const minAmount=big(outputAmount).minus(big(outputAmount).times(big((userSlippageTolerance/10000)))) 
    return  toPrecision(new BigNumber(minAmount),4)
 }
  return (
    <Flex flexDirection='column'>
      <AutoColumn gap="0px">
          
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">
              {TranslateString(10078,'Minimum received')}
            </Text>
            <QuestionHelper text={TranslateString(10081,"Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.")} />
          </RowFixed>
          <RowFixed>
              <Text fontSize="14px">
                 {minReturn()} {outputCurrency.symbol}
              </Text>
          </RowFixed>
        </RowBetween>
        {/* <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{TranslateString(10079,'Price Impact')}</Text>
            <QuestionHelper text={TranslateString(10082,"The difference between the market price and estimated price due to trade size.")} />
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{TranslateString(10080,'Liquidity Provider Fee')}</Text>
            <QuestionHelper text={TranslateString(10083,"For each trade a 0.2&#37; fee is paid. 0.15&#37; goes to liquidity providers and 0.05&#37; goes to the BenSwap treasury.").replace(/&#37;/g,"%")} />
          </RowFixed>
          <Text fontSize="14px">
              111
          </Text>
        </RowBetween> */}
        
      </AutoColumn>
    </Flex>
  )
}
export default HelpInfo