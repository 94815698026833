import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex,Text,Button, useModal,Modal, CloseIcon } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { CheckCircle, Copy, ExternalLink } from 'react-feather'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNotifStateHandlers } from 'state/GlobalNotifications/hooks'
import RomveDlg from './RomveDlg'





interface RemoveBtnProps {
  orderId:string
  pairsAddress:string
}

const Container = styled(Flex)<{isCopy:boolean}>`
    border-radius: 5px;
    padding: 2px;
    color: ${({isCopy,theme})=>{
      if(theme.isDark){
          return  isCopy?'#32e666':theme.colors.primary
      }
      return  isCopy?'#32e666':theme.colors.primary
      
    }};
    width: 22px;
    height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
      background-color: ${({ theme })=>theme.colors.background};
    }
    
    /* svg{
      fill: #ff0000;
    } */
`



const ShareIconBtn: React.FC<RemoveBtnProps> = ({pairsAddress,orderId}) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const [onPresentSettings] = useModal(<RomveDlg pairsAddress={pairsAddress} orderId={orderId}  />)
  const [copyState,setCopyState]=useState(false);
  const {onAddNotif}=useNotifStateHandlers()
  const handCopy=()=>{
        setCopyState(true);
        setTimeout(()=>{setCopyState(false);},3000);
        onAddNotif({
          variant: "success",
          i18n: { index: 13088, value: "Address has been copied to the clipboard!" }
        });
  }

  return (<Container isCopy={copyState} title={TranslateString(13080,"Copy Link")}>
            {copyState?<CheckCircle style={{cursor:'pointer'}}/>
            :
            <CopyToClipboard 
                onCopy={handCopy} 
                text={`${window.location.origin}/#/P2pSwap/${pairsAddress}/${orderId}`}>                        
                   <Copy style={{cursor:'pointer'}}/>
            </CopyToClipboard>
            }
          </Container>
  )
}

export default ShareIconBtn
