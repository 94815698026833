import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import TicketInput from 'components/TicketInput'
import { useActiveWeb3React } from 'hooks'
import { useAddPopup } from 'state/application/hooks'
import { TransactionSubmittedContent } from 'components/TransactionConfirmationModal'
import { ChainId, Token } from '@pancakeswap-libs/sdk-v2'
import BigNumber from 'bignumber.js'
import { Spinner } from 'components/Shared'
import toPrecision from 'utils/getPrecision'
import { useTokenBalance } from 'state/wallet/hooks'
import { useLimitListStateHandlers } from 'state/limit/hooks'
import Circle from '../../../assets/images/blue-loader.svg'
import { useLimtiExOrderFuc } from '../hooks/useOrder'








interface EditDlgProps {
  tokenAmount:string
  pairAddress: string
  tokenA:Token|null|undefined 
  orderId:string
  onDismiss?:()=>void;
}


const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`
const ErrorRow = styled(Text)`
  text-align: center;
  color: #ff6a6a;
  margin-top: 5px;
`

const ComfirmationWrapper = styled.div`
  margin: -24px;
`

const EditDlg: React.FC<EditDlgProps> = ( {tokenAmount,pairAddress,tokenA,orderId,onDismiss}) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const { account } = useActiveWeb3React();
  const addPopup = useAddPopup();
  const [resHash,setResHash]=useState<string>("");
  const [pendding,setPendding]=useState<boolean>();
  const [erroMessage,setErroMessage]=useState<string>("");
  const {onRefreshLiquidity}=useLimitListStateHandlers();

  // 获取余额
  const tokenBalance=useTokenBalance(account ?? undefined,tokenA as any)
  const Balance=useMemo(()=>{
    const res=new BigNumber(tokenBalance?.raw.toString()||"0").div(new BigNumber(10).pow(tokenA?.decimals||0));
    // return  toNewPrecision(new BigNumber(6655656565.10123),2)
    return  toPrecision(res,2)
  },[tokenBalance,tokenA?.decimals])
  const {updateOrder}=useLimtiExOrderFuc(pairAddress);
  // 币的总数
  const [amount,setAmount]=useState<any>(tokenAmount);
  const handleTotalChange = (e: React.FormEvent<HTMLInputElement>) => {
        setAmount(e.currentTarget.value)
  }
  const handleSelectMax = useCallback(() => {
        setAmount(Balance)
  }, [Balance])


  const erroTip:Array<string>=[];
  if(new BigNumber(amount).gt(new BigNumber(Balance))) erroTip.push(TranslateString(11117,"Insufficient Balance"));

  const transferHand=async ()=>{ 
    setPendding(true);
    setErroMessage("")
      // 提示信息
    const base = TranslateString(13054,`Modified Successfully`)
    const ret=await updateOrder(orderId,new BigNumber(amount).times(new BigNumber(10).pow(tokenA?.decimals||0)).toFixed(),setResHash)
    if(ret.status){
        addPopup(
          {txn: { hash:ret.res.transactionHash,success: true,summary:base}},
           ret.res.transactionHash
        )
        onRefreshLiquidity(true);
        setTimeout(()=>{onRefreshLiquidity(false);},1000)
    }else{
        setErroMessage(ret.res)
    }
    setPendding(false);
  }
  const showComponent=()=>{
    if(resHash!==""){
      return <ComfirmationWrapper><TransactionSubmittedContent isNoShowClose chainId={ChainId.MAINNET} hash={resHash} onDismiss={()=>{setResHash("");(onDismiss as any)()}} /></ComfirmationWrapper>
    }
    if(pendding&&resHash===""){
      return  <Flex justifyContent='center' alignItems="center">
             <CustomLightSpinner src={Circle} alt="loader" size="90px" />
            </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }
 
  return (<>

       <Modal title={TranslateString(13028,"Modification")} onDismiss={onDismiss}>

        {showComponent()}
        {resHash===""&&erroMessage==="" && !pendding?<>
              <TicketInput
                 value={amount}
                 onSelectMax={handleSelectMax}
                 onChange={handleTotalChange}
                 max={Balance}
                 symbol={tokenA?.symbol||""}
                 availableSymbol={tokenA?.symbol||""}
                 />
          {erroTip.map(t=>(
            <ErrorRow>{t}</ErrorRow>
          ))}
          
          <Flex flex={1} mt={3} justifyContent="center">
            <Button onClick={transferHand} disabled={erroTip.length>0}>{TranslateString(11139,"Confirm")}</Button>
          </Flex>
        
        </>:""}

        
         
       </Modal>
  </>
  )
}

export default EditDlg
