// import React, { useContext, useEffect } from 'react'
// import styled from 'styled-components'
// import useI18n from 'hooks/useI18n'
// import useTheme from 'hooks/useTheme'

// const CustomSelect = styled.select`
//   border: 0px;
//   width: 100%;
//   -webkit-box-align: center;
//   align-items: center;
//   background-color: ${(props:{backgroundColor?: boolean}) => props.backgroundColor ?'rgb(90, 77, 63)':"rgb(244, 239, 234)" };
//   /* background-color: rgb(90, 77, 63); */
//   border-radius: 16px;
//   display: flex;
//   height: 72px;
//   padding: 0px 16px;
//   color: rgb(249, 140, 8);
//   font-size: 100%;
//   :focus-visible{
//     border: 0px;
//   }
//   :focus { outline: none; };
// `
// interface SelectInputProps {
//   valueObj:Array<{label:string,value:string,select?:boolean}>
//   setValue:(ev:string)=>void
// }

// const SelectInput: React.FC<SelectInputProps> = ({valueObj,setValue}) => {
//   const TranslateString = useI18n();
//   const { isDark, toggleTheme, theme } = useTheme();
//   return (
//     <div>
//       <CustomSelect backgroundColor={isDark} onChange={(e)=>setValue(e.target.value)}>
//         {
//           valueObj.map((t,idx)=>{
//             return <option  value={t.value}  selected={t.select?t.select:false}>{t.label}</option>
//           })
//         }
//       </CustomSelect>
//     </div>
//   )
// }
// export default SelectInput
/*   eg:
  // -----input--------
  // valueObj select 选择对象，value 值，label 显示名称，select默认选中
  // setValue 接收onChange 的回调

  // ------demo-------- 
  const ops=[
    {value:"val1",label:"value1"},
    {value:"val2",label:"value2",select:true},
    {value:"val3",label:"value3"}
  ]
  const [val,setVal]=useState(""); 
 <div style={{width:'200px'}}><SelectInput valueObj={ops} setValue={setVal}/></div> */


 import React,  { useState, useRef, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { ArrowDropDownIcon, Flex, Text } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'

const DropDownHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0px 16px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  border: 1px solid ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.input};
  transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div`
  min-width: 136px;
  height: 0;
  position: absolute;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.input};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }
  box-shadow: 1px 10px 10px -12px;
`
const Box= styled.div`
`
const DropDownContainer = styled(Box)<{ isOpen: boolean }>`
  cursor: pointer;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  height: 100%;
  min-width: 136px;
  user-select: none;
  // z-index: 20;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }

  ${(props) =>
    props.isOpen &&
    css`
      ${DropDownHeader} {
        border-bottom: 1px solid ${({ theme }) => theme.colors.input};
        box-shadow: ${({ theme }) => theme.colors.input};
        border-radius: 16px 16px 0 0;
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border-radius: 0 0 16px 16px;
        box-shadow: ${({ theme }) => theme.colors.primary};
        border-top:1px solid ${({ theme }) => theme.colors.textSubtle};
        border-style: dashed;
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  display:flex;
  align-items: center;
  list-style: none;
  padding: 8px 16px;
  &:hover {
    background: ${({ theme }) => theme.isDark?'#796666':'#dfd6d3'};
  }
`
const Image = styled.img`
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
`

export interface SelectProps {
  options: OptionProps[];
  onOptionChange?: (option: OptionProps) => void
  placeHolderText?: string
  selectIndex?: number
}

export interface OptionProps{
  img?:{
    url:string,
    name:string
  }
  label: string
  value: any
  i18n?:{index:number,value:string},
}

const Select: React.FunctionComponent<SelectProps> = ({
  options,
  onOptionChange,
  selectIndex,
  placeHolderText,
  ...props
}) => {
  const TranslateString = useI18n()
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [optionSelected, setOptionSelected] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(selectIndex||0)

  
  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen)
    event.stopPropagation()
  }

  const onOptionClicked = (selectedIndex: number) => () => {
    setSelectedOptionIndex(selectedIndex)
    setIsOpen(false)
    setOptionSelected(true)

    if (onOptionChange) {
      onOptionChange(options[selectedIndex])
    }
  }

  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])


  useEffect(()=>{
    if(selectIndex!==undefined && selectIndex!==selectedOptionIndex){
      setSelectedOptionIndex(selectIndex)
      setIsOpen(false)
      setOptionSelected(true)
      // console.log('options',options)

      if (onOptionChange) {
          onOptionChange(options[selectIndex])
      }
    }

  },
  // eslint-disable-next-line
  [selectIndex])

  return (<>{options[0]?.label?
    <DropDownContainer isOpen={isOpen} {...props}>
      <DropDownHeader onClick={toggling}>
        {/* {options[selectedOptionIndex] && options[selectedOptionIndex].img?<Image src={options[selectedOptionIndex].img.url} alt={options[selectedOptionIndex].img.name} />:''} */}
        <Text color={!optionSelected && placeHolderText ? 'text' : undefined}>
          {!optionSelected && placeHolderText ? placeHolderText :(options[selectedOptionIndex] && options[selectedOptionIndex].label)}
        </Text>
      </DropDownHeader>
      <ArrowDropDownIcon color="text" onClick={toggling} />
      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option, index) =>
            placeHolderText || index !== selectedOptionIndex ? (
              <ListItem style={{textAlign:'left'}} onClick={onOptionClicked(index)} key={option.label}>
                {option.img?<Image src={option.img.url} alt={option.img.name} />:''}
                <Text>{option.label}</Text>
              </ListItem>
            ) : null,
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>:''}
    </>
  )
}

export default Select
