import { Card, Flex,Text,Button, Spinner, ChevronDownIcon} from '@pancakeswap-libs/uikit'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import Select from 'components/SelectInput'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useCurrency, useTokenInfo } from 'hooks/Tokens'
import { get0xAddress, getBchAddress } from 'utils/addressHelpers'
import TokenSelectPanel from 'components/TokenSelectPanel'
import { OptionProps } from 'components/SelectInput/SelectInput'
import ListContent from './component/ListContent'
import SwitchBtn from './SwitchBtn'
import useGetOrderData, { useOrderTotal } from './hooks/useOrder'
import Loading from '../../components/Loading'








const StylePanle = styled(Flex)`
`
const StyleRow20 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:20%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
      justify-content: center;
`
const StyleTh = styled(Flex)`
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   /* background-color:${({ theme })=>theme.colors.background}; */
`
const SpinnerOuterContainer = styled.div`
  padding: 100px;
  padding-bottom: 200px;
  text-align: center;
`
const SpinnerInnerContainer = styled.span`
  display: inline-block;
  position: relative;
  width: 64px;
`
const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 20px;
  cursor:pointer;
`
const StyleLoadingPanel = styled(Flex)`
   width:200px;
`
const StyleSelelct= styled(Select)`
   height: 40px;
   >:first-child{
      box-shadow: none;
      >:first-child{
        box-shadow: none;
      }
   }
`
const StyleFilter = styled(Flex)`
   @media (max-width:450px) {
    flex-direction:column;
   }
   flex-direction:row;
`

const RightFlex = styled(Flex)`
   @media (max-width: 450px) {
    margin-left:0px;
    margin-top:16px;
   }
   margin-left:16px;
`

const OrderList: React.FC= () => {
    const TranslateString = useI18n();
    const { isDesktop,isMobile } = useMatchBreakpoints();
    const defaultPageSize=15;
    const initPage=0;
    const [activeTab,setActiveTab]=useState<number>(4);
    const [pageNumber,setPageNumber]=useState<number>(initPage);


    const defaultData:any=useTokenInfo('');
    const [currentSelect,setCurrentSelect]=useState({
      SELL:defaultData,
      BUY:defaultData
    })
    const switchToken=()=>{
      setCurrentSelect({SELL:currentSelect.BUY,BUY:currentSelect.SELL})
    }

    const { data,isMoreStatus,orderPedding,readMorePedding} = useGetOrderData(activeTab,pageNumber,defaultPageSize,currentSelect.SELL?.address,currentSelect.BUY?.address);
    const onSelecth=(select:number)=>{
        setActiveTab(select);
        setPageNumber(initPage);
        setCurrentSelect({
          SELL:defaultData,
          BUY:defaultData
        });
    }



    return <>
    
                      <Flex p={2} >
                          <Flex flex={1}>
                            <SwitchBtn  onChange={onSelecth} status={activeTab}/>
                          </Flex>
                          
                      </Flex>
                      <Flex p={2} mt={2} key="111"  flexDirection='column'>
                        {
                          activeTab===4&&
                          <StyleFilter mb={3} justifyContent='center'>
                          <Flex flexDirection='column'>
                            <Text>{TranslateString(13013,"Maker Pays")}</Text> 
                            <Flex style={{height:'40px'}}>
                            <TokenSelectPanel
                                hideInput={false}
                                label=""
                                value=""
                                showMaxButton
                                currency={currentSelect?.SELL}
                                onUserInput={(value:string)=>{console.log("")}}
                                otherCurrency={currentSelect?.BUY}
                                onCurrencySelect={(selectCurrency:any)=>{
                                  if((selectCurrency.symbol==='SBCH' && currentSelect.BUY?.symbol==='SBCH') 
                                      || 
                                      (selectCurrency?.address && selectCurrency?.address===currentSelect.BUY?.address)){
                                     switchToken();
                                  }else{
                                    const selectObj=[selectCurrency][0];
                                    if(selectObj.symbol==='SBCH'){(selectObj as any).address=getBchAddress();}
                                    setCurrentSelect(pre=>({...pre,SELL:selectObj.name==='-1'?defaultData:selectObj}))
                                  }
                                }}
                                id="sell-swap-currency"
                              />
                            </Flex> 

                          </Flex>
                          <RightFlex  flexDirection='column'>
                            <Text>{TranslateString(13014,"Maker Receives")}</Text> 
                            <Flex style={{height:'40px'}}>
                            <TokenSelectPanel
                                hideInput={false}
                                label=""
                                value=""
                                showMaxButton
                                currency={currentSelect?.BUY}
                                onUserInput={(value:string)=>{console.log("")}}
                                otherCurrency={currentSelect?.SELL}
                                onCurrencySelect={(selectCurrency:any)=>{
                                  if((selectCurrency.symbol==='SBCH' && currentSelect.SELL?.symbol==='SBCH') 
                                     || 
                                     (selectCurrency?.address && selectCurrency?.address===currentSelect.SELL?.address)){
                                     switchToken();
                                  }else{
                                    const selectObj=[selectCurrency][0];
                                    if(selectObj.symbol==='SBCH'){(selectObj as any).address=getBchAddress();}
                                    setCurrentSelect(pre=>({...pre,BUY:selectObj.name==='-1'?defaultData:selectObj}))
                                  }
                                }}
                                id="buy-swap-currency"
                              />
                            </Flex> 

                          </RightFlex>
                          </StyleFilter>
                        }
                      
                     

                        {orderPedding?<>
                        <StylePanle flex={1} flexDirection="column" >
                            {isDesktop?
                            <StyleTh flex={1} p={2}>   
                                <StyleRow20 style={{width:isDesktop?"15%":""}} isMobile={!isDesktop}>{TranslateString(13013,"Maker Pays")}</StyleRow20>
                                <StyleRow20 style={{width:isDesktop?"15%":""}} isMobile={!isDesktop}>{TranslateString(13014,"Maker Receives")}</StyleRow20>
                                <StyleRow20 style={{width:isDesktop?"20%":""}} isMobile={!isDesktop}>{TranslateString(13015,"Rate")}</StyleRow20>
                                <StyleRow20 style={{width:isDesktop?"20%":""}} isMobile={!isDesktop}>{TranslateString(13016,"Valid Until")}</StyleRow20>
                                <StyleRow20 style={{width:isDesktop?"20%":""}} isMobile={!isDesktop}>{TranslateString(13017,"Status")}</StyleRow20>
                                <StyleRow20 style={{width:isDesktop?"10%":""}} isMobile={!isDesktop}/>

                            </StyleTh>
                            :""}
                            {data && data?.length>0 && data.map((t,idx)=>{
                                // const res=data.pairs.find((p:any)=>p.id===t.pair.id);
                                return <Flex key={`key-${t.id}`} mt={2} ><ListContent activeTab={activeTab} pair={t.pair} item={t}/></Flex>
                            })}
                            {/* <Pagination current={currentPage} count={count} onClick={(evt) => { console.log("ss",evt)} } name='historybenlockss' /> */}
                        </StylePanle>

                        { isMoreStatus ?
                            <Flex alignItems='center' pt={2} flexDirection='column'>
                                    {readMorePedding?
                                    <Button variant="text" onClick={()=>{setPageNumber(pre=>pre+defaultPageSize)}}>
                                        {TranslateString(13042,'Load More')}
                                        <ArrowIcon color="primary" toggled={false} />
                                    </Button>
                                    :<StyleLoadingPanel pt={2}>
                                        <Loading/>
                                    </StyleLoadingPanel>
                                    }
                           </Flex>:""
                        }
                      
                        
                        
                      

                        </>
                        :
                        <Flex flex={1}  justifyContent='center' alignItems='center'>
                            <SpinnerOuterContainer><SpinnerInnerContainer><Spinner/></SpinnerInnerContainer></SpinnerOuterContainer>
                        </Flex>
                        }
                       
                      
                      </Flex>
    </>
    
    
 
}
export default OrderList

