import { useEffect, useState, useRef } from 'react'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import { getNodeUrl } from 'connectors/index';

const NetWorkUrl = getNodeUrl()
// const RPC_URL = getRpcUrl()
const httpProvider = new Web3.providers.HttpProvider(NetWorkUrl)

/**
 * Provides a web3 instance using the provider provided by useWallet
 * with a fallback of an httpProver
 * Recreate web3 instance only if the ethereum provider change
 */
const useWeb3 = () => {

  const { library, chainId }= useWeb3React()
  const refEth = useRef(library?.provider)
  const [web3, setweb3] = useState(new Web3(Number(chainId) === ChainId.MAINNET && library?.provider || httpProvider))
  
  useEffect(() => {
    if (library?.provider !== refEth.current) {
      setweb3(new Web3(library?.provider || httpProvider))
      refEth.current = library?.provider
    }
  }, [library?.provider])

  return web3
}

export default useWeb3
