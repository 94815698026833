import React from 'react'
import { InjectedModalProps, Modal } from '@pancakeswap-libs/uikit'
import { TranslateString } from 'utils/translateTextHelpers'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'
import ExpertModeSetting from './ExpertModeSetting'

type SettingsModalProps = {
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal: React.FC<InjectedModalProps>  = ({ onDismiss = defaultOnDismiss }: SettingsModalProps) => {
  return (
     <Modal title={TranslateString(10013,'Settings')} onDismiss={onDismiss}>
       <SlippageToleranceSetting />
       <TransactionDeadlineSetting />
       <ExpertModeSetting />
     </Modal>
  )
}

export default SettingsModal
