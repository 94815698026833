import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { setupNetwork } from 'utils/wallet'
import { bsc, injected, walletconnect } from 'connectors'

const useAuth = () => {
  const {activate} = useWeb3React()

  const login = useCallback(
    (connectorId: string) => {
      if (connectorId === 'walletconnect') {
        return activate(walletconnect)
      }
      
      if (connectorId === 'bsc') {
        return activate(bsc)
      }
      return activate(injected, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork()
          if (hasSetup) {activate(injected)}
         }
        }
      )
    },
    [activate],
  )
  return {login}
}

export default useAuth
