import { ethers } from "ethers"

export const getAvggerAddress = () => {
  // return '0x64ae0134Befa943C4965EAd59E0598548d320CaE'
  return '0xa4E5791c64764db3255A58a44cb1Cf4eF89f610D'
}

export const getBenswapGridexRouterAddress = () => {
  return "0xb1964B4573521a21E3c9FC527e15e376E704Ec52"
}

export const getBenswapGridexRouterHelperAddress = () => {
  return "0xdFfE7885fBb66CF97B661A46905e5794470462f6"
}

export const getGridexAvggerAddress = () => {
  return '0xe2Ce50d43a6f1a78950C3C2527cDF29798006FAC'
  // return '0x84411D217C84Ed77BAd252Caf0dBd062C88D2093' // test
}

export const getBchAddress = () => {
  return '0x0000000000000000000000000000000000002711'
}

export const getChecksumAddress = (value) => {
  try {
    return ethers.utils.getAddress(value.toLowerCase())
  } catch (err) {
    return false
  }
}
export const getLimitFactoryAdress = () => {
  return "0x100609b86B1dc4Fc6D31F899447E5D2AE24df8A8"
}

export const getP2PExFactoryAddress = () => {
  return "0x0028d24E1bd811169Acb177b667021C856276902"
}
export const get0xAddress = () => {
  return "0x0000000000000000000000000000000000000000"
}

export default getAvggerAddress