import React, { useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from 'hooks'
import { removeShares } from 'utils/gidex'
import { useAddPopup } from 'state/application/hooks'
import { TransactionSubmittedContent } from 'components/TransactionConfirmationModal'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { Spinner } from 'components/Shared'
import { useLimitListStateHandlers } from 'state/limit/hooks'
import Circle from '../../../assets/images/blue-loader.svg'
import { useLimtiExOrderFuc } from '../hooks/useOrder'



interface RomveDlgProps {
  orderId:string
  onDismiss?:()=>void;
  pairsAddress:string
}
const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const ComfirmationWrapper = styled.div`
  margin: -24px;
`

const RomveDlg: React.FC<RomveDlgProps> = ({ orderId,pairsAddress,onDismiss}) => {
  const TranslateString = useI18n()
  const addPopup = useAddPopup();
  const [resHash,setResHash]=useState<string>("");
  const [pendding,setPendding]=useState<boolean>();
  const [erroMessage,setErroMessage]=useState<string>("");
  const {onRefreshLiquidity}=useLimitListStateHandlers();
  const {delOrder}=useLimtiExOrderFuc(pairsAddress);

  const removeHand=async ()=>{ 
      setPendding(true);
      setErroMessage("")
      // 提示信息
      const base = `Remove successfully`
      const ret=await delOrder(orderId,setResHash);
      if(ret.status){
        addPopup(
          {txn: { hash:ret.res.transactionHash,success: true,summary:base}},
           ret.res.transactionHash
        )
        onRefreshLiquidity(true);
        setTimeout(()=>{onRefreshLiquidity(false);},1000)
      }else{
        setErroMessage(ret.res)
      }
    
   
   
    setPendding(false);
  }
  const showComponent=()=>{
    if(resHash!==""){
      return <ComfirmationWrapper><TransactionSubmittedContent isNoShowClose chainId={ChainId.MAINNET} hash={resHash} onDismiss={()=>{setResHash("");(onDismiss as any)()}} /></ComfirmationWrapper>
    }
    if(pendding&&resHash===""){
      return  <Flex justifyContent='center' alignItems="center">
             <CustomLightSpinner src={Circle} alt="loader" size="90px" />
            </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }

  return (<>

       <Modal title={TranslateString(13029,"Cancellation")} onDismiss={onDismiss}>
         {showComponent()}
         {resHash===""&&erroMessage==="" && !pendding?<>
          <Flex flex={1} justifyContent="center">
            <Text bold mr={3}>{TranslateString(13020,"Are you sure you want to cancel this order?")}</Text>
          </Flex>

          <Flex flex={1} mt={3} justifyContent="center">
            <Button onClick={removeHand}>{TranslateString(11139,"Confirm")}</Button>
          </Flex>
         
         </>:""
         }
    
         
       </Modal>
  </>
  )
}

export default RomveDlg
