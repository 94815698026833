import { ChainId } from '@pancakeswap-libs/sdk-v2'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'

import { Flex,Text,Button, LinkExternal } from '@pancakeswap-libs/uikit'
import { ArrowUpCircle } from 'react-feather'
import { AutoColumn } from '../Column'
import { getEtherscanLink } from '../../utils'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  isNoShowClose?:boolean
}

const TransactionSubmittedContent = ({ onDismiss, chainId, hash,isNoShowClose }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
       {!isNoShowClose?
        <ContentHeader onDismiss={onDismiss}>{TranslateString(10107,'Transaction Submitted')}</ContentHeader>
       :
       <Flex flex={1} justifyContent="center">
         <Text fontSize="20px" bold>{TranslateString(10107,'Transaction Submitted')}</Text>
       </Flex>
       }
       
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={97} color={theme.colors.primary} />
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')}>{TranslateString(10066,'View on Smartscout')}</LinkExternal>
          )}
          <Button onClick={onDismiss} mt="20px">
            {TranslateString(438,'Close')}
          </Button>
         
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
