import React, { useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import BigNumber from 'bignumber.js'
import { ChainId,Currency} from '@pancakeswap-libs/sdk-v2'
import {  get0xAddress, getChecksumAddress } from '../../utils/addressHelpers'

import Logo from '../../components/Logo'
import Circle from '../../assets/images/blue-loader.svg'
import { Spinner } from '../../components/Shared'
import { useP2pExFuc } from './hooks/useP2p'
import CustomTransactionSuccessContent from './CustomTransactionSuccessContent'


const ComfirmationWrapper = styled.div`
  margin: -24px;
`


const StylePanel= styled(Flex)`
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 20px;
  flex-direction: column;
  padding:16px;
  justify-content: center;
  /* align-items: center; */
`
const Image = styled(Logo)`
  height: 24px;
  width: auto;
  display: inline-block;
  z-index: 1;
`

const TokenImage = styled(Image)<{isMobile?: boolean}>`
  height: ${props => props.isMobile ? "12px" : '24px'};
  width: ${props => props.isMobile ? "12px" : '24px'};
`

const StyleRate = styled(Flex)`
 background-color:${({ theme })=>theme.colors.background};
  border-radius: 5px;
`


const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

interface ConfirmModalProps {
  onDismiss?:()=>void;
  pairAddress:string|null;
  rate:string;
  tokenAAmount:string;
  tokenBAmount:string;
  tokenA:Currency | null | undefined;
  tokenB:Currency | null | undefined;
  expirationTime?:{i18n:{index:number,value:string},label:string,value:string};
  onClearData:()=>void;
  targetAddress:string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({onClearData,onDismiss,pairAddress,tokenAAmount,tokenBAmount,rate,tokenA,tokenB,expirationTime,targetAddress}) => {
  const TranslateString = useI18n();
  const [orderPendding,setOrderPendding]=useState(false);
  const [orderHash,setOrderHash]=useState<string>("");
  const [erroMessage,setErroMessage]=useState<string>("");
  const {createOrder}=useP2pExFuc(pairAddress);
  const [urlStr,setUrlStr]=useState<string>("");


  
  const handOrder=async ()=>{
    setOrderHash("")
    setErroMessage("")
    setOrderPendding(true);

    const tokenA_amount=new BigNumber(tokenAAmount).times(new BigNumber(10).pow(tokenA?.decimals||0)).toFixed();
    const currentTime=(new Date()).getTime()/1000;
    // max time;

    const expiration_Time=new BigNumber(currentTime).plus(new BigNumber(expirationTime?.value||"0")).toFixed(0);
    const expiration_OrderTime=expirationTime?.value==="100000000000"?"100000000000":expiration_Time;
    const ret=await createOrder(rate,expiration_OrderTime,tokenA_amount,targetAddress===""?get0xAddress():targetAddress);
    if(ret.status){
      // console.log("limit success",ret.res)
      setUrlStr(`${ret.res.events.CreateOrder.address}/${ret.res.events.CreateOrder.returnValues.orderId}`)
      setOrderHash(ret.res.transactionHash);
      onClearData();
    }else{
      setErroMessage(ret.res);
    }
    setOrderPendding(false);
    
 } 

  const showComponent=()=>{
    if(orderHash!==""){
      return <ComfirmationWrapper>
                <CustomTransactionSuccessContent p2pUrl={`${window.location.origin}/#/P2pSwap/${urlStr}`} isNoShowClose chainId={ChainId.MAINNET} hash={orderHash} onDismiss={onDismiss as any} />
            </ComfirmationWrapper>
    }
  
    if(orderPendding&&orderHash===""){
      return  <Flex justifyContent='center' alignItems="center">
             <CustomLightSpinner src={Circle} alt="loader" size="90px" />
            </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }

  return (<>
       <Modal title={TranslateString(13001, 'Create Order')} onDismiss={onDismiss}>
        {showComponent()}

        {/* sure modal */}
        { erroMessage==="" && orderHash==="" && !orderPendding?<>
           <Flex mb={2} flexDirection="column">
                <Text fontSize='18px' bold>{TranslateString(13004, 'You pay:')}</Text>
                <Flex alignItems="center" mt={2} mb={2}>
                            {tokenA && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress((tokenA as any).address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${(tokenA as any).address} Logo`} />}
                            <Text ml={2} bold>{tokenAAmount}</Text>
                            <Text ml={2} bold>{tokenA?.symbol}</Text>
                </Flex>
                <StyleRate justifyContent='space-between'  p={3} mt={2} mb={2}>
                      <Text bold>{TranslateString(13006, 'Rate:')}</Text> 
                      <Text bold>1 {tokenA?.symbol}= {rate} {tokenB?.symbol}</Text> 
                </StyleRate>

                <Text fontSize='18px' bold>{TranslateString(13005, 'You receive:')}</Text>
                <Flex alignItems="center" mt={2} mb={2}>
                            {tokenB && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress((tokenB as any).address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${(tokenB as any).address} Logo`} />}
                            <Text ml={2} bold>{tokenBAmount}</Text>
                            <Text ml={2} bold>{tokenB?.symbol}</Text>
                </Flex>

                <Flex justifyContent='space-between'   mt={2} mb={2}>
                      <Text bold>{TranslateString(13002, 'Expires In:')}</Text> 
                      <Text bold>{expirationTime?TranslateString(expirationTime.i18n.index,expirationTime.i18n.value):""}</Text> 
                </Flex>
                {/* <Text fontSize='18px' bold>{tokenA?.symbol}/{tokenA?.symbol}</Text>

                <Text fontSize='18px' bold>{tokenA?.symbol}/{tokenB?.symbol}</Text> */}
                {/* <Text fontSize='18px' bold>{tokenA?.symbol}/{tokenB?.symbol}</Text>
                </Flex>
                <StylePanel flex={1}>
                    <Flex flex={1} justifyContent="space-between">
                        <Flex alignItems="center">
                            {selectToken.tokenA && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(selectToken.tokenA)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${selectToken.tokenA} Logo`} />}
                            <Text ml={2} bold>{tokenA?.symbol}</Text>
                        </Flex>
                        <Text bold>{toFormatPrice(new BigNumber(tokenAamountValue),2)}</Text>
                    </Flex>
                    <Flex flex={1} mt={2} justifyContent="space-between">
                        <Flex alignItems="center">
                            {selectToken.tokenB && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(selectToken.tokenB)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${selectToken.tokenB} Logo`} />}
                            <Text ml={2} bold>{tokenB?.symbol}</Text>
                        </Flex>
                        <Text bold>{toFormatPrice(new BigNumber(tokenBamountValue),2)}</Text>
                </Flex>
                    <Flex flex={1} mt={3} justifyContent="space-between">
                        <Text fontSize='14px' bold>{TranslateString(11126,"fee")}</Text>
                        <Text fontSize='14px' bold>{feeTier.fee/100}%</Text>
                    </Flex>
                </StylePanel>
                <Flex mt={3}><Text>{TranslateString(11122,"Range of exchange rate")}</Text></Flex>
                <Flex mt={2} mb={3}>
                    <StylePanel flex={1} alignItems="center">
                        <MinText fontSize='12px'>{TranslateString(11124,"Min Price")}</MinText>
                        <Text bold>{toFormatPrice(new BigNumber(leftRangeTypedValue),2)}</Text>
                        <MinText fontSize='12px'>{tokenB?.symbol}/{tokenA?.symbol}</MinText>
                    </StylePanel>
                    <StylePanel ml={3} flex={1} alignItems="center">
                        <MinText fontSize='12px'>{TranslateString(11125,"Max Price")}</MinText>
                        <Text bold>{toFormatPrice(new BigNumber(rightRangeTypedValue),2)}</Text>
                        <MinText fontSize='12px'>{tokenB?.symbol}/{tokenA?.symbol}</MinText>
                    </StylePanel>
                </Flex>

                <StylePanel alignItems="center">
                        <MinText fontSize='14px'>{TranslateString(11123,"present price")}</MinText>
                        <Text>{new BigNumber(pircie).toFixed(6)}</Text>
                        <MinText fontSize='14px'>{tokenB?.symbol}/{tokenA?.symbol}</MinText>
                </StylePanel>      */}
                <Flex mt={3}  justifyContent='center'>
                   <Button onClick={handOrder}>{TranslateString(11139,"Confirm")}</Button>
               </Flex>
               </Flex>
           </>:""
        }   
       </Modal>
  </>
  )
}

export default ConfirmModal
