import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from 'hooks'
import { useAddPopup } from 'state/application/hooks'
import { TransactionSubmittedContent } from 'components/TransactionConfirmationModal'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { Spinner } from 'components/Shared'
import { transformShares } from 'utils/gidex'
import Web3 from 'web3'
import Circle from '../../../assets/images/blue-loader.svg'
import { useGriLiquidityListStateHandlers } from '../../../state/griLiquidityList/hooks'

const ComfirmationWrapper = styled.div`
  margin: -24px;
`

interface RomveDlgProps {
  onDismiss?:()=>void;
  pairAddress?: string;
  ids:any;
  removedAmounts:any;
  amount:string;
}
const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`
const ErrorRow = styled(Text)`
  text-align: center;
  color: #ff6a6a;
  margin-top: 5px;
`

const TransferDlg: React.FC<RomveDlgProps> = ({ amount,onDismiss,ids,pairAddress,removedAmounts }) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const { account } = useActiveWeb3React();
  const [toAddress,setToAddress] = useState<string>();
  const addPopup = useAddPopup();
  // const [resHash,setResHash]=useState<string>("");
  const [pendding,setPendding]=useState<boolean>();
  const [erroMessage,setErroMessage]=useState<string>("");
  const [checkAddress,setCheckAddress]=useState<boolean>(true)
  const {onRefreshLiquidity}=useGriLiquidityListStateHandlers();
  const resHash=useRef<any>("");
  const setResHash=(evt:string)=>{
    resHash.current=evt;
  }

  const erroTip:Array<string>=[];
  if(!Web3.utils.isAddress(toAddress||"")) erroTip.push(TranslateString(11138,"Invalid Address"));

  const transferHand=async ()=>{ 
    setPendding(true);
    setErroMessage("")
    try{
      // 提示信息
      const base = `Send ${amount} Liquidity`
      const res=await transformShares(account,toAddress,setResHash,pairAddress,ids,removedAmounts)
      console.log("成功",res)
      addPopup(
          {
            txn: { hash:resHash.current,success: true,summary:base}
          },
          resHash.current
      )
      onRefreshLiquidity(true);
      setTimeout(()=>{onRefreshLiquidity(false);},1000)
    }catch(e:any){
      console.log("失败",e)
      setErroMessage(e.message?e.message:e.toString())
    }
    setPendding(false);
  }
  const showComponent=()=>{
    if(resHash.current!==""){
      return <ComfirmationWrapper><TransactionSubmittedContent isNoShowClose chainId={ChainId.MAINNET} hash={resHash.current} onDismiss={onDismiss as any} /></ComfirmationWrapper>
    }
    if(pendding&&resHash.current===""){
      return  <Flex justifyContent='center' alignItems="center">
             <CustomLightSpinner src={Circle} alt="loader" size="90px" />
            </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }
 
  return (<>

       <Modal title={TranslateString(11135,"Send")} onDismiss={onDismiss}>

        {showComponent()}
        {erroMessage==="" && !pendding && !resHash.current?<>
          <Input
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder={TranslateString(11137,"Target Address")}
              pattern="^(0x[a-fA-F0-9]{40})$"
              onChange={(e)=>{setToAddress(e.target.value)}}
              value={toAddress}/>
          {/* //  <Flex mt={3}>
          //     <Input 
          //     type="number" 
          //     step="1"
          //     min="1" 
          //     value=""
          //     placeholder='数量'
          //     onChange={()=>{console.log("change")}} />
          //  </Flex> */}
          {erroTip.map(t=>(
            <ErrorRow>{t}</ErrorRow>
          ))}
          
          <Flex flex={1} mt={3} justifyContent="center">
            <Button onClick={transferHand}>{TranslateString(11139,"Confirm")}</Button>
          </Flex>
        
        </>:""}

        
         
       </Modal>
  </>
  )
}

export default TransferDlg
