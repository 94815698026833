import { ChainId } from '@pancakeswap-libs/sdk-v2'
import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'

import { Flex,Text,Button, LinkExternal } from '@pancakeswap-libs/uikit'
import { ExternalLink } from 'react-feather'
import { getEtherscanLink } from '../../utils'
import { AutoColumn } from '../../components/Column'
import { ConfirmedIcon, ContentHeader, Section, Wrapper } from '../../components/TransactionConfirmationModal/helpers'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  isNoShowClose?:boolean,
  p2pUrl?:string
}


const StyleLinkExternal = styled.a`
  color:${({ theme })=>theme.colors.primary};
  max-width: 250px;
  :hover{
    text-decoration: revert;
  }
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`

const Stylep2pUrl = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`

const ExternalLinkIcon = styled(ExternalLink)`
    color:${({ theme })=>theme.colors.primary};
    width: 20px;
    height: 20px;
    margin-left: 4px;
`


const CustomTransactionSuccessContent = ({ onDismiss, chainId, hash,isNoShowClose,p2pUrl }: TransactionSubmittedContentProps) => {
  return (
    <Wrapper>
      <Section>
       {!isNoShowClose?
        <ContentHeader onDismiss={onDismiss}>{TranslateString(13052,'Order Created')}</ContentHeader>
       :
       <Flex flex={1} justifyContent="center">
         <Text fontSize="20px" bold>{TranslateString(13052,'Order Created')}</Text>
       </Flex>
       }
       
        <ConfirmedIcon>
          <img src="/images/checked.png" alt="Successful" width="128" height="128" style={{width:128,height:128}}/>

          {/* <ArrowUpCircle strokeWidth={0.5} size={97} color={theme.colors.primary} /> */}
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')}>{TranslateString(10066,'View on Smartscout')}</LinkExternal>
          )}
          
          {p2pUrl && p2pUrl!=='' && (
            <Flex>
              <LinkExternal href={p2pUrl}>{TranslateString(13071,'View Trading Page')}</LinkExternal>
            </Flex>
          )}
          <Button onClick={onDismiss} mt="20px">
            {TranslateString(438,'Close')}
          </Button>
         
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default CustomTransactionSuccessContent
