import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Text, Link, ChevronDownIcon } from '@pancakeswap-libs/uikit'
import { darken } from 'polished'

import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import Logo from 'components/Logo'
import { Token } from '@pancakeswap-libs/sdk-v2'
import { getBchAddress, getChecksumAddress } from 'utils/addressHelpers'
import useI18n from 'hooks/useI18n';
import { useCurrency, useToken, useTokenInfo } from 'hooks/Tokens'

const CurrencySelect = styled.button<{ selected: boolean }>`
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    background-color: transparent;
		color: ${(props) => props.theme.colors.primary};
    border-radius: 12px;
    outline: none;
    cursor: pointer;
    user-select: none;
    border: none;
    padding: 8px 16px;
		background-color: ${(props) => props.theme.colors.input};
		border-radius: ${(props) => props.theme.radii.default};`

const TokenImage = styled(Logo)`
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
`

interface SelectCurrencyProps {
	disabaleAddress:string;
	value?:string;
	onSelect: (token: string) => void
}

export default function ({disabaleAddress,value,onSelect }: SelectCurrencyProps) {
	const [token, setToken] = useState<Token|null|undefined>();
	const [modalOpen, setModalOpen] = useState(false);

	const valueToken=useTokenInfo(value);
	const tokenInfo=useTokenInfo(disabaleAddress);

	const  currencyselected=useCurrency(value===getBchAddress()?'ETH':value);
    const  otherSelected=useCurrency(disabaleAddress===getBchAddress()?'ETH':disabaleAddress);



	const handleChange = useCallback((values) => {
		setToken(values);
		setModalOpen(false)
		onSelect(values.address||values.symbol)
	},[onSelect]);
	const TranslateString = useI18n();

	useEffect(()=>{
		if(token?.address!==valueToken?.address){
		   setToken(valueToken)
		}
	},[valueToken,token?.address])

	return (
		<>
			<CurrencySelect
				selected={!!token?.address}
				className="open-currency-select-button"
				onClick={() => setModalOpen(true)}
			>
				{token?.address && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(token?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${token?.address} Logo`} />}
				<Text color={token?.symbol ? "text" : "rgb(132 131 131)"} style={{ flex: 1, textAlign: 'left', textOverflow: "ellipsis", height: 56, lineHeight: '56px', fontSize: 16 }}>
					{token?.symbol || TranslateString(11102, 'Select a token')}
				</Text>
				<ChevronDownIcon color='rgb(132 131 131)'/>
			</CurrencySelect>
			<CurrencySearchModal
			    otherSelectedCurrency={otherSelected}
			    selectedCurrency={currencyselected}
				isOpen={modalOpen}
				onDismiss={() => setModalOpen(false)}
				onCurrencySelect={handleChange}
			/>
		</>
	)
}