import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Tag,Heading, IconButton, Text, Flex, useModal, Input } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useTokenInfo } from 'hooks/Tokens';
import useI18n from 'hooks/useI18n';
import { useGriLiquidityState } from 'state/griDexLiquidity/hooks';
import toPrecision, { toFormatPrice } from 'utils/getPrecision';
import getRate from './utils/getRate';


interface PageHeaderProps {
    tokenA?:string;
    tokenB?:string;
    values:string;
    changeValues:(evt:string)=>void;
    chartsData:Array<any>;
    isHighest:boolean;
}

const StylePanel= styled(Flex)`
  background-color:${({ theme })=>theme.colors.background};
  border:1px solid ${({ theme })=>theme.colors.borderColor};
  border-radius: 20px;
`
const StyleTokenTag= styled(Text)`
  padding: 4px 12px;
  background-color: ${({ theme })=>theme.isDark?"#444444":"#ececec"};
  border-radius: 8px;
`
const StyleInput= styled(Input)`
  font-size:18px;
  padding:0;
  background-color:${({ theme })=>theme.colors.background};
  box-shadow:none !important;
  :focus{
   border:none;
   box-shadow:none !important;
  }
  /* border-radius: 8px; */
`

const ExchangeRate: React.FC<PageHeaderProps>= ({isHighest,chartsData,values,changeValues}) => {
  const TranslateString = useI18n();
  const [val,setVal]=useState(values);
 
  const {priceInfo,selectToken,rightRangeTypedValue,leftRangeTypedValue}=useGriLiquidityState();
  const tokenA=useTokenInfo(selectToken.tokenA);
  const tokenB=useTokenInfo(selectToken.tokenB);
  // 计算最接近的值
  const onBlurChange=useCallback(()=>{
    const res=getRate(isHighest,chartsData,val,priceInfo);

    if(!isHighest && new BigNumber(res).lt(new BigNumber(rightRangeTypedValue))){
      changeValues(res);
      setVal(toFormatPrice(new BigNumber(res),4,4));
    }else if(!isHighest){
      // left >right 还原数据
      changeValues(leftRangeTypedValue);
      setVal(toFormatPrice(new BigNumber(leftRangeTypedValue),4,4));
    }
 
    if(isHighest && new BigNumber(res).gt(new BigNumber(leftRangeTypedValue))){
      changeValues(res);
      setVal(toFormatPrice(new BigNumber(res),4,4));
    }else if(isHighest){
       // right<left 还原数据
      changeValues(rightRangeTypedValue);
      setVal(toFormatPrice(new BigNumber(rightRangeTypedValue),4,4));
    }
  },[priceInfo,chartsData,changeValues,isHighest,val,leftRangeTypedValue,rightRangeTypedValue])

  useEffect(()=>{
      setVal(toFormatPrice(new BigNumber(values),4,4));
  },[values])


  return (
        <StylePanel  p={3} flex={1} flexDirection='column'  justifyContent='space-evenly'>
             <Text fontSize="14px">
               {isHighest?TranslateString(11109,"Max Price"):TranslateString(11108,"Min Price")}
             </Text>
             <Flex alignItems="center">
                <StyleInput
                type="number"
                scale="lg"
                min={0}
                placeholder="0.0"
                value={val}
                onBlur={onBlurChange}
                onChange={(event: any)=>{setVal(event.target.value)}}
                />
             </Flex>
             <Text fontSize="14px">{tokenB?.symbol} {TranslateString(10044,"per")} {tokenA?.symbol}</Text>

        </StylePanel>
    )
}

export default ExchangeRate
