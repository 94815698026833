import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'

// Retrieve allowance for any token
export const useAllowance = (tokenContract: Contract, spenderAddress: string, dependency?: any) => {
  const { account } = useWeb3React()
  const [allowance, setAllowance] = useState<any>(null)
  const fetch = useCallback(async () => {
    try {
      const res = await tokenContract.methods.allowance(account, spenderAddress).call()
      setAllowance(new BigNumber(res))
    } catch (e) {
      setAllowance(null)
    }
  },[account, spenderAddress, tokenContract])

  useEffect(() => {
      if (account && spenderAddress && tokenContract && tokenContract.options.address){
        fetch()
      }else{setAllowance(null);}
    
  }, [account, spenderAddress, tokenContract, dependency,fetch])
  // console.log(allowance.toNumber())
  return allowance
}

export default useAllowance