import { ChainId } from '@pancakeswap-libs/sdk-v2'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'
import { Flex,Text,Button, LinkExternal } from '@pancakeswap-libs/uikit'
import { ArrowUpCircle } from 'react-feather'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'
import { AutoColumn } from '../../../components/Column'
import { getEtherscanLink } from '../../../utils'



type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  isNoShowClose?:boolean
}
const StyledInternalLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin: 7px 0;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const MySpan = styled.span`
  display: inline-block;
  text-align: left;
`

const TransactionSubmittedContent = ({ onDismiss, chainId, hash,isNoShowClose }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)
  const toPage=()=>{
    onDismiss();
    window.location.href=`${window.location.origin}/#/GriDexLiquidityList`;
  }
  return (
    <Wrapper>
      <Section>
       {!isNoShowClose?
        <ContentHeader onDismiss={onDismiss}>{TranslateString(10107,'Transaction Submitted')}</ContentHeader>
       :
       <Flex flex={1} justifyContent="center">
         <Text fontSize="20px" bold>{TranslateString(10107,'Transaction Submitted')}</Text>
       </Flex>
       }
       
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={97} color={theme.colors.primary} />
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          <MySpan>
            {chainId && hash && (
              <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')}>{TranslateString(10066,'View on Smartscout')}</LinkExternal>
            )}
            <StyledInternalLink onClick={toPage}>
                    {TranslateString(13093,'View my positions')}
            </StyledInternalLink>
          </MySpan>
          <div>
            <Button onClick={onDismiss} mt="20px">
              {TranslateString(438,'Close')}
            </Button>
          </div>

         
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
