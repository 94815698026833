import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from 'hooks'
import { removeShares } from 'utils/gidex'
import { useAddPopup } from 'state/application/hooks'
import { TransactionSubmittedContent } from 'components/TransactionConfirmationModal'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { Spinner } from 'components/Shared'
import Circle from '../../../assets/images/blue-loader.svg'
import { useGriLiquidityListStateHandlers } from '../../../state/griLiquidityList/hooks'

const ComfirmationWrapper = styled.div`
  margin: -24px;
`

interface RomveDlgProps {
  deprecated: boolean
  onDismiss?:()=>void;
  pairAddress?: string;
  ids:any;
  removedAmounts:any;
  amount:string;
}
const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const RomveDlg: React.FC<RomveDlgProps> = ({ deprecated, amount,onDismiss,ids,pairAddress,removedAmounts }) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const { account } = useActiveWeb3React();
  const addPopup = useAddPopup();
  // const [resHash,setResHash]=useState<string>("");
  const [pendding,setPendding]=useState<boolean>();
  const [erroMessage,setErroMessage]=useState<string>("");
  const {onRefreshLiquidity}=useGriLiquidityListStateHandlers();
  const resHash=useRef<any>("");

  const removeHand=async ()=>{ 
    setPendding(true);
    setErroMessage("")
    try{
      // 提示信息
      const base = `Remove ${amount} Liquidity`
      const res=await removeShares(deprecated, account,setResHash,pairAddress,ids,removedAmounts)
      console.log("成功",res)
      console.log(resHash.current)
      addPopup(
          {
            txn: { hash:resHash.current,success: true,summary:base}
          },
          resHash.current
      )
      onRefreshLiquidity(true);
      setTimeout(()=>{onRefreshLiquidity(false);},1000)
    }catch(e:any){
      console.log("失败",e)
      setErroMessage(e.message?e.message:e.toString())
    }
    setPendding(false);
  }
  const showComponent=()=>{
    if(resHash.current!==""){
      return <ComfirmationWrapper><TransactionSubmittedContent isNoShowClose chainId={ChainId.MAINNET} hash={resHash.current} onDismiss={onDismiss as any} /></ComfirmationWrapper>
    }
    if(pendding&&resHash.current===""){
      return  <Flex justifyContent='center' alignItems="center">
             <CustomLightSpinner src={Circle} alt="loader" size="90px" />
            </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }

  const setResHash=(evt:string)=>{
        resHash.current=evt;
  }
 
  return (<>
       <Modal title={TranslateString(11134,"Remove")} onDismiss={onDismiss}>
         {showComponent()}
         {erroMessage==="" && !pendding && !resHash.current?<>
          <Flex flex={1} mt={3} justifyContent="center">
            <Text bold mr={3}>{TranslateString(11136,"Removing LP amount:")}</Text>
            <Text>{amount}</Text>
          </Flex>
          {/* <Input 
          type="number" 
          step="1"
          min="1" 
          value={amount}
          placeholder={removedAmounts[1]?"test":removedAmounts}
          onChange={(e)=>{setAmount(e.target.value)}} /> */}
          <Flex flex={1} mt={3} justifyContent="center">
            <Button onClick={removeHand}>{TranslateString(11139,"Confirm")}</Button>
          </Flex>
         
         </>:""
         }
    
         
       </Modal>
  </>
  )
}

export default RomveDlg
