import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import Logo from 'components/Logo'
import { getChecksumAddress } from 'utils/addressHelpers'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'

interface isMobileProp {
  isMobile?: boolean
}

const Container = styled.span<isMobileProp>`
  display: block;
  white-space: nowrap;
  height: ${props => props.isMobile ? "24px" : '32px'};
  width: ${props => props.isMobile ? "24px" : '32px'};
  position: relative;
`

const Image = styled(Logo)`
  height: 24px;
  width: auto;
  display: inline-block;
  position: absolute;
  z-index: 1;
`

const TokenImage = styled(Image)<isMobileProp>`
  height: ${props => props.isMobile ? "12px" : '16px'};
  width: ${props => props.isMobile ? "12px" : '16px'};
  right: 0;
  bottom: 0;
`

const BaseTokenImage = styled(Image)<isMobileProp>`
  height: ${props => props.isMobile ? "19px" : '26px'};
  width: ${props => props.isMobile ? "19px" : '26px'};
  left: 0;
  top: 0;
`
const TokenImages = styled(Image)<isMobileProp>`
  height: ${props => props.isMobile ? "19px" : '24px'};
  width: ${props => props.isMobile ? "19px" : '24px'};
  /* left: 0;
  top: 0; */
`

const BoneImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  display: block;
`

interface IconProps {
    token0: any,
    token1: any
}

const TableLpLcon : React.FC<IconProps> = ({ token0, token1 }) => {
    const { isDesktop, isMobile } = useMatchBreakpoints()
    return (
        <Container isMobile={isMobile}>
             <BaseTokenImage isMobile={isMobile} srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(token0)}/logo.png`,"/images/UNKNOWNSEP20.png"]} alt={`${token0} Logo`} />
             <TokenImage  isMobile={isMobile} srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(token1)}/logo.png`,"/images/UNKNOWNSEP20.png"]} alt={`${token1} Logo`} />
        </Container>
    )
}

export default TableLpLcon
