import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Toggle, Text } from '@pancakeswap-libs/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import { TranslateString } from 'utils/translateTextHelpers'
import useI18n from 'hooks/useI18n'
import QuestionHelper from '../QuestionHelper'
import TranslatedText from '../TranslatedText'


const StyledTransactionDeadlineSetting = styled.div`
  margin-bottom: 16px;
`

const Label = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`

const Field = styled.div`
  align-items: center;
  display: inline-flex;
`

const ExpertModeSetting = () => {
  const [isExpertMode, toggleSetExpertMode] = useExpertModeManager()

  return (
    <StyledTransactionDeadlineSetting>
      <Label>
        <Text style={{ fontWeight: 600 }}>
          <TranslatedText translationId={11030}>Expert Mode</TranslatedText>
        </Text>
        <QuestionHelper text={TranslateString(11029,"Allows high slippage trades and optionally adding recipient. Use at your own risk!")} />
      </Label>
      <Field>
        <Toggle checked={isExpertMode} onChange={toggleSetExpertMode} />
      </Field>
    </StyledTransactionDeadlineSetting>
  )
}

export default ExpertModeSetting
