import { createAction } from '@reduxjs/toolkit'

export enum Fields {
    TOKEN_A = 'tokenA',
    TOKEN_B = 'tokenB',
}
export enum Bound {
    LOWER = 'LOWER',
    UPPER = 'UPPER',
}
export const selectToken = createAction<{tokenA:string,tokenB:string}>('addLiquidity/selectToken')
export const tokenAmount = createAction<{tokenA:string,tokenB:string}>('addLiquidity/tokenAmount')
export const tokenAamountValue = createAction<{ typedValue: string }>('addLiquidity/tokenAamountValue')
export const tokenBamountValue = createAction<{ typedValue: string }>('addLiquidity/tokenBamountValue')

export const switchToken= createAction<void>('addLiquidity/switchToken')
export const typeLeftRangeInput = createAction<{ typedValue: string }>('addLiquidity/typeLeftRangeInput')
export const typeRightRangeInput = createAction<{ typedValue: string }>('addLiquidity/typeRightRangeInput')

export const recordSharesResult = createAction<{sharesResultVal: any }>('addLiquidity/recordSharesResult')

export const setFeeTier = createAction<{ typedValue: {
    fee:number,gridexType:16|64|256,impAddress:string,recommended:boolean
} }>('addLiquidity/setFeeTier')

export const setPriceInfo = createAction<{priceInfoValue:{migGridinitialized:boolean,price:string,result:any,onlyTokenFromPrice:string,onlyTokenToPrice:string} }>('addLiquidity/priceInfo')
export const setClearAll = createAction<{ typedValue: boolean }>('addLiquidity/setClearAll')
export const setAllrange = createAction<{ typedValue: Array<string> }>('addLiquidity/setAllrange')