import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Tag,Heading, IconButton, Text, Flex, useModal, Input } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints';
import useI18n from 'hooks/useI18n';
import { useHistory } from 'react-router-dom';
import { useGriLiquidityActionHandlers, useGriLiquidityState, useTokenEchangeInfo } from '../../state/griDexLiquidity/hooks';
import { useTokenInfo } from '../../hooks/Tokens';





interface PageHeaderProps {
    tokenA?:string;
    tokenB?:string;
}

const StylePanel= styled(Flex)`
  /* background-color:${({ theme })=>theme.colors.background}; */
  border-radius: 20px;
`
const StyleTokenFlex= styled(Flex)`
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 10px;
  padding: 2px;
`
const StyleText= styled(Text)`
  cursor: pointer;
  padding:4px 8px;
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 6px;
`

const ClearAllText = styled(Text)`
  border: none;
  text-decoration: none;
  background: none;

  cursor: pointer;
  color: ${({ theme }) => (theme.colors.primary)};
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const TokenSwitchBtn: React.FC<PageHeaderProps>= () => {
  const TranslateString = useI18n();
  const [state,setState]=useState(1);
  const theme = useContext(ThemeContext);  
  const { isMobile,isTablet } = useMatchBreakpoints();
  const isResponse =isMobile || isTablet;

  const history = useHistory();
  const {feeTier,selectToken,tokenBamountValue}=useGriLiquidityState();
  // 设置输入值
  // const { data }=useTokenEchangeInfo(selectToken.tokenA,selectToken.tokenB,feeTier.gridexType);
  const {
    onTokenSwitch,
    onSetClearAll,
    onSetFeeTier,
    onTokenAamountValue,
    onTokenBamountValue,
    onSelectToken,
    onTokenAmount,
    onSetPriceInfo}=useGriLiquidityActionHandlers();
  // 设置选择代币
  const tokenA=useTokenInfo(selectToken.tokenA);
  const tokenB=useTokenInfo(selectToken.tokenB);
  // 备份的数据
  const back=useMemo(()=>{return [tokenB,tokenA]},[tokenB,tokenA]);
  const [toggltData,setToggltData]=useState<Array<any>>([]);
  useEffect(()=>{
    const res=toggltData.find(t=>t?.name===tokenA?.name)
    const res1=toggltData.find(t=>t?.name===tokenB?.name)
    if(!(res && res1) || toggltData.length===0){
      if(tokenA && tokenB){
        setToggltData([tokenA as any,tokenB as any])
      }
    }
    if(!tokenA && !tokenB && toggltData.length>0){
       setToggltData([])
    }
  },[tokenA,tokenB,back,toggltData])

  useEffect(()=>{
    const resA=toggltData.find(t=>t?.name===tokenA?.name)
    const resB=toggltData.find(t=>t?.name===tokenB?.name)
    if((!resA||!resB) && tokenA && toggltData.length>0){
       setState(1)
    }
  },[tokenA,tokenB,toggltData])



  const handCheckout=(num:number,address:string)=>{
        if(address!==selectToken.tokenA){
          // onSelectToken({tokenA:selectToken.tokenB,tokenB:selectToken.tokenA})
          onTokenSwitch();
          onTokenAamountValue(tokenBamountValue);
        }
        setState(num)
  }
 
  const handClear=()=>{
    setState(1);
    setToggltData([]);
    onSetClearAll(true)
    setTimeout(()=>{
      onSetClearAll(false)
    },100)
  }

  return (
        <StylePanel  flex={1}  alignItems="center" justifyContent='end'>
            {!isResponse?<ClearAllText onClick={handClear} mr={3} fontSize='12px' color={theme.isDark?'#f98c08':'rgb(0, 104, 252)'}>{TranslateString(11110,"Clear All")}</ClearAllText>:""}
            {/* {toggltData[0]&&toggltData[1]?
             <StyleTokenFlex mr={2} >
              <StyleText onClick={()=>{handCheckout(1,(toggltData[0] as any)?.address)}} style={{backgroundColor:state===1?(theme.isDark?"rgb(94 93 93)":"rgb(218 218 218)"):""}} fontSize='12px'>{toggltData[0]?.symbol}</StyleText>
              <StyleText onClick={()=>{handCheckout(2,(toggltData[1] as any)?.address)}} style={{backgroundColor:state===2?(theme.isDark?"rgb(94 93 93)":"rgb(218 218 218)"):""}} color='#fffff' fontSize='12px'>{toggltData[1]?.symbol}</StyleText>
             </StyleTokenFlex>
            :""} */}
           
        </StylePanel>
    )
}

export default TokenSwitchBtn
