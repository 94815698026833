import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal } from '@pancakeswap-libs/uikit'
import { useGriLiquidityActionHandlers, useGriLiquidityState, useTokenEchangeInfo } from 'state/griDexLiquidity/hooks';
import BigNumber from 'bignumber.js';
import useI18n from 'hooks/useI18n';
import { getGridexPairTokenReturn } from 'utils/gidex';
import {  debounce, throttle } from 'lodash'
import TokenInput from './TokenInput';
import { useTokenInfo} from '../../hooks/Tokens';
import { useUserSlippageTolerance } from '../../state/user/hooks';






interface RechargeAmountProps {
    tokenA?:string;
    tokenB?:string;
    Aprice?:string,
    Bprice?:string,
    state:boolean,
    result:any,
    chartData:Array<any>
}

const StyleSelectCommission= styled(Text)`
  padding: 4px 6px;
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 8px;
`

const  KeepOutPanel= styled(Flex)<{state:boolean}>`
    opacity: ${(props) => (props.state?1:0.2)};
    pointer-events: ${(props) => (props.state ? "initial" : "none")};
`
// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const RechargeAmount: React.FC<RechargeAmountProps>= ({
  Aprice,
  Bprice,
  state,
  result,
  chartData
}) => {
  const TranslateString = useI18n();
  const [isShowA,setIsShowA]=useState(true);
  const [isShowB,setIsShowB]=useState(true);
  const theme = useContext(ThemeContext);
 

  const {rangeAllvalue,feeTier,priceInfo,selectToken,tokenAamountValue,tokenBamountValue,leftRangeTypedValue,rightRangeTypedValue}=useGriLiquidityState();
  const {onSetAllrange,onRecordSharesResult,onTokenAamountValue,onTokenBamountValue}=useGriLiquidityActionHandlers();
  const tokenA=useTokenInfo(selectToken.tokenA);
  const tokenB=useTokenInfo(selectToken.tokenB);
  const [userSlippageTolerance] = useUserSlippageTolerance();
  const inter= useRef<any>();
  const {onLeftRangeInput,onRightRangeInput}=useGriLiquidityActionHandlers();
  // find Grid
  const getGridexPairTokenReturnFuc=useCallback(async (tokenFrom, tokenTo, gridexType, results,minPrice, maxPrice, tokenFromAmount, tokenToAmount)=>{
      const minGrid = chartData.find(x => x.price0 === minPrice).grid
      const maxGrid = chartData.find(x => x.price0 === maxPrice).grid - 1
      const { amount, sharesResult } = await getGridexPairTokenReturn(tokenFrom, tokenTo, gridexType, results, minGrid, maxGrid, tokenFromAmount, tokenToAmount)
      return { amount, sharesResult };
  },[chartData]) 

  const handAmount=useCallback(async (amountVal:string,type:string)=>{
    // console.log(priceInfo?.price!=='' )
   
 
   
    if(type==="A"){
      onTokenAamountValue(amountVal);
    }
    if(type==="B"){
      onTokenBamountValue(amountVal)
    }
    clearTimeout(inter.current);

    if(priceInfo?.price===''||!leftRangeTypedValue || !rightRangeTypedValue || parseFloat(leftRangeTypedValue) >= parseFloat(rightRangeTypedValue)){
      return; 
    } 
    inter.current=setTimeout(async ()=>{
      if(type==="A"){
        const resValue=new BigNumber(amountVal||"0").times(new BigNumber(10).pow(tokenA?.decimals||0)).toString();
        const { amount, sharesResult } = await getGridexPairTokenReturnFuc(selectToken.tokenA, selectToken.tokenB, feeTier.gridexType, result, leftRangeTypedValue, rightRangeTypedValue, resValue, 0)
        const outAmount=new BigNumber(amount).div(new BigNumber(10).pow(tokenB?.decimals||0)).toString();
        onTokenBamountValue(outAmount)
        onRecordSharesResult(sharesResult)
      }
      if(type==="B"){
        const resValue=new BigNumber(amountVal).times(new BigNumber(10).pow(tokenB?.decimals||0)).toString();
        const { amount, sharesResult } = await getGridexPairTokenReturnFuc(selectToken.tokenA, selectToken.tokenB, feeTier.gridexType, result, leftRangeTypedValue, rightRangeTypedValue,0,resValue)
        const resAmount=new BigNumber(amount).div(new BigNumber(10).pow(tokenA?.decimals||0)).toString();
        onTokenAamountValue(resAmount)
        onRecordSharesResult(sharesResult)
      }
    },1000)

   
  },[priceInfo,feeTier,getGridexPairTokenReturnFuc,onRecordSharesResult,onTokenAamountValue,onTokenBamountValue,selectToken,result,leftRangeTypedValue,rightRangeTypedValue,tokenB?.decimals,tokenA?.decimals])


  const Aamount= useRef<any>();
  const Bamount= useRef<any>();
  useEffect(()=>{
    Aamount.current=tokenAamountValue;
  },[tokenAamountValue])
  useEffect(()=>{
    Bamount.current=tokenBamountValue;
  },[tokenBamountValue])

  // token A B修改触发金额变化
  useEffect(()=>{
    if(tokenAamountValue!==""){
      // 同步会卡主界面
      setTimeout(async ()=>{
        if(Aamount.current==="0" && Bamount.current==="0"){
          onTokenAamountValue("0");
          onTokenBamountValue("0");
          return;
        }
        if(!isShowA || !isShowB || priceInfo?.price==='' || !leftRangeTypedValue || !rightRangeTypedValue || parseFloat(leftRangeTypedValue) >= parseFloat(rightRangeTypedValue)){
          return 
        }
        
        const resValue=new BigNumber(tokenAamountValue||"0").times(new BigNumber(10).pow(tokenA?.decimals||0)).toString();
        const { amount, sharesResult } =await getGridexPairTokenReturnFuc(selectToken.tokenA, selectToken.tokenB, feeTier.gridexType, result, leftRangeTypedValue, rightRangeTypedValue, resValue, 0);
        const outAmount=new BigNumber(amount).div(new BigNumber(10).pow(tokenB?.decimals||0)).toString();
        onTokenBamountValue(outAmount)
        onRecordSharesResult(sharesResult)
      },0)
    }
  },
    // eslint-disable-next-line
  [selectToken.tokenA,selectToken.tokenB,priceInfo])

   // 根据价格范围触发，选择单币内容
  //  useEffect( ()=>{
  //   async function func() {
  //     //  刷新 当前价格 加载完成，初始化价格
  //     if(priceInfo.price===""){return;}
  //     console.log("isShowA",isShowA)
  //     // console.log("范围修改")
  //     if(isShowA && isShowB && priceInfo?.price!=='' && leftRangeTypedValue!=="0" && rightRangeTypedValue!=="0" && parseFloat(leftRangeTypedValue) < parseFloat(rightRangeTypedValue)){
  //       const resValue=new BigNumber(tokenAamountValue||"0").times(new BigNumber(10).pow(tokenA?.decimals||0)).toString();
  //       const { amount, sharesResult } =await getGridexPairTokenReturn(selectToken.tokenA, selectToken.tokenB, feeTier.gridexType,userSlippageTolerance/10, result, leftRangeTypedValue, rightRangeTypedValue, resValue, 0)
  //       const outAmount=new BigNumber(amount).div(new BigNumber(10).pow(tokenB?.decimals||0)).toString();
  //       onTokenBamountValue(outAmount)
  //       onTokenAamountValue(tokenAamountValue)
  //       onRecordSharesResult(sharesResult)
  //     }
     

  //     setIsShowA(true);
  //     setIsShowB(true);
  //     // onlyTokenFromPrice:"",  // minPrice大于等于这个值则只能输入tokenFrom的数量
  //     // onlyTokenToPrice: "", // maxPrice小于这个值则只能输入tokenTo的数量
  //     if(new BigNumber(leftRangeTypedValue).gte(new BigNumber(priceInfo.onlyTokenFromPrice||"0").multipliedBy(0.99999))){
  //        setIsShowB(false);
  //        onTokenAamountValue("0");
  //        onTokenBamountValue("0");
  //     }
  //     if(new BigNumber(rightRangeTypedValue).lte(new BigNumber(priceInfo.onlyTokenToPrice||"0").multipliedBy(1.00001))){
  //       setIsShowA(false);
  //       onTokenAamountValue("0");
  //       onTokenBamountValue("0");
  //     } 
  //   }
  //   // if(rangeAllvalue[0]==="0" && rangeAllvalue[0]==="0"){
  //   //   func()
  //   // }
  //   func()
  //  },
  //  // eslint-disable-next-line
  //  [leftRangeTypedValue,rightRangeTypedValue])


   useEffect(()=>{
    async function func() {
      //  刷新 当前价格 加载完成，初始化价格
      // console.log("同时变化")
      if(priceInfo.price===""){return;}
      const leftRange=rangeAllvalue[0];
      const rightRange=rangeAllvalue[1];

      let  isFeth=true;
      if(!isShowA || !isShowB){
        onTokenAamountValue("0");
        onTokenBamountValue("0");
      }
      setIsShowA(true);
      setIsShowB(true);
      if(new BigNumber(leftRange).gte(new BigNumber(priceInfo.onlyTokenFromPrice||"0"))){
         setIsShowB(false);
         onTokenAamountValue("0");
         onTokenBamountValue("0");
         isFeth=false;
      }
      if(new BigNumber(rightRange).lte(new BigNumber(priceInfo.onlyTokenToPrice||"0"))){
        setIsShowA(false);
        onTokenAamountValue("0");
        onTokenBamountValue("0");
        isFeth=false;
      } 
      


      // console.log("范围修改")
      if(isFeth  && priceInfo?.price!=='' && leftRange!=="0" && rightRange!=="0" && parseFloat(leftRange) < parseFloat(rightRange)){
        const resValue=new BigNumber(tokenAamountValue||"0").times(new BigNumber(10).pow(tokenA?.decimals||0)).toString();
        const { amount, sharesResult } =await getGridexPairTokenReturnFuc(selectToken.tokenA, selectToken.tokenB, feeTier.gridexType, result, leftRange, rightRange, resValue, 0)
        const outAmount=new BigNumber(amount).div(new BigNumber(10).pow(tokenB?.decimals||0)).toString();
        onTokenBamountValue(outAmount)
        onTokenAamountValue(tokenAamountValue)
        onRecordSharesResult(sharesResult)
      }


      onSetAllrange(["0","0"])
     }
     if(rangeAllvalue[0]!=="0" && rangeAllvalue[1]!=="0"){
      func();
     }
   },
   // eslint-disable-next-line
   [rangeAllvalue])

   useEffect(()=>{
      if(selectToken.tokenA!=="" && selectToken.tokenB!==""){
        onTokenAamountValue("0");
        onTokenBamountValue("0");
      }
   },[selectToken.tokenA,selectToken.tokenB,onTokenAamountValue,onTokenBamountValue])

  return (
        <KeepOutPanel state={
           state && 
           selectToken.tokenA!==""&&
           selectToken.tokenB!=="" &&
           priceInfo.price!==""&&
           priceInfo.price!=="-1"
          } flex={1} flexDirection='column'  justifyContent='flex-start'>
              {/* A{new BigNumber(Aprice).toFixed(tokenA?.decimals||0)} */}
              {isShowA||isShowB?
              <Text fontSize='16px'  bold>
                {TranslateString(11105,"Deposit Amounts")}
              </Text>
              :""
              }
             
              {isShowA?
                  <Flex mt={3}>
                    <TokenInput
                      isAllShow={isShowA && isShowB}
                      onInputChange={(val:string)=>{handAmount(val,"A")}}
                      value={tokenAamountValue}
                      tokenAddress={selectToken.tokenA}/>
                  </Flex>
              :""}
         
             {isShowB?
                 <Flex mt={3}>
                 <TokenInput  
                  isAllShow={isShowA && isShowB}
                  disabled={isShowA}
                  onInputChange={(val:string)=>{handAmount(val,"B")}}
                  value={tokenBamountValue}
                  tokenAddress={selectToken.tokenB}/>
                </Flex>
              :""}
            
            
        </KeepOutPanel>
    )
}

export default RechargeAmount
