import React, { useState } from 'react'
import { Price } from '@pancakeswap-libs/sdk-v2'
import { Flex, SyncAltIcon, Text } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import toPrecision from 'utils/getPrecision'
import { StyledBalanceMaxMini } from 'components/swap/styleds'
import { TranslateString } from 'utils/translateTextHelpers'


interface TradePriceProps {
 inputAmounts:string
 outputAmounts:string
 outputSymbol:string |undefined
 inputSymbol:string|undefined
 inputDec:number|undefined
 outputDec:number|undefined
}
const TradePrice: React.FC<TradePriceProps> = ({  
   inputAmounts,
   outputAmounts,
   outputSymbol,
   inputSymbol,
  }) => {

  const [btnStatus,setBtnStatus]=useState(true)
  const big=(str:string | number)=>{
    return new BigNumber(str)
  }
 
  const priceCom=()=>{
   return <Text fontSize="14px" style={{display:'flex'}}>
     
     {btnStatus?`
      ${toPrecision(big(inputAmounts).div(big(outputAmounts)),6)} ${inputSymbol} per ${outputSymbol}`
      :`${toPrecision(big(outputAmounts).div(big(inputAmounts)),6)} ${outputSymbol} per ${inputSymbol}`
     }
     <StyledBalanceMaxMini onClick={()=>{setBtnStatus(!btnStatus)}}>
      <SyncAltIcon style={{cursor:'pointer'}} width="20px" color="primary" />
     </StyledBalanceMaxMini>
   </Text>
  }

  return (
     <> {
    big(inputAmounts).gt(0) && big(outputAmounts).gt(0) 
    && outputSymbol &&  inputSymbol?
    <Flex pb={1} justifyContent='space-between' alignItems='end'>
                
                
        <Text fontSize="14px">{TranslateString(10086,'Price')}</Text>
        {priceCom()}
    
    
    </Flex>:""
   }
   </> 

  )
}

export default TradePrice
