import { getNodeUrl } from 'connectors';
import { ChainId } from '@pancakeswap-libs/sdk-v2'

import Web3 from 'web3'

import { ethers } from 'ethers';
import { UNIT } from 'utils/limitex/price';
import { getP2PExFactoryAddress } from 'utils/addressHelpers';
import P2PEx_ABI from '../../constants/abis/P2PEx.json'
import P2PExFactory_ABI from '../../constants/abis/P2PExFactory.json'


function getWeb3() {
    return new Web3(Number(window.web3?.currentProvider?.chainId) === ChainId.MAINNET && window.web3.currentProvider || new Web3.providers.HttpProvider(getNodeUrl()));
}

export async function p2pExSwap(account, pairAddr, orderId, sureFuc: (hash: string) => void) {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(P2PEx_ABI as any, pairAddr)
    try {
        const tx_ = await contract.methods.dealWithOrder(orderId).send({
            gas: '',
            from: account
        }).on('transactionHash', (tx) => {
            sureFuc(tx)
        })
        const { transactionHash, events: { DealWithOrder: { returnValues: { amountToMaker, amountToTaker } } } } = tx_
        const hash = transactionHash;
        return { tokenAmount: amountToMaker, tokenBmount: amountToTaker, transactionHash: hash }
    } catch (error:any) {
       return error.message ? error.message : error.toString();
    }
}

export async function getPairParams(pairAddr: string) {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(P2PEx_ABI as any, pairAddr)
    const result = await contract.methods.loadParams().call({ gas: 1000000000000 })
    return result
}

export async function getP2PExResult(orderId, fromTokenDecimals, destTokenDecimals) {
    const graphql = JSON.stringify({
        query: `{\n      orders(where:{orderId:"${orderId}"}){\n    target,\n    amount,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n    }\n  }\n}`,
        variables: {}
    })
    const order = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-p2pex", {
        // eslint-disable-next-line
        "body": graphql,
        "method": "POST",
    }).then(res => res.json()).then(res => res.data.orders[0])
    let priceMul: any = 1;
    let priceDiv: any = 1;
    if (fromTokenDecimals >= destTokenDecimals) {
        priceMul = (10 ** (fromTokenDecimals - destTokenDecimals)).toString();
    } else {
        priceDiv = (10 ** (destTokenDecimals - fromTokenDecimals)).toString();
    }
    const reutrnAmount = ethers.BigNumber.from(order.price).mul(priceMul).mul(order.amount).div(UNIT.mul(priceDiv)).toString()
    return reutrnAmount
}
