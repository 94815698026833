import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Alert, Flex, Text } from '@pancakeswap-libs/uikit'
import { useNotifState, useNotifStateHandlers } from 'state/GlobalNotifications/hooks'
import useI18n from 'hooks/useI18n'

const Card = styled.div<any>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
  background-color: ${({ theme }) => theme.colors.invertedContrast};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
`

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.colors.binance};
  font-weight: 500;
`

export const PinkCard = styled(Card)`
  background-color: rgba(255, 0, 122, 0.03);
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
`

const NotificationsPanel = styled(Flex)`
    position: fixed;
    top: 5px;
    right: 20px;
    z-index: 22;
    max-height: 100%;
    overflow-y: auto;
`

const StylePanel = styled(Flex)`
 .fade-in-out {
    opacity: 0;
    transition: opacity 5000ms ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
`

// 创建淡入动画的关键帧
const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
`;
// 创建淡入动画的关键帧
const fadeOutAnimation = keyframes`
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
`;

// 使用动画和过渡属性创建样式组件
const FadeInDiv = styled(Flex)<{isOut:boolean}>`
  opacity: 0;
  animation: ${(props) => (props.isOut ? css`${fadeOutAnimation} 1s ease-in-out forwards` : css`${fadeInAnimation} 500ms ease-in-out forwards`)}; ;
`;  


// let notifs=[{
//     title:"11",
//     variant:"success",
//     content:"复制成功"
//   },{
//     title:"11",
//     variant:"success",
//     content:"复制成功"
//   }];

interface NotifiItemProps {
    idx:number
    title?:string
    variant?:any|"danger"|"success"|"warning"|"info"
    content?:any
    i18n?:{index:number,value:string}
}

export const  NotifiItem= ({idx,title,variant,content,i18n}: NotifiItemProps) => {
    const t = useI18n()
    const {onRemoveNotif}=useNotifStateHandlers();
    const timeRef=useRef<any>();
    const [state,setState]=useState<boolean>(false);
    const removeItem=useCallback(()=>{
        setState(true);
        setTimeout(()=>{onRemoveNotif(idx)},1000)
    },[idx,onRemoveNotif])

    useEffect(()=>{
        timeRef.current=setTimeout(()=>{
        removeItem();
       },3000)
    },[idx,removeItem])
  
 

    return (
        <FadeInDiv isOut={state}>
            <Flex mt={3}  >
            <Alert 
            title={title||''} 
            onClick={()=>{
                clearTimeout(timeRef.current);
                removeItem();
            }} 
            variant={variant}>
                {i18n?.index?
                <Text style={{maxWidth:'180px'}}>{t(i18n.index,i18n.value)}</Text>
                :
                <>{content}</>
                }
            </Alert>
        </Flex>
     </FadeInDiv>
       
        )
}

export const GlobalNotifications = ({ children, ...rest }: any) => {
  const {notifs}= useNotifState();

  useEffect(()=>{
     console.log("notifs",notifs)
  },[notifs])
//   useNotifStateHandlers
  return (<NotificationsPanel flexDirection='column'>
           {
            notifs.map((t,idx)=>{
                return <NotifiItem key={t.id}
                    idx={t.id}
                    title={t?.title}
                    variant={t?.variant} 
                    content={t?.content} 
                    i18n={t?.i18n} />
            })
           }
          </NotificationsPanel>)
}
