import React, { ReactNode, useContext, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Tag,Heading, IconButton, Text, Flex, useModal, Input } from '@pancakeswap-libs/uikit'
import { useTokenInfo } from 'hooks/Tokens';
import useI18n from 'hooks/useI18n';
import { useTokenBalance } from 'state/wallet/hooks';
import { useActiveWeb3React } from 'hooks';
import { BigNumber } from 'bignumber.js'
import AlertHelper from 'components/AlertHelper';



interface TokenInputProps {
    disabled?:boolean;
    tokenAddress?:string;
    value:string;
    onInputChange:(num:string)=>void;
    exchange?:number;
    isAllShow:boolean
}

const StylePanel= styled(Flex)`
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 20px;
`
const StyleShowPanel= styled(Flex)`
  /* background-color:${({ theme })=>theme.colors.background}; */
  border-radius: 20px;
`
const StyleTokenTag= styled(Text)`
  padding: 4px 12px;
  background-color: ${({ theme })=>theme.isDark?"#444444":"#ececec"};
  border-radius: 8px;
  display:flex ;
  line-height: inherit;
  align-items:center ;
  padding:7px;
`
const StyleInput= styled(Input)`
  font-size:18px;

  margin-right:50px ;
  padding:0;
  background-color:${({ theme })=>theme.colors.background};
  box-shadow:none !important;
  :focus{
   border:none;
   box-shadow:none !important;
  }
`
const StyleText= styled(Text)`
  padding: 4px 12px;
  background-color: ${({ theme })=>theme.isDark?"#444444":"#f4efea"};
  border-radius: 8px;
  cursor: pointer;
  :hover{
    background-color:${({ theme })=>theme.isDark?"#63584b":"#f8e8ac"}
  }
`


const TokenInput: React.FC<TokenInputProps>= ({isAllShow,disabled,tokenAddress,value,onInputChange}) => {
  const TranslateString = useI18n();
  const valueToken=useTokenInfo(tokenAddress);
  const { account, chainId } = useActiveWeb3React()
  const tokenAmount=useTokenBalance(account ?? undefined,valueToken as any);
  const balance=useMemo(()=>{
    const amount=new BigNumber(tokenAmount?.raw.toString()||0);
    if(amount.isEqualTo(0)){
       return new BigNumber(0).toFixed()
    }
    const  tokenVal=amount.div(new BigNumber(10).pow(valueToken?.decimals||0))
    return tokenVal.toFixed(6)
  },[tokenAmount,valueToken?.decimals])
  
  const onMaxInput=()=>{
       const amount=new BigNumber(tokenAmount?.raw.toString()||0);
       const tokenVal=amount.div(new BigNumber(10).pow(valueToken?.decimals||0))
       console.log("TokenAmount",tokenVal.toString())
       onInputChange(tokenVal.toString())
  }


  return (
    <>
       {
         disabled?
         <StyleShowPanel p={3} flex={1} flexDirection='column'  justifyContent='space-evenly'>
         
              <Flex alignItems="center">
                 <Flex flex={1}>
                  <Text>{value}</Text>
                 </Flex>

                  <StyleTokenTag fontSize="18px" bold>
                      {
                        valueToken?.symbol?
                        <AlertHelper text={TranslateString(11155,"A very small amount of unused tokens will be returned to your wallet.")}/>
                        :""
                      }
                      {valueToken?.symbol}
                  </StyleTokenTag>
                  
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text fontSize="14px">{TranslateString(11115,"Balance:")}{balance}</Text>
              </Flex>
              

         </StyleShowPanel>
         :
         <StylePanel p={3} flex={1} flexDirection='column'  justifyContent='space-evenly'>
         
              <Flex alignItems="center">
                  <StyleInput
                  disabled={disabled}
                  type="number"
                  scale="lg"
                  min={0}
                  placeholder="0"
                  value={value}
                  onChange={(e)=>{onInputChange(e.target.value)}}
                  />
                  <StyleTokenTag fontSize="18px" bold>
                     {!isAllShow?
                      <AlertHelper text={TranslateString(11155,"A very small amount of unused tokens will be returned to your wallet.")}/>
                       :""
                     }
                     {valueToken?.symbol}
                  </StyleTokenTag>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                {/* <Text fontSize="14px">1,993.08 美元</Text> */}
                <Text fontSize="14px">{TranslateString(11115,"Balance:")}{balance}</Text>
                <Flex alignItems="center">
                    {/* <Text fontSize="14px">余额： {balance}</Text> */}
                    <StyleText ml={2} fontSize="12px" onClick={onMaxInput}>{TranslateString(11114,"Max")}</StyleText>
                </Flex>
              </Flex>
              

         </StylePanel>
       }
    </>
  )
}

export default TokenInput
