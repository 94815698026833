import { createReducer } from '@reduxjs/toolkit'
import { setRefreshlist,setAllowPartDeal } from './actions'


export interface griDexSwapState {
  readonly refresh: boolean
  readonly allowPartDeal:boolean
}

const initialState: griDexSwapState = {
  refresh:false,
  allowPartDeal:true
}

export default createReducer<griDexSwapState>(initialState, (builder) =>
  builder
    .addCase(setRefreshlist, (state, { payload:{refresh}}) => {
      return {...state,refresh}
    })
    .addCase(setAllowPartDeal, (state, { payload:{allowPartDeal}}) => {
      return {...state,allowPartDeal}
    })
)
