import { Input,ArrowDownIcon, Button, CardBody, Flex, IconButton,Text, useModal } from '@pancakeswap-libs/uikit'
import CardNav from 'components/CardNav'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { ArrowWrapper, BottomGrouping } from 'components/swap/styleds'
import BigNumber from 'bignumber.js'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'
import Card from 'components/Card'
import Loading from 'components/Loading'
import toPrecision from '../../utils/getPrecision'












const  StylePanel= styled(Flex)`
  height: 68px;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius:16px;
  /* #5a4d3f */
`
const  TextFlex= styled(Flex)`
  font-weight:bold;
  white-space:nowrap;
`
const  BtnCurrent= styled(Flex)<{isDisable:boolean}>`
  padding:3px 10px 3px 10px;
  font-size: 12px;
  white-space:nowrap;
  color: ${({isDisable,theme})=>isDisable?theme.colors.textDisabled:theme.colors.primary};
  border:1px solid  ${({isDisable,theme})=>isDisable?theme.colors.textDisabled:theme.colors.primary}; 

  border-radius:15px;
  cursor:${({isDisable,theme})=>isDisable?'not-allowed':'pointer'} ;
`
const  StyleTokenRate= styled(Flex)`
font-size: 12px;
white-space:nowrap;
`



const  StyleInput= styled(Input)`
  padding-top: 30px;
  padding-bottom:30px;
  box-shadow: none;
  &:focus:not(:disabled){
    box-shadow:none;
  }
  /* background-color: ${({ theme }) => theme.colors.background}; */
`

const StyledLoadingContainer = styled(Flex)`
  height: 60px;
  align-items: center;
`

const StyledLoadingContainerInner = styled.div`
  margin-top: 2px;
  display: inline-block;
  width: 98%;
  max-width: 150px;
`

export const Wrapper = styled.div`
  position: relative;
`
export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const InfoGrouping = styled(BottomGrouping)`
  padding: 0 .75rem;
`
interface RateProps {
  currentRate:string,
  getCurrentRate: () => void,
  onSetRate: (evt:string) => void,
  form:string|null|undefined,
  to:string|null|undefined
  isLoding:boolean;
}

const Rate:React.FC<RateProps> = ({isLoding,form,to,currentRate,onSetRate,getCurrentRate}) => {
    const handGetCurrentRate=()=>{
          if(form && to){getCurrentRate();}
    }
    return (<StylePanel flex={1} alignItems="center">
        <BtnCurrent isDisable={!form || !to} ml={2} alignItems="center" onClick={handGetCurrentRate}> {TranslateString(13003, 'Current Rate')}</BtnCurrent>
        
    
        <Flex flex={1} pl={2} pr={2}>

          {
            isLoding?
            <StyledLoadingContainer flex={1}>
              <StyledLoadingContainerInner><Loading/></StyledLoadingContainerInner>
            </StyledLoadingContainer>
            :
            <StyleInput
                onChange={(event:any)=>{onSetRate(event.target.value)}}
                //  placeholder="rate"W
                value={currentRate}
            />

          }
          
    
       
               
         <StyleTokenRate alignItems='center'>{to} {TranslateString(10044, 'per')} {form}</StyleTokenRate>    
        </Flex>

    </StylePanel>)
}

export default Rate


