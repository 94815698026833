import React, { useContext, useState } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import useI18n from 'hooks/useI18n'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'constants/localisation/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useAuth from 'hooks/useAuth'
import useProfile from 'hooks/useProfile'
import links from './config'




const Menu: React.FC = props => {
  const { login } = useAuth();
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useGetPriceData()
  const t=useI18n();
  const profile = useProfile();
  const [connector,setConnector]= useState('')

  return (
    <UikitMenu
      connectorId={connector as any}
      links={links(t)}
      priceLink="https://chartpro.benswap.cash/?token=0x77cb87b57f54667978eb1b199b28a0db8c8e1c0b"
      account={account as string}
      login={(connectorId: ConnectorId)=>{setConnector(connectorId);login(connectorId);}}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code }
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd}
      profile={profile}
      {...props}
    />
  )
}

export default Menu