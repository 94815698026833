import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import SelectCurrency from 'components/SelectSearchModal/SelectCurrency'
import { CheckCircle } from 'react-feather'
import { useGriLiquidityActionHandlers, useGriLiquidityState, useTokenFeeInfo } from 'state/griDexLiquidity/hooks'
import BigNumber from 'bignumber.js'
import { getBchAddress } from '../../utils/addressHelpers'




interface SelectTokenProps {
  chartData:Array<any>
}


const StyleCommission= styled(Flex)<{state:boolean}>`
  border:1px solid ${({ theme })=>theme.colors.borderColor} ;
  border-radius:16px ;
  justify-content: end;
  align-items: center;
  opacity: ${(props) => (props.state?1:0.2)};
  pointer-events: ${(props) => (props.state ? "initial" : "none")};
`
const StyleSelectCommission= styled(Text)`
  padding: 4px 6px;
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 8px;
`

const StyleCommissionItem= styled(Flex)`
  border:1px solid ${({ theme })=>theme.colors.borderColor} ;
  border-radius:12px ;
  padding: 12px 8px;
  /* cursor: pointer; */
  :hover{
    /* border:1px solid ${({ theme })=>theme.colors.success} !important ; */
  };
`

const SelectToken: React.FC<SelectTokenProps>= ({chartData}) => {
  const TranslateString = useI18n();
  const [isShow,setIsShow]=useState(false);
  const theme = useContext(ThemeContext);
  const tokenValue=useGriLiquidityState();
  const {onSelectToken,onSetFeeTier,onLeftRangeInput,onRightRangeInput}=useGriLiquidityActionHandlers();

  const [selectVal,setSelectVal]=useState<number>(1);
  const {item}=useTokenFeeInfo(tokenValue.selectToken.tokenA,tokenValue.selectToken.tokenB)


  const description=[
  {
      fee:30,
      desValue:TranslateString(11143,"Best for most pairs.")
  }
  ,
  {
    fee:10,
    desValue:TranslateString(11144,"Best for exotic pairs.")
  },
  {
    fee:5,
    desValue:TranslateString(11145,"Best for stable pairs.")
  }]

  useEffect(()=>{
    if(item && item.length>0){
      // const idx=item.findIndex(t=>t.recommended===true);
      const res=item.find(t=>t.recommended===true);
      setSelectVal(res.fee);
      onSetFeeTier(res);
    }
  },[item,onSetFeeTier])


  const selectItem=(idx:number,t:any)=>{
        setSelectVal(idx);
        onSetFeeTier(t);
  }

  const getItems=()=>{
      return item.length>0 && item.filter(t => t.fee!==10).map((t,idx)=>(
         <StyleCommissionItem 
          style={{border:selectVal===t.fee?`1px solid ${theme.colors.primary}`:`1px solid ${theme.colors.borderColor}`}}
          key={t.fee} 
          // onClick={()=>selectItem(idx,t)} 
          flex={1}
          mr={2}
          flexDirection='column'>
          <Flex flexDirection='column'>
              <Flex justifyContent='space-between' alignItems='center'>
                    <Text fontSize='14px' bold>{t.fee/100}%</Text>
                    {selectVal===t.fee?<CheckCircle color={theme.colors.success} size={14} />:""}
              </Flex>
              
              <Text fontSize='12px'>{(description.find((v,idxs)=>t.fee===v.fee))?.desValue}</Text>
          </Flex>
          {/* <Flex mt={2} flexDirection='column' alignItems='center'>
              <StyleSelectCommission fontSize='12px' bold>{t.selectNum}</StyleSelectCommission>
          </Flex> */}
         </StyleCommissionItem>  
      ))
  }
  // 设置选择token 全局状态变量
  const handSelectTokenA=useCallback((token:string)=>{
    if(token==="SBCH"){
       token=getBchAddress();
    }
    if(token===tokenValue.selectToken.tokenB){
       onSelectToken({tokenA:tokenValue.selectToken.tokenB,tokenB:tokenValue.selectToken.tokenA})
    }else{
      onSelectToken({...tokenValue.selectToken,tokenA:token})
    }

  },[onSelectToken,tokenValue])
 
  const handSelectTokenB=useCallback((token:string)=>{
    if(token==="SBCH"){
      token=getBchAddress();
    }
    if(token===tokenValue.selectToken.tokenA){
      onSelectToken({tokenA:tokenValue.selectToken.tokenB,tokenB:tokenValue.selectToken.tokenA})
    }else{
      onSelectToken({...tokenValue.selectToken,tokenB:token})
    }
  },[onSelectToken,tokenValue])

  return (<>
        <Flex flex={1} flexDirection='column'  justifyContent='space-evenly'>
            <Text fontSize='16px' bold >{TranslateString(11112,"Select Pair")}</Text>
            <Flex mt={3} style={{height:"45px"}}>
                <Flex flex={1}>
                   <SelectCurrency disabaleAddress={tokenValue.selectToken.tokenB}  value={tokenValue.selectToken.tokenA} onSelect={(token:string)=>{handSelectTokenA(token)}} />
                </Flex>
                <Flex ml={3} flex={1}>
                   <SelectCurrency disabaleAddress={tokenValue.selectToken.tokenA}  value={tokenValue.selectToken.tokenB} onSelect={(token:string)=>{handSelectTokenB(token)}} />
                </Flex>
            </Flex>
            {/* {tokenValue.selectToken.tokenA.address} */}
            <StyleCommission state={tokenValue.selectToken.tokenA!==""&&tokenValue.selectToken.tokenB!==""} mt={4} mb={2} p={3} flex={1}>
                <Flex flexDirection="column">
                    <Text bold>{tokenValue.feeTier.fee/100}% {TranslateString(11103,"fee tier")}</Text>
                    {/* <Flex mt={2}>
                      <StyleSelectCommission fontSize='14px' bold>选择 80%</StyleSelectCommission>
                    </Flex> */}
                </Flex>
                <Flex style={{cursor:"pointer"}} flex={1} justifyContent="end"  onClick={()=>{
                    // if(isShow===false){
                    //     liRefList.current=[];
                    // }
                    setIsShow(!isShow);
                }}>
                   <StyleSelectCommission>
                       {isShow?TranslateString(11113,"Hide"):TranslateString(11104,"Edit")}
                   </StyleSelectCommission>
                </Flex>
            </StyleCommission>
            {isShow?<Flex mt={2}>{getItems()}</Flex>:""}
          
        </Flex>
       

  </>
    
  )
}

export default SelectToken
