import React, { useEffect, useState } from 'react'
import { Checkbox, Flex, InjectedModalProps, Modal, Toggle,Text } from '@pancakeswap-libs/uikit'
import { TranslateString } from 'utils/translateTextHelpers'
import styled from 'styled-components'
import { useSmartSwapActionHandlers, useSmartSwapState } from 'state/smartSwap/hooks'
import SlippageToleranceSetting from 'components/PageHeader/SlippageToleranceSetting'

const SwapList = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.input};
  border-radius: 20px;
`


type SettingsModalProps = {
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal: React.FC<InjectedModalProps>  = ({ onDismiss = defaultOnDismiss }: SettingsModalProps) => {
  return (
     <Modal title={TranslateString(10013,'Settings')} onDismiss={onDismiss}>
       {/* checked={isChecked} onChange={toggle} */}
      
       <SlippageToleranceSetting />
       {/* <TransactionDeadlineSetting /> */}
       {/* <TransactionDeadlineSetting /> */}
       {/* <ExpertModeSetting /> */}
        
     
     </Modal>
  )
}

export default SettingsModal

const Label = styled(Text)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  font-weight: 600;
`