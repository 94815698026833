import { Modal,ArrowDownIcon, Button, CardBody, Flex, IconButton,Text, useModal, Input, SyncAltIcon, Spinner, LinkExternal } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import LimitCardNav from 'components/LimitCardNav'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import {  RowBetween } from 'components/Row'
import {  BottomGrouping, StyledBalanceMaxMini } from 'components/swap/styleds'
import AppBody from 'pages/AppBody'
import SwapHeader from 'pages/SmartSwap/xSwapHeaderx'
import React, {  useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'
import Card from 'components/Card'
import { useCurrencyBalances } from 'state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'
import { useParams } from 'react-router-dom'
import { formatDates } from 'pages/P2pOrder/utils/tranDate'
import Logo from 'components/Logo'
import { useAllowance } from 'hooks/useAllowance'
import { CheckCircle, Copy } from 'react-feather'
import { useCurrency, useTokenInfo } from '../../hooks/Tokens'
import { useApprovefuc, useGetOrderInfo, useGetPrice } from './hooks/useP2p'
import { get0xAddress, getBchAddress, getChecksumAddress, getLimitFactoryAdress, getP2PExFactoryAddress } from '../../utils/addressHelpers'
import ConfirmModal from './ConfirmModal'
import Select, { OptionProps } from '../../components/SelectInput/SelectInput'
import toPrecision from '../../utils/getPrecision'
import { useERC20 } from '../../hooks/useContractV2'
import {useTokenApprove} from '../../hooks/useApprove'
import ConnectWalletButton from '../../components/ConnectWalletButton'




const SpinnerOuterContainer = styled.div`
  padding: 100px;
  padding-bottom: 200px;
  text-align: center;
`
const SpinnerInnerContainer = styled.span`
  display: inline-block;
  position: relative;
  width: 64px;
`

const StyleIconPanel = styled(Flex)<{isCopy:boolean}>`
  color: ${({isCopy,theme})=>isCopy?'#2adf8d':theme.colors.primary};
  width: 16px;
  height: 16px;
  align-items: center;
  cursor: pointer;
`

const StyleLinkExternal = styled(Flex)`
  max-width: 130px;
`

const Stylep2pUrl = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`


const Image = styled(Logo)`
  height: 24px;
  width: auto;
  display: inline-block;
  z-index: 1;
`
const TokenImage = styled(Image)<{isMobile?: boolean}>`
  height: ${props => props.isMobile ? "12px" : '40px'};
  width: ${props => props.isMobile ? "12px" : '40px'};
`


export const StylePanel = styled(Card)`
  padding:0px ;
  background-color:${({ theme })=>theme.colors.input};
`

export const Wrapper = styled.div`
  position: relative; 
`
export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const StyleBackGroundPanel = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  padding: 12px 20px 12px 20px;
`
const StyleMaxMini= styled(StyledBalanceMaxMini)`
       background-color: ${({ theme }) => theme.colors.input};
`
const StyleLabel = styled(Text)`
    font-size:13px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: default;
`
const StyleLinkExternals = styled(LinkExternal)`
   font-weight: unset;
   font-size:13px ;
`
interface RouteParams {
  pairAddr: string;
  orderId: string;
}
const P2pSwap = () => {
    const { pairAddr, orderId} = useParams<RouteParams>();
    const { data,orderPedding}=useGetOrderInfo(orderId)
    const tokenFrom=useTokenInfo(data?.pair?.money);
    const fromCurrency=useCurrency(tokenFrom?.address);
    const tokenTo=useTokenInfo(data?.pair?.stock);

    const { account } = useWeb3React();
    const stepStatus=useRef<any>(false);

    const defaultInput:any=useTokenInfo(getBchAddress());
    const defaultOutput=useCurrency('');
    const [currentSelect,setCurrentSelect]=useState({
          INPUT:defaultInput,
          OUTPUT:defaultOutput
    })
    const [tokenAmount,setTokenAmount]=useState<any>(['0','0'])
    const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [fromCurrency ?? undefined,])

    const {rate,isPedding,setRate,getPriceFuc}=useGetPrice(tokenFrom?.address,tokenTo?.address,tokenFrom?.decimals,tokenTo?.decimals)

    const claerData=()=>{
      setCurrentSelect({
        INPUT:defaultInput,
        OUTPUT:defaultOutput
      })
      setRate(1);
      setTokenAmount(['0','0']);
      A_approve.setApproved(A_approve.approved+1);
      stepStatus.current=false;
    }
    // expiration time
    const [selectTime,setSelectTime]=useState<{i18n:{index:number,value:string},label:string,value:string}>({i18n:{index:13007,value:"1 Hour"},label:"1 Hour",value:`${1*3600}`});
    const handleSortOptionChange = (option: OptionProps): void => {
        setSelectTime({i18n:(option.i18n as any),label:option.label,value:option.value})
    }
    const A_approve=useApprovefuc((currentSelect.INPUT as any)?.address,getLimitFactoryAdress(),tokenAmount[0],currentSelect.INPUT?.decimals||0);
    // limitFactory
    const [onPresentSettings] = useModal(<ConfirmModal 
      rate={rate} 
      pairAddress={pairAddr}
      orderId={data?.orderId}
      formAmount={data?.stockAmount}
      toAmount={data?.moneyAmount}
      fromSymbol={tokenFrom?.symbol}
      toSymbol={tokenTo?.symbol}
      onClearData={claerData}
      />);
  

    useEffect(()=>{
      setTokenAmount(pre=>[pre[0],new BigNumber(pre[0]||"0").times(new BigNumber(rate||"0")).toFixed()])
    },[rate])
    // token change init step status
    useEffect(()=>{
      stepStatus.current=false;
    },[currentSelect])
    


   
   const expirySeconds =  parseInt(data?.expireTime);
   const expiryString = (expirySeconds > 75000000000) ? TranslateString(13053,"Forever") : formatDates(expirySeconds*1000);
   const [btnStatus,setBtnStatus]=useState(true);
   const getRate=(direction:boolean)=>{
        let val=new BigNumber(0);
        const priceValue=new BigNumber(rate||"0");
        if(direction){
          val=new BigNumber(1).times(new BigNumber(priceValue))
        }else{
          val=new BigNumber(1).div(new BigNumber(priceValue))
        }
        return toPrecision(val,2)
   }


   // approve
   const [approved, setApproved] = useState(0);
   const tokenContract=useERC20(tokenFrom?.symbol==='SBCH'?getBchAddress():tokenFrom?.address||"");
   const tokenAllowance = useAllowance(tokenContract, getP2PExFactoryAddress(),approved);
   const onTokenApprove = useTokenApprove(tokenContract, getP2PExFactoryAddress());
   const [requestedApproval, setRequestedApproval] = useState(false);
   const tokenAllowedStatus =useMemo(()=>
          tokenAllowance && !tokenAllowance.isLessThan(
            new BigNumber(data?.stockAmount).times(new BigNumber(10).pow(tokenFrom?.decimals||0))
          ),
   [data?.stockAmount,tokenAllowance,tokenFrom?.decimals]);

   // approve
   const handleApprove = useCallback(async () => {
         try {
             setRequestedApproval(true)
             const txHash = await onTokenApprove()
             setRequestedApproval(false)
             if (txHash) {setApproved(approved + 1);}
         } catch (e) {
             console.error(e)
         }
   }, [onTokenApprove,approved])

 
   const checkButton=()=>{
        if(data?.target?.toLowerCase()!==account?.toLowerCase() && data?.target!==get0xAddress()){
           return <Button fullWidth disabled>{TranslateString(13078,"Untradable")}</Button>
        }
        if(statusStringBtn!==1){
           return <Button fullWidth disabled>{statusStringBtn}</Button>
        }
        if(!account){
            return  <ConnectWalletButton fullWidth />
        }
        // Balance check
        const BalanceIn=relevantTokenBalances[0]?.toExact();
        if(BalanceIn && data?.stockAmount && new BigNumber(BalanceIn).isLessThan(new BigNumber(data?.stockAmount))){
          return <RowBetween>
          <Button fullWidth disabled>
            {TranslateString(10089, 'Insufficient')} {tokenFrom?.symbol} {TranslateString(10090, 'balance')}
          </Button>
        </RowBetween>
        }
  
        // approve
        if(!tokenAllowedStatus){
          return <RowBetween>
          <Button 
            style={{ width: '48%',flex:1 }}  
            disabled={requestedApproval} onClick={handleApprove}>
            {TranslateString(564, 'Approve')} {tokenFrom?.symbol}
          </Button>
        </RowBetween>
        }
      

       return <Button fullWidth onClick={onPresentSettings}>
               {TranslateString(8, 'Swap')}  
        </Button>
              
   }



   const isExpired = parseInt(`${new Date().getTime()/1000}`)>parseInt(data?.expireTime);
   const statusString = data?.filled=== true ? TranslateString(13021,'Filled') : (
    data?.deleted ? TranslateString(13047,'Cancelled') : (
       isExpired ? TranslateString(13051,'Expired') : TranslateString(13050,'Active')
     )
   );
   
   const statusStringBtn = data?.filled=== true ? TranslateString(13021,'Filled') : (
    data?.deleted ? TranslateString(13047,'Cancelled') : (
       isExpired ? TranslateString(13051,'Expired') : 1
     )
   );

   const [copyState,setCopyState]=useState(false);
   const [copyMakerState,setMakerCopyState]=useState(false);
   const copyText = (str:string,type?:string) => {
    const textToCopy = str;
    navigator.clipboard.writeText(textToCopy||"")
      .then(() => {

        if(type==='maker'){
          setMakerCopyState(true);
        }else{
          setCopyState(true);
        }
       
      })
      .catch((error) => {

        if(type==='maker'){
          setMakerCopyState(false);
        }else{
          setCopyState(true);
        }

      });
   } 

   const addressComponent=(address:string|undefined)=>{
    return address===get0xAddress()?
      <StyleLabel title={TranslateString(13067,"Everyone")}>{TranslateString(13067,"Everyone")}</StyleLabel>
      :
      <StyleLinkExternals  title={address} href={`https://smartscout.cash/address/${address}`}>{address?.substring(0, 8)}</StyleLinkExternals>

      // <StyleAddressLabel href={`https://smartscout.cash/address/${address}`} target="_blank"  title={address}>{address?.substring(0, 8)}</StyleAddressLabel>
   }
   return (<>
      <AppBody overflow='unset'>
        <Wrapper id="agg-swap-page">
          <CardBody>
            {
              orderPedding?
              <Flex p={2} flexDirection='column'>
                <Text fontSize='20px'>{TranslateString(13066, 'You pay:')}</Text>
                <Flex mt={2} alignItems='center'>
                  <Flex>
                    <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenFrom?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${tokenFrom?.symbol}`} />
                  </Flex>
                  <Text ml={2} fontSize='20px'>{data?.stockAmount}</Text>
                  <Text ml={2} fontSize='20px'>{tokenFrom?.symbol}</Text>
                </Flex>
                
                <StyleBackGroundPanel mt={3} justifyContent='space-between'>
                   <Text fontSize='14px'>{TranslateString(13065, 'Rate')}</Text>
                   <Flex>
                      {btnStatus?
                      <Text fontSize='14px'>1 {tokenFrom?.symbol} = {getRate(true)} {tokenTo?.symbol}</Text>
                        :
                      <Text fontSize='14px'>1 {tokenTo?.symbol} = {getRate(false)} {tokenFrom?.symbol}</Text>
                      }
                      <Text>
                      <StyleMaxMini onClick={()=>{setBtnStatus(!btnStatus)}}>
                        <SyncAltIcon style={{cursor:'pointer'}} width="20px" color="primary" />
                      </StyleMaxMini>
                      </Text>
                   </Flex>
                </StyleBackGroundPanel>

                <Text mt={3} fontSize='20px'>{TranslateString(13005, 'You receive:')}</Text>
                <Flex mt={2} alignItems='center'>
                  <Flex>
                     <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenTo?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${tokenTo?.symbol}`} />
                  </Flex>
                  <Text ml={2} fontSize='20px'>{data?.moneyAmount}</Text>
                  <Text ml={2} fontSize='20px'>{tokenTo?.symbol}</Text>
                </Flex>

                <StyleBackGroundPanel mt={3} p={3} justifyContent='space-between'>
                   <Text fontSize='14px'>{TranslateString(13016, 'Valid Until')} </Text>
                   <Text fontSize='14px'>{expiryString}</Text>
                </StyleBackGroundPanel>

                <StyleBackGroundPanel mt={2} p={3} flexDirection='column' >
                   <Flex  justifyContent='space-between'>
                         <Text fontSize='14px'>{TranslateString(13070, 'Maker Address')}</Text>
                         <Flex>
                            {addressComponent(data?.operator)}
                            {/* <StyleLinkExternal >
                              <Stylep2pUrl title={data?.operator} fontSize='14px'>{data?.operator}</Stylep2pUrl>
                            </StyleLinkExternal>
                            <Flex alignItems='center'>
                                <StyleIconPanel isCopy={copyMakerState} ml={2} onClick={()=>{copyText(data?.operator,'maker')}}>
                                  {copyMakerState?<CheckCircle/>:<Copy/>}
                                </StyleIconPanel>
                              </Flex> */}
                         </Flex>
                       
                   </Flex>
                   <Flex mt={2} justifyContent='space-between'>
                         <Text fontSize='14px'>{TranslateString(13057, 'Taker Address')}</Text>
                         <Flex>
                            {addressComponent(data.filled?data.taker:data?.target)}
                            {/* <StyleLinkExternal >
                              <Stylep2pUrl title={data?.target===get0xAddress()?TranslateString(13067,'All'):data?.target} fontSize='14px'>{data?.target===get0xAddress()?TranslateString(13067,'All'):data?.target}</Stylep2pUrl>
                            </StyleLinkExternal>
                            <Flex alignItems='center'>
                                <StyleIconPanel isCopy={copyState} ml={2} onClick={()=>{copyText(data?.target)}}>
                                  {copyState?<CheckCircle/>:<Copy/>}
                                </StyleIconPanel>
                              </Flex> */}
                         </Flex>
                       
                   </Flex>
                   <Flex mt={2}  justifyContent='space-between'>
                        <Text fontSize='14px'>{TranslateString(13062, 'Order Status')}</Text>
                        <Text fontSize='14px'>{statusString}</Text>
                   </Flex>
                   
                </StyleBackGroundPanel>



                <Flex mt={4} justifyContent='center'>
                  {/* <Button>Limit Swap</Button> */}
                  {checkButton()}
                </Flex>
                
                
              </Flex>
              :
              <Flex flex={1}  justifyContent='center' alignItems='center'>
                    <SpinnerOuterContainer><SpinnerInnerContainer><Spinner/></SpinnerInnerContainer></SpinnerOuterContainer>
              </Flex>
            }
            


            
{/*            
            const StyleLoadingPanel = styled(Flex)`
   width:200px;
` */}
          </CardBody>
        </Wrapper>
      </AppBody>
    </>)
}

export default P2pSwap


