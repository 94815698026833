import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import BigNumber from 'bignumber.js'
import { ChainId,Currency} from '@pancakeswap-libs/sdk-v2'
import { useWeb3React } from '@web3-react/core'
import {  getChecksumAddress } from '../../utils/addressHelpers'
import Circle from '../../assets/images/blue-loader.svg'
import { Spinner } from '../../components/Shared'
import { p2pExSwap } from '../../utils/p2pex/p2pex'
import { toPrecisionDown } from '../../utils/getPrecision'
import { useFinalizeTransaction, useTransactionAdder } from '../../state/transactions/hooks'
import { useAddPopup } from '../../state/application/hooks'
import {  useP2pListStateHandlers } from '../../state/p2pEx/hooks';
import TransactionSuccessContent from './TransactionSuccessContent'




const ComfirmationWrapper = styled.div`
  margin: -24px;
`

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

interface ConfirmModalProps {
  onDismiss?:()=>void;
  pairAddress:string|null;
  orderId?:string;
  rate:string;
  formAmount:string;
  toAmount:string;
  fromSymbol?:string;
  toSymbol?:string;
  onClearData:()=>void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({formAmount,toAmount,fromSymbol,toSymbol,orderId,onClearData,onDismiss,pairAddress,rate}) => {
  const TranslateString = useI18n();
  const [orderPendding,setOrderPendding]=useState(false);
  const [orderHash,setOrderHash]=useState<string>("");
  const [erroMessage,setErroMessage]=useState<string>("");
  const { account } = useWeb3React();
  const addPopup = useAddPopup();

  const { onRefreshPage } =useP2pListStateHandlers();
  // const doneTransaction = useFinalizeTransaction();
  // const addTransaction = useTransactionAdder();

   // 重置状态
   const reset=(hashStr:string)=>{
    // const base = `Swap ${toPrecisionDown(new BigNumber(formAmount),3)} ${fromSymbol} to ${toPrecisionDown(new BigNumber(toAmount),3)} ${toSymbol}`
    // addTransaction({
    //   ...{},
    //   hash: hashStr,
    //   confirmations: 0,
    //   from: '',
    //   wait: null as any,
    //   nonce: 0,
    //   gasLimit: null as any,
    //   gasPrice: null as any,
    //   data: '',
    //   value: null as any,
    //   chainId: ChainId.MAINNET
    // }, {summary: base})
    setOrderHash(hashStr);
    setOrderPendding(false);
  }
  const handOrder=useCallback(async ()=>{
    setOrderHash("")
    setErroMessage("")
    setOrderPendding(true);

    const ret:any=await p2pExSwap(account,pairAddress,orderId,reset);
    if(ret?.transactionHash){
      onClearData();
      const base = `Swap ${toPrecisionDown(new BigNumber(formAmount),3)} ${fromSymbol} to ${toPrecisionDown(new BigNumber(toAmount),3)} ${toSymbol}`
      //  成功哈希提示
      addPopup( {txn: { hash:ret?.transactionHash||"0xxx",success: true,summary:base}},ret?.transactionHash||"0xxx")
      onRefreshPage(true);
      setTimeout(()=>{onRefreshPage(false);},1000)
      //  交易记录
      // doneTransaction({chainId: ChainId.MAINNET,hash: ret.transactionHash,receipt: {...ret,status:1},summary:base})
    }else{
      setErroMessage(ret);
    }
    setOrderPendding(false);
 },[onRefreshPage,orderId,account,pairAddress,onClearData,addPopup,formAmount,toAmount,fromSymbol,toSymbol]) 

 useEffect(()=>{
    handOrder();
  },[handOrder])
  const showComponent=()=>{
    if(orderHash!==""){
      return <ComfirmationWrapper><TransactionSuccessContent isNoShowClose chainId={ChainId.MAINNET} hash={orderHash} onDismiss={onDismiss as any} /></ComfirmationWrapper>
    }
  
    if(orderPendding&&orderHash===""){
      return  <Flex justifyContent='center' alignItems="center">
               <CustomLightSpinner src={Circle} alt="loader" size="90px" />
              </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }

  return (<>
       <Modal title={TranslateString(13069,'Trade')} onDismiss={onDismiss}>
        {showComponent()}
       </Modal>
  </>
  )
}

export default ConfirmModal
