// Set of helper functions to facilitate wallet setup
import { UnsupportedChainIdError} from '@web3-react/core'
import { injected, bsc, walletconnect, getNodeUrl } from 'connectors'

const NetWorkUrl = getNodeUrl()
/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export  const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    // bch chainId 10000 转 16进制
    const chainId = (10000).toString(16)
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId}`,
            chainName: 'Smart Bitcoin Cash',
            nativeCurrency: {
              name: 'Smart Bitcoin Cash',
              symbol: 'SBCH',
              decimals: 18,
            },
            rpcUrls: [NetWorkUrl],
            blockExplorerUrls: [`https://smartscout.cash/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}
export  const connWallt = async (connectorId:string,activate:any) => {
  if (connectorId === 'walletconnect') {
    return activate(walletconnect)
  }
  
  if (connectorId === 'bsc') {
    return activate(bsc)
  }
  return activate(injected, async (error: Error) => {
    if (error instanceof UnsupportedChainIdError) {
      const hasSetup = await setupNetwork()
      if (hasSetup) {activate(injected)}
     }
    }
  )
}

export default connWallt;