import BigNumber_ from 'bignumber.js';
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { ethers } from "ethers"
import Web3 from 'web3'
import { getNodeUrl } from 'connectors/index';
import LIMIEX_ABI from '../../constants/abis/LimitEx.json'
import { getvalidOrders } from "./LimitEX_util"
import { UNIT } from "./price"

function getWeb3() {
    return new Web3(Number(window.web3?.currentProvider?.chainId) === ChainId.MAINNET && window.web3.currentProvider || new Web3.providers.HttpProvider(getNodeUrl()));
}

async function queryOrders(fromToken, destToken) {
    const pairs = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-limitex", {
        // eslint-disable-next-line
        "body": `{\"query\":\"{\\n  pairs(where:{stock:\\\"${destToken}\\\",money:\\\"${fromToken}\\\"}) {\\n    id\\n    impl\\n    stock\\n    money\\n  }\\n}\\n\",\"variables\":null,\"operationName\":null}`,
        "method": "POST",
    }).then(res => res.json()).then(res => res.data.pairs)
    if (pairs.length === 0) {
        return []
    }
    const orders = await fetch("https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-limitex", {
        // eslint-disable-next-line
        "body": `{\"query\":\"{\\n  orders(orderDirection: asc, orderBy: price, where: {pair: \\\"${pairs[0].id}\\\",deleted: false, expireTime_gt:${Math.ceil(new Date().getTime() / 1000)}, price_gt:\\\"${0}\\\"}) {\\n    maxAllowance\\n    remainingAllowance\\n    usedAllowance\\n    operator\\n    id\\n    price\\n    expireTime\\n    deleted\\n    pair {\\n      id\\n    }\\n  }\\n}\\n\",\"variables\":null,\"operationName\":null}`,
        "method": "POST",
    }).then(res => res.json()).then(res => res.data.orders)
    return orders
}

export default async function getLimitExResult(fromToken, destToken, fromTokenDecimals, destTokenDecimals, amountIn) {
    const orders = await queryOrders(fromToken, destToken)
    if (orders.length === 0) {
        return {
            pairAddr: ethers.constants.AddressZero,
            amountOut: 0,
            orderIdList: [],
            availableAllownances: [],
        }
    }
    let priceMul: any = 1;
    let priceDiv: any = 1;
    if (destTokenDecimals >= fromTokenDecimals) { // from = money  to=stock
        priceDiv = (10 ** (destTokenDecimals - fromTokenDecimals)).toString();
    } else {
        priceMul = (10 ** (fromTokenDecimals - destTokenDecimals)).toString();
    }
    const { validOrderIds, availableAllownances } = await getvalidOrders(orders[0].pair.id, orders.map(v => v.id.slice(42)))
    if (validOrderIds.length === 0) {
        return null
    }
    let amountOut = ethers.constants.Zero
    const newOrderIdList: string[] = []
    const newAvailableAllownances: string[] = []
    let reamingAmount = ethers.BigNumber.from(amountIn)
    const dealOrders: Array<{ price: string, tokenAmount: string, tokenARatio: string }> = []
    for (let index = 0; index < validOrderIds.length; index++) {
        const orderId = validOrderIds[index]
        const order = orders.find(x => x.id.includes(orderId))!
        newOrderIdList.push(orderId)
        newAvailableAllownances.push(availableAllownances[index].toString())
        let amountToTaker = availableAllownances[index]
        let amountToMaker = ethers.BigNumber.from(order.price).mul(priceMul).mul(amountToTaker).div(UNIT.mul(priceDiv)).toString()
        const price = new BigNumber_(order.price).div(UNIT.toString()).toString()
        if (reamingAmount.gte(amountToMaker)) {
            amountOut = amountOut.add(amountToTaker)
            reamingAmount = reamingAmount.sub(amountToMaker)
            dealOrders.push({
                price,
                tokenAmount: amountToMaker.toString(),
                tokenARatio: ''
            })
        } else {
            amountToTaker = reamingAmount.mul(UNIT.mul(priceDiv)).div(ethers.BigNumber.from(order.price).mul(priceMul))
            amountToMaker = ethers.BigNumber.from(order.price).mul(priceMul).mul(amountToTaker).div(UNIT.mul(priceDiv)).toString()
            amountOut = amountOut.add(amountToTaker)
            reamingAmount = ethers.constants.Zero
            dealOrders.push({
                price,
                tokenAmount: amountToMaker.toString(),
                tokenARatio: ''
            })
            break;
        }
        if (reamingAmount.isZero()) {
            break;
        }
    }
    amountIn = ethers.BigNumber.from(amountIn).sub(reamingAmount).toString()
    dealOrders.forEach(v => {
        v.tokenARatio = new BigNumber_(v.tokenAmount).div(amountIn).toString()
    })

    return {
        dealOrders,
        pairAddr: orders[0].pair.id,
        amountOut: amountOut.toString(),
        orderIdList: newOrderIdList,
        availableAllownances: newAvailableAllownances,
        amountIn
    }
}

// getLimitExResult("0x0000000000000000000000000000000000002711", "0x77cb87b57f54667978eb1b199b28a0db8c8e1c0b", 18, 18, "920000000000000")
//     .then(x => console.log(11, x)).catch(console.error)
// .then(({ pairAddr, amountOut, orderIdList, availableAllownances, reamingAmount }) => {
// limitExSwap("0xf29c9eF6496A482b94BDB45ABA93d661F082922C", pairAddr, amountOut, orderIdList, availableAllownances)
// })


export async function limitExSwap(account, pairAddr, amountOut, orderIdList, availableAllownances, allowPartDeal: boolean, sureFuc: (hash: string) => void) {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(LIMIEX_ABI as any, pairAddr)
    console.log(pairAddr, allowPartDeal ? 0 : amountOut, 0, orderIdList, availableAllownances, amountOut)
    const tx_ = await contract.methods.dealWithOrders(allowPartDeal ? 0 : amountOut, 0, orderIdList, availableAllownances, amountOut).send({
        gas: '',
        from: account
    }).on('transactionHash', (tx) => {
        sureFuc(tx)
    })
    const { transactionHash, events: { DealWithOrder: { returnValues: { amountToMaker, amountToTaker } } } } = tx_
    const hash = transactionHash;
    return { tokenAmount: amountToMaker, tokenBmount: amountToTaker, transactionHash: hash }
}


// (window as any).limitExSwap = limitExSwap