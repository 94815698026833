import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useWeb3 from 'hooks/useWeb3'
import { useActiveWeb3React } from '../../hooks'
import { useAddPopup, useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'



export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number }
): boolean {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  }
  // otherwise every block
  return true
}

export default function Updater(): null {
  const { chainId, library } = useActiveWeb3React()
  const web3 = useWeb3();
  const lastBlockNumber = useBlockNumber()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transactions = chainId ? state[chainId] ?? {} : {}

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return

    Object.keys(transactions)
      .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach((hash) => {
        library
          .getTransactionReceipt(hash)
          .then(async (receipt) => {

            // // 指定合约地址和 Swap 事件的 ABI
            // const swapEventAbi = {
            //   "anonymous": false,
            //   "inputs": [
            //     {
            //       "indexed": true,
            //       "name": "sender",
            //       "type": "address"
            //     },
            //     {
            //       "indexed": false,
            //       "name": "amount0In",
            //       "type": "uint256"
            //     },
            //     {
            //       "indexed": false,
            //       "name": "amount1In",
            //       "type": "uint256"
            //     },
            //     {
            //       "indexed": false,
            //       "name": "amount0Out",
            //       "type": "uint256"
            //     },
            //     {
            //       "indexed": false,
            //       "name": "amount1Out",
            //       "type": "uint256"
            //     },
            //     {
            //       "indexed": true,
            //       "name": "to",
            //       "type": "address"
            //     }
            //   ],
            //   "name": "Swap",
            //   "type": "event"
            // };
            // // 查找所有 Swap 事件
            // const swapEvents:any = receipt.logs.find(log => log.topics[0] === web3.utils.sha3("Swap(address,uint256,uint256,uint256,uint256,address)"));
            // // 解码所有 Swap 事件
            // const decodedEvent = web3.eth.abi.decodeLog(
            //   swapEventAbi.inputs,
            //   swapEvents.data,
            //   swapEvents.topics.slice(1)
            // );
            // const decodedSwapEvents= {
            //   ...swapEvents,
            //   sender: decodedEvent.sender,
            //   amount0In: decodedEvent.amount0In,
            //   amount1In: decodedEvent.amount1In,
            //   amount0Out: decodedEvent.amount0Out,
            //   amount1Out: decodedEvent.amount1Out,
            //   to: decodedEvent.to
            // };
            // console.log(decodedSwapEvents);

            // if(decodedSwapEvents){
            //   dispatch(
            //     finalizeTransaction({
            //       chainId,
            //       hash,
            //       receipt: {
            //         blockHash: receipt.blockHash,
            //         blockNumber: receipt.blockNumber,
            //         contractAddress: receipt.contractAddress,
            //         from: receipt.from,
            //         status: receipt.status,
            //         to: receipt.to,
            //         transactionHash: receipt.transactionHash,
            //         transactionIndex: receipt.transactionIndex,
            //       },
            //       summary:""
            //     })
            //   )

            //   addPopup(
            //     {
            //       txn: {
            //         hash,
            //         success: receipt.status === 1,
            //         summary: "",
            //       },
            //     },
            //     hash
            //   )
            // }

            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                })
              )

              addPopup(
                {
                  txn: {
                    hash,
                    success: receipt.status === 1,
                    summary: transactions[hash]?.summary,
                  },
                },
                hash
              )
            } else {
              dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }))
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [chainId, library, transactions, lastBlockNumber, dispatch, addPopup, web3.eth.abi,web3.utils])

  return null
}
