import { AbiItem } from 'web3-utils'
import { Interface } from '@ethersproject/abi'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import Web3 from 'web3'
import { getNodeUrl } from '../connectors/index';
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'

const multicallAddress = MULTICALL_NETWORKS[ChainId.MAINNET]
const NetWorkUrl = getNodeUrl()
// testNet
// const multicallAddress = '0xd512aB1c3650dDb220C0E6Fa970Ff3AC640eFb7f'
// const NetWorkUrl = "https://moeing.tech:9545"

interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (exemple: balanceOf)
  params?: any[] // Function params
}

const multicall = async (abi: any[], calls: Call[]) => {
  const web3 = new Web3(new Web3.providers.HttpProvider(NetWorkUrl));
  const contract = new web3.eth.Contract(MULTICALL_ABI as any, multicallAddress)
  const itf = new Interface(abi)
  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const { returnData } = await contract.methods.aggregate(calldata).call()
  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}

export default multicall
