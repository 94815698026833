import React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
    width: 100%;
    max-width: 250px;
    padding-bottom: 12%;
    position: relative;
`

const Image = styled.span`
    display: inline-block;
    position: absolute;
    width: 40%;
    height: 100%;
    left: 30%;
`

const E1 = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`
const E2 = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(150%, 0);
    }
`
const E3 = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`

const Ball = styled.div`
    position: absolute;
    width: 20%;
    top: 5%;
    height: 66.7%;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.text};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
        left: 10%;
        animation: ${E1} 0.6s infinite;
    }
    &:nth-child(2) {
        left: 10%;
        animation: ${E2} 0.6s infinite;
    }
    &:nth-child(3) {
        left: 40%;
        animation: ${E2} 0.6s infinite;
    }
    &:nth-child(4) {
        left: 70%;
        animation: ${E3} 0.6s infinite;
    }
`

const Loading= () => {
    return (
        <Container>
            <Image>
                <Ball/><Ball/><Ball/><Ball/>
            </Image>
        </Container>
    );
}

export default Loading
