import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useWeb3 from "hooks/useWeb3";
import { getP2PExResult } from "utils/p2pex/p2pex";
import multicall from "utils/multicall";
import { UNIT } from "utils/limitex/price";
import { ethers } from "ethers";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLimitListState } from "state/limit/hooks";
import { useERC20, useLimitExContract, useP2pEx } from "../../../hooks/useContractV2";
import ERC20_ABI from '../../../constants/abis/erc20.json';
import { useP2pListState } from "../../../state/p2pEx/hooks";
import { get0xAddress } from "../../../utils/addressHelpers";


const useGetOrderData = (activeTab:number,pageSize:number,defaultPageSize:number): { data: any,isMoreStatus:boolean, orderPedding: boolean,readMorePedding:boolean } => {
    const web3 = useWeb3();
    const { account } = useWeb3React();
    const [isMoreStatus, setIsReadMore] = useState<boolean>(false);
    const [readMorePedding, setReadMorePedding] = useState<boolean>(true);
    const [orderPedding, setOrderPedding] = useState<boolean>(true);

    const [data, setData] = useState<Array<any>>([]);
    const { refresh } = useP2pListState();
    const filter=useRef<string>("");

    useEffect(() => {
        if (refresh === true || account) {
            setOrderPedding(false);
            filter.current="";
            switch(activeTab){
                case 1:
                    filter.current=`{operator:"${account}",expireTime_gte: "${parseInt(`${new Date().getTime()/1000}`)}",deleted:false,filled:false}`
                break;   
                case 2:
                    filter.current=`{target:"${account}",expireTime_gte: "${parseInt(`${new Date().getTime()/1000}`)}",deleted:false,filled:false}`
                break;   
                // case 2:
                //     filter.current=`{operator:"${account}",deleted:true}`
                // break;       
                case 3:
                    filter.current=`{taker:"${account}",filled:true}`
                break;       
                case 4:
                    filter.current=`{operator:"${account}"}`
                break;
                case 5:
                    filter.current=`{target:"${get0xAddress()}",expireTime_gte: "${parseInt(`${new Date().getTime()/1000}`)}",deleted:false,filled:false}`
                break; 
            }

            fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-p2pex`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "query": `{orders(first: ${defaultPageSize+1},orderDirection: desc,orderBy: createBlockNumber,
                    where:${filter.current}
                    ){\n    target,\n orderId,  taker,  amount,\n    filled,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n,stock,money     }\n   }\n}`,

                    // ){\n    maxAllowance,\n    remainingAllowance,\n    usedAllowance,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n, stock,money    }\n  }\n}`,
                    "variables": null,
                    "operationName": null
                })
            })
                .then(res => res.json())
                .then(async (r: any) => {
                    const orders = r?.data?.orders || []
                    const tokens = Array.from(new Set([].concat(...orders.map(order => [order.pair.stock, order.pair.money]))))
                    const decimals = (await multicall(ERC20_ABI, tokens.map((v) => ({
                        address: v,
                        name: 'decimals',
                    })))).map((v) => parseInt(v))

                    for (let index = 0; index < orders.length; index++) {
                        const order = orders[index];
                        const fromTokenDecimals = decimals[tokens.findIndex(x => x === order.pair.stock)]
                        const destTokenDecimals = decimals[tokens.findIndex(x => x === order.pair.money)]

                        let priceMul: any = 1;
                        let priceDiv: any = 1;
                        if (destTokenDecimals >= fromTokenDecimals) {
                            priceMul = (10 ** (destTokenDecimals - fromTokenDecimals)).toString();
                        } else {
                            priceDiv = (10 ** (fromTokenDecimals - destTokenDecimals)).toString();
                        }
                        const reutrnAmount = ethers.BigNumber.from(order.price).mul(priceMul).mul(order.amount).div(UNIT.mul(priceDiv)).toString()
                 
                        // order.stockAmount=ethers.BigNumber.from(order.amount).div(ethers.BigNumber.from(10).pow(ethers.BigNumber.from(fromTokenDecimals))).toString();
                        order.stockAmount=new BigNumber(order.amount).div(new BigNumber(10).pow(fromTokenDecimals)).toString();
                        order.moneyAmount =new BigNumber(reutrnAmount).div(new BigNumber(10).pow(destTokenDecimals)).toString() ;
                    }
                    // console.log("orders", orders);
                    // console.log("111111111",getP2PExResult("0x0031b97dc1cb93f70db674b4d005b10c2caad6450xccb22ec0300b9c8fd30de563e5b0af9cd31764b40dbc16d674ec8000647d908b",2,18))
                    setIsReadMore(orders.length>defaultPageSize);
                    if(orders.length>defaultPageSize){
                        orders.pop();
                    }
                    setData(orders);
                    setOrderPedding(true);
                })
        }
    }, [account,web3,refresh,activeTab,defaultPageSize])



    useEffect(() => {
        if (pageSize>0 && filter.current!=="") {
            setReadMorePedding(false);
            fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-p2pex`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "query": `{orders(skip: ${pageSize},first: ${defaultPageSize+1},orderDirection: desc,orderBy: createBlockNumber,
                    where:${filter.current}
                    ){\n    target,\n orderId,    amount,\n    filled,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n,stock,money     }\n   }\n}`,
                    "variables": null,
                    "operationName": null
                })
               })
                .then(res => res.json())
                .then(async (r: any) => {
                    const orders = r?.data?.orders || []
                    const tokens = Array.from(new Set([].concat(...orders.map(order => [order.pair.stock, order.pair.money]))))
                    const decimals = (await multicall(ERC20_ABI, tokens.map((v) => ({
                        address: v,
                        name: 'decimals',
                    })))).map((v) => parseInt(v))

                    for (let index = 0; index < orders.length; index++) {
                        const order = orders[index];
                        const fromTokenDecimals = decimals[tokens.findIndex(x => x === order.pair.stock)]
                        const destTokenDecimals = decimals[tokens.findIndex(x => x === order.pair.money)]

                        let priceMul: any = 1;
                        let priceDiv: any = 1;
                        if (destTokenDecimals >= fromTokenDecimals) {
                            priceMul = (10 ** (destTokenDecimals - fromTokenDecimals)).toString();
                        } else {
                            priceDiv = (10 ** (fromTokenDecimals - destTokenDecimals)).toString();
                        }
                        const reutrnAmount = ethers.BigNumber.from(order.price).mul(priceMul).mul(order.amount).div(UNIT.mul(priceDiv)).toString()
                 
                        // order.stockAmount=ethers.BigNumber.from(order.amount).div(ethers.BigNumber.from(10).pow(ethers.BigNumber.from(fromTokenDecimals))).toString();
                        order.stockAmount=new BigNumber(order.amount).div(new BigNumber(10).pow(fromTokenDecimals)).toString();
                        order.moneyAmount =new BigNumber(reutrnAmount).div(new BigNumber(10).pow(destTokenDecimals)).toString() ;
                    }
                    // console.log("orders", orders);

                    setIsReadMore(orders.length>defaultPageSize);
                    if(orders.length>defaultPageSize){
                        orders.pop();
                    }
                   
                    setData(pre=>[...pre,...orders]);
                    setReadMorePedding(true);
               })
        }
    }, [pageSize,defaultPageSize])
    return { data, orderPedding,readMorePedding,isMoreStatus }
}



export const useP2pExOrderFuc = (pairAddress: string | null) => {
    const p2pExContract = useP2pEx(pairAddress || "");
    const { account } = useWeb3React();


    // delOrder order
    const delOrder = useCallback(async (orderId: string, setResHash: any) => {
        try {
            const txHash = await p2pExContract.methods.deleteOrder(orderId)
                .send({ ...{}, from: account })
                .on('transactionHash', (tx) => {
                    setResHash(tx)
                })
            return { status: true, res: txHash };
        } catch (error: any) {
            console.log(error);
            return { status: false, res: error.message ? error.message : error.toString() };
        }
    }, [p2pExContract, account])

    // updateOrder order
    const updateOrder = useCallback(async (orderId: string, amount: string, setResHash: any) => {
        try {
            const txHash = await p2pExContract.methods.changeMaxAllowanceOfOrder(orderId, amount)
                .send({ ...{}, from: account })
                .on('transactionHash', (tx) => {
                    setResHash(tx)
                })
            return { status: true, res: txHash };
        } catch (error: any) {
            console.log(error);
            return { status: false, res: error.message ? error.message : error.toString() };
        }

    }, [p2pExContract, account])

    return { delOrder, updateOrder };
}
export default useGetOrderData