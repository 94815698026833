import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'

export const BodyWrapper = styled(Card)<{overflow?:string}>`
  position: relative;
  max-width: 436px;
  width: 100%;
  z-index: 5;
  overflow: ${(props) => (props.overflow?props.overflow:'hidden')};
`


/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children,overflow }: { children: React.ReactNode,overflow?:string  }) {
  return <BodyWrapper overflow={overflow}>{children}</BodyWrapper>
}
