import { ChainId, Currency, CurrencyAmount, JSBI, Token, Trade } from '@pancakeswap-libs/sdk-v2'
import { ArrowDownIcon, Button, CardBody, Flex, IconButton,Text, useModal } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import CardNav from 'components/CardNav'
import { AutoColumn } from 'components/Column'
import ConnectWalletButton from 'components/ConnectWalletButton'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow, RowBetween } from 'components/Row'
import { ArrowWrapper, BottomGrouping } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import AppBody from 'pages/AppBody'
import { useLimitListState } from 'state/limit/hooks'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { useCurrencyBalance, useCurrencyBalances } from 'state/wallet/hooks'
import styled, { ThemeContext } from 'styled-components'
import {maxAmountSpend} from 'utils/maxAmountSpend'
import { TranslateString } from 'utils/translateTextHelpers'
import { useSmartSwapState } from 'state/smartSwap/hooks'
import { useTokenApprove } from 'hooks/useApprove'
import { getAvggerAddress, getBchAddress, getLimitFactoryAdress } from 'utils/addressHelpers'
import { useAllowance } from 'hooks/useAllowance'
import {useERC20} from 'hooks/useContractV2'
import { useAddPopup } from 'state/application/hooks'
import { useFinalizeTransaction, useTransactionAdder } from 'state/transactions/hooks'
import toPrecision, { toPrecisionDown } from 'utils/getPrecision'
import {Subscription} from 'web3-core-subscriptions';
import {BlockHeader} from 'web3-eth';
import {  TYPE } from 'components/Shared'
import Card from 'components/Card'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import LimitCardNav from 'components/LimitCardNav'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import ConfirmModal from './ConfirmModals'
import useCreateSmartSwap from './hooks/useLimitSwap'


import RouterSwap from './RouterSwap'
import SwapHeader from './xSwapHeaderx'
import TradePrice from './TradePrice'
import HelpInfo from './HelpInfo'
import useWeb3 from '../../hooks/useWeb3'
import { useDefaultsFromURLSearch } from '../../state/swap/hooks'
import TokenWarningModal from '../../components/TokenWarningModal'












const  StyleAppBody= styled(AppBody)`
  margin-top: 1.25rem;
`
export const Wrapper = styled.div`
  position: relative;
`
export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const InfoGrouping = styled(BottomGrouping)`
  padding: 0 .75rem;
`

const LimitSwap = () => {
    const loadedUrlParams = useDefaultsFromURLSearch();
    const { main: Main } = TYPE
    const BCHAddress=getBchAddress();
    const getExpTime=500;
    const [isExpertMode] = useExpertModeManager()
    const { account } = useActiveWeb3React()

    const { dexs }=useSmartSwapState()
    const [amountInput,setAmountInput]=useState("")
    const [amountOutput,setAmountOutput]=useState("")

    const [loadedInputCurrency, loadedOutputCurrency] = [
       useCurrency(loadedUrlParams?.inputCurrencyId),
       useCurrency(loadedUrlParams?.outputCurrencyId),
    ]
    const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
    const urlLoadedTokens: Token[] = useMemo(
      () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
      [loadedInputCurrency, loadedOutputCurrency]
    )
    const handleConfirmTokenWarning = useCallback(() => {
      setDismissTokenWarning(true)
    }, [])

    const [currentInput,setCurrentInput]=useState(useCurrency('ETH'))
    const [currentOutPut,setCurrentOutPut]=useState(useCurrency(''))
    // 根据parameter init  select token
    useEffect(()=>{
      if((loadedInputCurrency as Token) instanceof Token){
         setCurrentInput(loadedInputCurrency)
      }
    },[loadedInputCurrency])
    useEffect(()=>{
      if((loadedOutputCurrency as Token) instanceof Token){
        setCurrentOutPut(loadedOutputCurrency)
      }
    },[loadedOutputCurrency])

    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currentInput ?? undefined)
    const maxAmountInput: CurrencyAmount | any = maxAmountSpend(selectedCurrencyBalance)
    const {getExpectedReturn,swap,subBlock}=useCreateSmartSwap();
    const [distribution,setDistribution]=useState([]);
    const atMaxAmountInput = Boolean(maxAmountInput && amountInput===maxAmountInput.toFixed())
    // 根据新区块产生 刷新界面
    const web3 = useWeb3();
    const refTimer = useRef<any>();
    const [block,setBlock]=useState('0');
    const [sub,setSub]=useState<Subscription<BlockHeader>>();
    // warp an unWarp
    const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
          currentInput as any,currentOutPut as any, amountInput
    )
    const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;

    const refPage=useCallback(async ()=>{
      if(amountInput){
        const OutAmount=await calculateInput(amountInput,currentInput,currentOutPut)
        setAmountOutput(OutAmount)
      }
    },
    // eslint-disable-next-line
    [amountInput,currentInput,currentOutPut])
   
    // 订阅回调
    const resFunc=(transaction)=>{
          if (refTimer.current) {
              clearTimeout(refTimer.current);
          }
          refTimer.current = setTimeout(() => {
            setBlock(`${transaction.number}`)
          }, 6000);
    } 

    // 订阅刷新  
    useEffect(()=>{
      if(block!=='0'){refPage()}
    },
    // eslint-disable-next-line
    [block])

    // 订阅新区块
    useEffect(()=>{
      if(!sub){
        // resFunc
        const subscription=web3.eth.subscribe('newBlockHeaders').on("data",resFunc);
        setSub(subscription)
      }
    },
    // eslint-disable-next-line
    [sub,web3.eth])

    // 组件销毁取消订阅
    useEffect(()=>{
     return ()=>{
             if(!sub){return }
             sub.unsubscribe((error, success)=>{
               if (success) {
                 console.log('close newBlockHeaders')
               }
            })
        }
    },
    // eslint-disable-next-line
    [])


   
    const big=(str:string | number)=>{
      return new BigNumber(str)
    }
    // 检测address 和 amount
    const checkAddress=(form:Currency|null|undefined,to:Currency|null|undefined,amount:string)=>{
      const formAddress=form?(form as any).address:undefined;
      const toAddress=to?(to as any).address:undefined;
      return formAddress && toAddress && big(amount).gt(0) && (formAddress!==toAddress)
    }

    // 输入控件
    const { current }  = useRef({ timer1:[],timer2:[]});

     // token Switch
    const onSwitchTokens=async()=>{
      setAmountInput(amountOutput)
      setAmountOutput("")
      setCurrentInput(currentOutPut)
      setCurrentOutPut(currentInput)
      const InAmount=await calculateInput(amountOutput,currentOutPut,currentInput)
      setAmountOutput(InAmount)
    }
    const handUserInput=async (value:string)=>{
          setAmountInput(value);
          // 防抖查询
          if(currentInput){
            clearTimeout(current.timer1[0]);
            current.timer1[0]=(setTimeout(async()=>{
              const OutAmount=await calculateInput(value,currentInput,currentOutPut)
              setAmountOutput(OutAmount)
            },getExpTime) as never)
          }
          setAmountOutput("")
    }
    const handMaxInput=async ()=>{
          console.log(maxAmountInput.toExact())
          setAmountInput(maxAmountInput.toExact())
          const OutAmount=await calculateInput(maxAmountInput.toExact(),currentInput,currentOutPut)
          setAmountOutput(OutAmount);
    }
    const handSelectInput=async (selectCurrency)=>{
          if(selectCurrency.symbol===currentOutPut?.symbol){
             onSwitchTokens();
          }else{
            setCurrentInput(selectCurrency)
            const OutAmount=await calculateInput(amountInput,selectCurrency,currentOutPut)
            setAmountOutput(OutAmount)
          }
    }

    const [expectedData,setExpectedData]=useState<{
      pairAddr:string,
      amountOut:string,
      orderIdList: Array<any>,
      availableAllownances: any,
      amountIn: string,
      tranAmountOut:string,
      dealOrders:Array<any>
    }>()
    const expectedDataRef=useRef<any>();
    // 查询预期兑换  
    const calculateInput=async (formValue:string,form:Currency|null|undefined,to:Currency|null|undefined,isReverse?:boolean)=>{
      if((form?.symbol==='SBCH' && to?.symbol==='WBCH')
        ||(form?.symbol==='WBCH' && to?.symbol==='SBCH')
        ){
        return formValue;
      }
      let formObj=form;
      let toObj=to;
      if(form?.symbol==='SBCH'){
        const addr={address:BCHAddress}
        formObj={...form,...addr}
      }
      if(to?.symbol==='SBCH'){
        const addr={address:BCHAddress}
        toObj={...to,...addr}
      }
      if(checkAddress(formObj,toObj,formValue)){  
        const formDecimals=formObj?.decimals || 0;
        const toDecimals=toObj?.decimals || 0;
        const formAddress=(formObj as any).address;
        const toAddress=(toObj as any).address;
  
        const amount=(big(formValue).times(big(10).pow(formDecimals))).toFixed(0); 
        // 禁止交易所 4个交易对
        // const disdex=(dexs.map(t=>[t,t,t,t,t])).flat();
        const res= await getExpectedReturn(formAddress,toAddress,amount,formDecimals,toDecimals);
        if(res){
          setExpectedData(res);
          expectedDataRef.current=res;
        }
        return res.tranAmountOut;
      }
      return "";
    }

    // 输出控件 
    const handUserOutput=async (value:string)=>{
          setAmountOutput(value)
          // 防抖查询
          if(currentOutPut){
            clearTimeout(current.timer2[0]);
            current.timer2[0]=(setTimeout(async()=>{
              const OutAmount=await calculateInput(value,currentOutPut,currentInput,true)
              setAmountInput(OutAmount);
            },getExpTime) as never)
          }else{
              setAmountInput('');
          }
    }
    const handSelectOutput=async (selectCurrency)=>{
            if(selectCurrency.symbol===currentInput?.symbol){
               onSwitchTokens();
            }else{
              setCurrentOutPut(selectCurrency)
              const OutAmount=await calculateInput(amountInput,currentInput,selectCurrency)
              setAmountOutput(OutAmount)
            }
    }

    // 交易提示modal
    const [isOpen,setIsOpen]=useState(false)
    const [isPendding,setIsPendding]=useState(false)
    const [swapErrorMessage,setSwapErrorMessage]=useState<string>('')
    const [xhash,setxHash]=useState("");
    
  
    // 授权
    const [approved, setApproved] = useState(0)
    const tokenContract=useERC20(currentInput?.symbol==='SBCH'?BCHAddress:(currentInput as any)?.address);
    const tokenAllowance = useAllowance(tokenContract, getLimitFactoryAdress(),approved);
    const onTokenApprove = useTokenApprove(tokenContract, getLimitFactoryAdress());
    const [requestedApproval, setRequestedApproval] = useState(false);
    // const tokenAllowedStatus = tokenAllowance && !tokenAllowance.isLessThan(new BigNumber(10).pow(77));
    const tokenAllowedStatus =useMemo(()=>
    tokenAllowance && !tokenAllowance.isLessThan(
     new BigNumber(amountInput).times(new BigNumber(10).pow(currentInput?.decimals||0))
    ),
    [amountInput,tokenAllowance,currentInput?.decimals]) ;

    
    // 授权
    const handleApprove = useCallback(async () => {
      try {
          setRequestedApproval(true)
          const txHash = await onTokenApprove()
          setRequestedApproval(false)
          if (txHash) {setApproved(approved + 1);}
      } catch (e) {
          console.error(e)
      }
    }, [onTokenApprove,approved])

    // 判断btn
    const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
      currentInput ?? undefined,
      currentOutPut ?? undefined,
    ])
    const transactionType=():string=>{
      if(wrapType === WrapType.WRAP){
        return  'Wrap'
      }
      if(wrapType === WrapType.UNWRAP){
        return "Unwrap"
      }
      return "Swap";
    }

    // clear ExpectedData;
    useEffect(()=>{
      setExpectedData(undefined);
      expectedDataRef.current=undefined;
    },[currentInput,currentOutPut]);

    useEffect(()=>{
        setTimeout(()=>{
          if(currentInput && expectedDataRef.current?.amountIn &&  expectedDataRef.current?.amountIn!=='0'){
            const amountInputVal=big(amountInput).times(big(10).pow(currentInput.decimals)).toFixed(0)
            if(expectedDataRef.current?.amountIn!==amountInputVal){
              setAmountInput(big(expectedDataRef.current.amountIn).div(big(10).pow(currentInput.decimals)).toFixed())
            }
          }
        },500)
    },
    // eslint-disable-next-line
    [expectedData,currentInput]);

   
    
    const showButton=()=>{
      if(!account){
         return  <ConnectWalletButton fullWidth />
      }
      if(amountInput==='' || big(amountInput).lte(0)){
        return<Button fullWidth disabled>
          {TranslateString(10026, 'Enter an amount')}
        </Button>
      }
      if(!currentInput?.name || !currentOutPut?.name){
        return  <Button fullWidth disabled>{TranslateString(10077, 'Select a Token')}</Button>
      }
      // // 数额太大
      // const amountInputVal=big(amountInput).times(big(10).pow(currentInput.decimals)).toFixed(0)
      // if(expectedData?.amountIn && expectedData?.amountIn!==amountInputVal){
      //    setAmountInput(big(expectedData.amountIn).div(big(10).pow(currentInput.decimals)).toFixed())
      // }
      // 余额不足
      const BalanceIn=relevantTokenBalances[0]?.toExact();
      if(BalanceIn && amountInput && big(BalanceIn).isLessThan(big(amountInput))){
        return <RowBetween>
        <Button fullWidth disabled>
        {TranslateString(10089, 'Insufficient')} {currentInput?.symbol} {TranslateString(10090, 'balance')}
        </Button>
       </RowBetween>
      }
      
      // SBCH-WBCH
      if(showWrap){
        return <Button onClick={onWrap} id="agg-swap-button" fullWidth >
          {wrapInputError ?? (wrapType === WrapType.WRAP ? TranslateString(11043, 'Wrap') : wrapType === WrapType.UNWRAP  ? TranslateString(11044, 'Unwrap') : null)}
        </Button>
      }
      
      // 流动性不足
      if((amountInput && !amountOutput) || parseInt(expectedData?.amountOut||'0')===0){
        return<GreyCard  style={{ textAlign: 'center' }}>
        <Main mb="4px">{TranslateString(10091,'Insufficient liquidity for this trade.')}</Main>
       </GreyCard>
     
      }
      

      // 授权
      if(!tokenAllowedStatus){
        return <RowBetween>
        <Button 
          style={{ width: '48%',flex:1 }}  
          disabled={requestedApproval} onClick={handleApprove}>
          {TranslateString(564, 'Approve')} {currentInput?.symbol}
        </Button>
       </RowBetween>
      }
      

      
  

      return <Button onClick={()=>{setIsOpen(true)}} id="agg-swap-button" fullWidth >
               {TranslateString(8, 'Swap')}
             </Button>
    }
    // 兑换回调
    const addTransaction = useTransactionAdder()
    const [deadline, setDeadline] = useUserDeadline()
    const addPopup = useAddPopup();
    // 重置状态
    const reset=(hashStr:string)=>{
      const base = `${transactionType()} ${toPrecisionDown(new BigNumber(amountInput||''),3)} ${currentInput?.symbol} ${showWrap?'for':'to'} ${toPrecisionDown(new BigNumber(amountOutput||''),3)} ${currentOutPut?.symbol}`
      addTransaction({
        ...{},
        hash: hashStr,
        confirmations: 0,
        from: '',
        wait: null as any,
        nonce: 0,
        gasLimit: null as any,
        gasPrice: null as any,
        data: '',
        value: null as any,
        chainId: ChainId.MAINNET
      }, {summary: base})
      setxHash(hashStr);
      setIsPendding(false);
    }
    const { allowPartDeal }=useLimitListState();
    const doneTransaction = useFinalizeTransaction();
    
    const handerConfirm=async()=>{
          // 交易等待
          setIsPendding(true)
          let formAddress=(currentInput as any).address;
          let toAddress=(currentOutPut as any).address;

          // BCH 地址替换
          if(currentInput?.symbol==='SBCH'){
            formAddress=BCHAddress
          }
          if(currentOutPut?.symbol==='SBCH'){
            toAddress=BCHAddress
          }
          // 交易兑换
          const [res,resInfo]=await swap(expectedData as any,reset,allowPartDeal);
          
          if(res){
            const a_amount=big(resInfo.tokenAmount).div(big(10).pow(currentInput?.decimals||0));
            const b_amount=big(resInfo.tokenBmount).div(big(10).pow(currentOutPut?.decimals||0));

            const base = `${transactionType()} ${toPrecisionDown(a_amount,3)} ${currentInput?.symbol} ${showWrap?'for':'to'} ${toPrecisionDown(b_amount,3)} ${currentOutPut?.symbol}`
            //  成功哈希提示
            addPopup( {txn: { hash:resInfo.transactionHash,success: true,summary:base}},resInfo.transactionHash)
            //  交易记录
            doneTransaction({chainId: ChainId.MAINNET,hash: resInfo.transactionHash,receipt: {...resInfo,status:1},summary:base})
          }else{
            setSwapErrorMessage(resInfo.message?resInfo.message:resInfo.toString())
          }
          setIsPendding(false)
    }
    // 成功关闭初始化
    const successDismiss=()=>{
          setIsOpen(false);
          setAmountInput("");
          setAmountOutput("");
          setTimeout(()=>{setxHash("")},500)
    }

    return (<>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
    {/* <CardNav activeIndex={3} /> */}
    <LimitCardNav activeIndex={2} />

      <AppBody>
        {/* {showFeather?<Wrappers id="swap-page" />:""}  */}
        <Wrapper id="agg-swap-page">
          {/* <SwapHeader  title={TranslateString(11038,"DEX Aggregator")} 
          description={TranslateString(11039,"Trade tokens with the best rates")} /> */}
          <SwapHeader title={TranslateString(284, 'Exchange')} description={TranslateString(13037, 'Take limit orders with the best rates')}/>
          <CardBody>

          <AutoColumn gap="md">
              <CurrencyInputPanel
                label={TranslateString(76, 'From')}
                value={amountInput}
                showMaxButton={!atMaxAmountInput}
                currency={currentInput}
                onUserInput={handUserInput}
                onMax={handMaxInput}
                onCurrencySelect={handSelectInput}
                otherCurrency={currentOutPut}
                id="agg-swap-currency-input"
              />
              <AutoColumn justify="space-between">
                <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable>
                    <IconButton
                      variant="tertiary"
                      onClick={() => {
                        // setApprovalSubmitted(false) // reset 2 step UI for approvals
                       onSwitchTokens()
                      }}
                      style={{ borderRadius: '50%' }}
                      size="sm"
                    >
                      
                      <ArrowDownIcon color="primary" width="24px" />
                    </IconButton>
                  </ArrowWrapper>

                </AutoRow>
              </AutoColumn>

               {/* 反向禁用   */}
              <CurrencyInputPanel
                disableInput
                value={amountOutput}
                onUserInput={handUserOutput}
                label={TranslateString(80, 'To')}
                showMaxButton={false}
                currency={currentOutPut}
                onCurrencySelect={handSelectOutput}
                otherCurrency={currentInput}
                id="agg-swap-currency-output"
              />

            </AutoColumn>
            <InfoGrouping style={showWrap?{display:"none"}:{}}>
      
              <TradePrice 
                inputAmounts={amountInput} 
                outputAmounts={amountOutput}
                inputSymbol={currentInput?.symbol}
                outputSymbol={currentOutPut?.symbol}
                inputDec={currentInput?.decimals}
                outputDec={currentOutPut?.decimals}
               />

              <RowBetween align="center" mb={1}>
                  <Text fontSize="14px">{TranslateString(13036,'Allow Partial Success')}</Text>
                  <Text fontSize="14px">{
                      allowPartDeal?
                      TranslateString(13034,'Yes'):
                      TranslateString(13035,'No')}
                  </Text>
              </RowBetween>
              {
                 expectedData &&
                 amountInput && 
                 amountOutput && 
                 expectedData.dealOrders.length>0?
                <RouterSwap 
                dealOrders={expectedData?.dealOrders||[]}  
                distribution={distribution} 
                outputCurrency={currentOutPut as any} 
                inputCurrency={currentInput as any}/>
                :""
              }
            
 
            </InfoGrouping>
            <BottomGrouping>
             {showButton()}
            </BottomGrouping>


         
            <ConfirmModal 
              successHash={xhash}
              onSuccessDismiss={successDismiss}
              swapErrorMessage={swapErrorMessage}
              isPendding={isPendding}
              onConfirm={()=>{handerConfirm()}}
              onErroDismiss={()=>{
                setIsOpen(false);
                setTimeout(()=>{setSwapErrorMessage('')},500)
              }}
              onDismiss={()=>{setIsOpen(false)}}
              isOpen={isOpen}
              inputCurrency={currentInput as Currency} 
              outputCurrency={currentOutPut as Currency} 
              inputAmount={amountInput} 
              outputAmount={amountOutput}/>
         
          </CardBody>
        </Wrapper>
      </AppBody>

    {
      amountInput && amountOutput && !showWrap?
      <>{
        distribution.length>0?
        <>
          <Flex mt={3}/>
          <StyleAppBody>
              <Wrapper id="agg-swap-page">
              <CardBody>
                <HelpInfo
                  outputCurrency={currentOutPut as any}
                  outputAmount={amountOutput as any}
                />
              </CardBody>
            </Wrapper>
          </StyleAppBody>
        </>
      
        :''
      }
      <Flex mt={3}/>
 
      </>
      :''
    }
   
    </>)
}

export default LimitSwap


