import { Card,Modal,ArrowDownIcon, Button, CardBody, Flex, IconButton,Text, useModal } from '@pancakeswap-libs/uikit'
import React, {  useEffect, useMemo, useRef, useState } from 'react'
import {GlobalNotifications} from 'components/GlobalNotifications'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import LimitCardNav from 'components/LimitCardNav'
import OrderList from './OrderList'




const StylePanle = styled(Flex)`
  position: relative;
  max-width:880px;
  width: 100%;
  z-index: 5;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding:0px;
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding:30px;
    max-width:880px;
  }
`
const BodyWrapper = styled(Card)`
  position: relative;
  max-width:880px;
  width: 100%;
  z-index: 5;
  padding:16px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    max-width:880px;
  }
  overflow: unset;
`
 const Wrapper = styled.div`
  position: relative;
`
export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const StyleSelectTimePanel = styled(Flex)`
  height: 40px;
`

const LimitOrder = () => {
   const { account } = useWeb3React();
   const [rate,setRate]=useState<any>(1);

   return (<>
           <LimitCardNav activeIndex={1} />
           <BodyWrapper>
                      <Wrapper id="agg-swap-page">
                        <Flex  flexDirection="column">
                              <OrderList/>
                        </Flex>
                      </Wrapper>
                 
           </BodyWrapper>
    </>)
}

export default LimitOrder


