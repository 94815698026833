import { createReducer } from '@reduxjs/toolkit'
import { selectDex} from './actions'

export interface griDexSwapState {
  readonly dexs: Array<boolean>
}

const initialState: griDexSwapState = {
  dexs:[true,true,true,true,true,true,true,true,true,true,true]
}
// dexs:['MISTSWAP','TANGOSWAP','LAWSWAP','COWSWAP','ONEBCH','TROPICALFINANCE','VERSEDEX','EMBERSWAP']

export default createReducer<griDexSwapState>(initialState, (builder) =>
  builder
    .addCase(selectDex, (state, { payload: { dexs } }) => {
      const backDex=[...dexs];
      if(backDex[8]===true){
        return {...state,dexs:[...backDex]}
      }

      if(state.dexs[8]!==backDex[8]){
         backDex[9]=backDex[8];
         return {...state,dexs:[...backDex]}
      }
      if(state.dexs[9]!==backDex[9]){
        backDex[8]=backDex[9];
        return {...state,dexs:[...backDex]}
      }
      return {...state,dexs:[...backDex]}
    })
)
