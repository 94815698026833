import BigNumber from 'bignumber.js';
import toPrecision, { toFormatPrice } from '../../../utils/getPrecision';


const getRate = (isHighest: boolean, chartsData: Array<any>, val: string,priceInfo:any) => {
  const valTran=toFormatPrice(new BigNumber(val),4,4);
  // --------------最低兑换率-------------------
  if (!isHighest) {
    const found = [...chartsData].reverse().find(v => {
      const price0=toFormatPrice(new BigNumber(v.price0),4,4);
      if (new BigNumber(v.price0).lte(val)  && valTran.includes(".") && price0.includes(`.${valTran.split('.')[1]}`)) {
        return true
      }
      return new BigNumber(price0).toNumber() - new BigNumber((valTran as any)).toNumber() <= 0
    })

    // migGridinitialized 为false 重置为默认值
    // 拖动价格为currentPrice  重置为默认值
    if(val===priceInfo.price || (new BigNumber(val).gt(new BigNumber(priceInfo.price)) && !priceInfo.migGridinitialized)){
      const idx=chartsData.findIndex(t=>t.price0===priceInfo.price); 
      return chartsData[idx-1].price0;
    }

    if(!found){ return chartsData[0].price0 }
    return found.price0
  }
  

  // -------------最高兑换率--------------------
  const found = chartsData.find(v => {
    const price0=toFormatPrice(new BigNumber(v.price0),4,4);
    if (new BigNumber(v.price0).gte(val) && valTran.includes(".") && price0.includes(`.${valTran.split('.')[1]}`)) {
         return true
    }
    return new BigNumber(price0).toNumber() - new BigNumber((valTran as any)).toNumber() >= 0
  })

  // migGridinitialized 为false 重置为默认值
  // 拖动价格为currentPrice  重置为默认值
  if(val===priceInfo.price || (new BigNumber(val).lt(new BigNumber(priceInfo.price)) && !priceInfo.migGridinitialized)){
    const idx=chartsData.findIndex(t=>t.price0===priceInfo.price); 
    return chartsData[idx+1].price0;
  }

  if(!found){ return chartsData[chartsData.length-1].price0 }

  return found.price0
}

export default getRate
