import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import {Modal,Input,Button,Flex,Text } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import BigNumber from 'bignumber.js'
import { toFormatPrice } from 'utils/getPrecision'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { useGriLiquidityActionHandlers, useGriLiquidityState } from '../../state/griDexLiquidity/hooks'
import { getBchAddress, getChecksumAddress } from '../../utils/addressHelpers'
import { useTokenInfo } from '../../hooks/Tokens'
import Logo from '../../components/Logo'
import { addShares } from '../../utils/gidex'

import { useActiveWeb3React } from '../../hooks'
import { useAddPopup } from '../../state/application/hooks'
import Circle from '../../assets/images/blue-loader.svg'
import { Spinner } from '../../components/Shared'
import TransactionSubmittedContent from './component/TransactionSubmittedContent'





const StylePanel= styled(Flex)`
  background-color:${({ theme })=>theme.colors.background};
  border-radius: 20px;
  flex-direction: column;
  padding:16px;
  justify-content: center;
  /* align-items: center; */
`
const Image = styled(Logo)`
  height: 24px;
  width: auto;
  display: inline-block;
  z-index: 1;
`

const TokenImage = styled(Image)<{isMobile?: boolean}>`
  height: ${props => props.isMobile ? "12px" : '20px'};
  width: ${props => props.isMobile ? "12px" : '20px'};
`

const MinText = styled(Text)`
  font-size: 12px;
  color:${({ theme })=>theme.isDark?"#ccc":"#929292"} ;
`
const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const ComfirmationWrapper = styled.div`
  margin: -24px;
`

interface RomveDlgProps {
  onDismiss?:()=>void;
  pircie:string;
  result:any;
}

const ConfirmModal: React.FC<RomveDlgProps> = ({ onDismiss,pircie,result }) => {
  const TranslateString = useI18n();
  const { isDesktop } = useMatchBreakpoints();

  const {feeTier,sharesResult,tokenAamountValue,tokenBamountValue,selectToken,leftRangeTypedValue,rightRangeTypedValue}=useGriLiquidityState();
  const tokenA=useTokenInfo(selectToken.tokenA);
  const tokenB=useTokenInfo(selectToken.tokenB);
  const { account, chainId } = useActiveWeb3React();
  const addPopup = useAddPopup();
  // const [resHash,setResHash]=useState<string>("");
  const [pendding,setPendding]=useState<boolean>();
  const [erroMessage,setErroMessage]=useState<string>("");
  const [userSlippageTolerance] = useUserSlippageTolerance();
  const {onSetClearAll,onTokenAamountValue,onTokenBamountValue}=useGriLiquidityActionHandlers();
  const resHash=useRef<any>("");
  const setResHash=(evt:string)=>{
    resHash.current=evt;
  }


  const  getBase=()=>{
    const showAval=toFormatPrice(new BigNumber(tokenAamountValue),2)
    const showBval=toFormatPrice(new BigNumber(tokenBamountValue),2)
    let base = `Add ${showAval} ${tokenA?.symbol} and ${showBval} ${tokenB?.symbol} Liquidity`
    if(tokenAamountValue==="0"){
      base = `Add ${showBval} ${tokenB?.symbol}`
    }
    if(tokenBamountValue==="0"){
      base = `Add ${showAval} ${tokenA?.symbol}`
    }
    return base
  }
  const getBchAmount=(A:string,B:string)=>{
    let bchAmount="";
    if(selectToken.tokenA===getBchAddress()){
       bchAmount=A;
    }
    if(selectToken.tokenB===getBchAddress()){
       bchAmount=B;
    }
    return bchAmount;
  }

  const sureHand=(str:string)=>{
        setResHash(str)
        onTokenAamountValue("");
        onTokenBamountValue("");
        onSetClearAll(true)
        setTimeout(()=>{
          onSetClearAll(false)
        },100)
  }
  const onSumbit=async ()=>{
      setPendding(true);
      setErroMessage("")
      try{
        // 提示信息
        const base=getBase();
        const amountA=new BigNumber(tokenAamountValue).times(new BigNumber(10).pow(tokenA?.decimals||0)).toString()
        const amountB=new BigNumber(tokenBamountValue).times(new BigNumber(10).pow(tokenB?.decimals||0)).toString()

        console.log("userSlippageTolerance",userSlippageTolerance/10)
        const res=await  addShares(account, sureHand,selectToken.tokenA, selectToken.tokenB, feeTier.gridexType, amountA, amountB, result, sharesResult)
        console.log("成功",resHash.current)
        addPopup(
            {txn: {hash:resHash.current,success: true,summary:base}
            },
            resHash.current
        )
      }catch(e:any){
        console.log("失败")
        setErroMessage(e.message?e.message:e.toString())
      }
      setPendding(false);
  }


  const showComponent=()=>{
    if(resHash.current!==""){
      return <ComfirmationWrapper><TransactionSubmittedContent isNoShowClose chainId={ChainId.MAINNET} hash={resHash.current} onDismiss={onDismiss as any} /></ComfirmationWrapper>
    }
    if(pendding&&resHash.current===""){
      return  <Flex justifyContent='center' alignItems="center">
             <CustomLightSpinner src={Circle} alt="loader" size="90px" />
            </Flex>
    }

    if(erroMessage!==""){
      return <Flex justifyContent='center' alignItems="center">
      <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
         {erroMessage}
      </Text>
     </Flex>
    }
    return ""
  }

  return (<>
       <Modal title={TranslateString(10028,"Add Liquidity")} onDismiss={onDismiss}>
        {showComponent()}

        {/* sure modal */}
        {  erroMessage==="" && !pendding && !resHash.current?<>
           <Flex mb={2}>
                <Text fontSize='18px' bold>{tokenA?.symbol}/{tokenB?.symbol}</Text>
                </Flex>
                <StylePanel flex={1}>
                    <Flex flex={1} justifyContent="space-between">
                        <Flex alignItems="center">
                            {selectToken.tokenA && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(selectToken.tokenA)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${selectToken.tokenA} Logo`} />}
                            <Text ml={2} bold>{tokenA?.symbol}</Text>
                        </Flex>
                        <Text bold>{toFormatPrice(new BigNumber(tokenAamountValue),2)}</Text>
                    </Flex>
                    <Flex flex={1} mt={2} justifyContent="space-between">
                        <Flex alignItems="center">
                            {selectToken.tokenB && <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(selectToken.tokenB)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${selectToken.tokenB} Logo`} />}
                            <Text ml={2} bold>{tokenB?.symbol}</Text>
                        </Flex>
                        <Text bold>{toFormatPrice(new BigNumber(tokenBamountValue),2)}</Text>
                </Flex>
                    {/* <Flex flex={1} mt={3} justifyContent="space-between">
                        <Text fontSize='14px' bold>{TranslateString(11126,"fee")}</Text>
                        <Text fontSize='14px' bold>{feeTier.fee/100}%</Text>
                    </Flex> */}
                </StylePanel>
                <Flex mt={3}><Text>{TranslateString(11122,"Range of exchange rate")}</Text></Flex>
                <Flex mt={2} mb={3}>
                    <StylePanel flex={1} alignItems="center">
                        <MinText fontSize='12px'>{TranslateString(11124,"Min Price")}</MinText>
                        <Text bold>{toFormatPrice(new BigNumber(leftRangeTypedValue),2)}</Text>
                        <MinText fontSize='12px'>{tokenB?.symbol}/{tokenA?.symbol}</MinText>
                    </StylePanel>
                    <StylePanel ml={3} flex={1} alignItems="center">
                        <MinText fontSize='12px'>{TranslateString(11125,"Max Price")}</MinText>
                        <Text bold>{toFormatPrice(new BigNumber(rightRangeTypedValue),2)}</Text>
                        <MinText fontSize='12px'>{tokenB?.symbol}/{tokenA?.symbol}</MinText>
                    </StylePanel>
                </Flex>

                <StylePanel alignItems="center">
                        <MinText fontSize='14px'>{TranslateString(11123,"present price")}</MinText>
                        <Text>{new BigNumber(pircie).toFixed(6)}</Text>
                        <MinText fontSize='14px'>{tokenB?.symbol}/{tokenA?.symbol}</MinText>
                </StylePanel>     
                <Flex mt={3}  justifyContent='center'>
                <Button onClick={onSumbit}>{TranslateString(10111,"Add")}</Button>
           </Flex>
           </>:""
        }   

       </Modal>
  </>
  )
}

export default ConfirmModal
