import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useWeb3 from "hooks/useWeb3";
import multicall from "utils/multicall";
import { UNIT } from "utils/limitex/price";
import { ethers } from "ethers";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLimitListState } from "state/limit/hooks";
import { useERC20, useLimitExContract } from "../../../hooks/useContractV2";
import ERC20_ABI from '../../../constants/abis/erc20.json';

const useGetOrderData = (activeTab:number,pageSize:number,defaultPageSize:number,stockAddress:string,moneyAddress:string): { data: any,isMoreStatus:boolean, orderPedding: boolean,readMorePedding:boolean } => {
    const web3 = useWeb3();
    const { account } = useWeb3React();
    const [isMoreStatus, setIsReadMore] = useState<boolean>(false);
    const [readMorePedding, setReadMorePedding] = useState<boolean>(true);
    const [orderPedding, setOrderPedding] = useState<boolean>(true);

    const [data, setData] = useState<Array<any>>([]);
    const { refresh } = useLimitListState();
    const filter=useRef<string>("");




    const getPairIds=useCallback(
        async(stock:string,money:string)=>{
            let filterData="";
            if(stock){
              filterData=`{stock:"${stock}"}`
            }
            if(money){
              filterData=`{money:"${money}"}`
            }
     
            if(stock && money){
               filterData=`{stock:"${stock}",money:"${money}"}`
            }
            
            try{
                 const res= await fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-limitex`, {
                     method: 'post',
                     headers: {
                         'Content-Type': 'application/json'
                     },
                     body: JSON.stringify({
                         "query": `{pairs(where:${filterData}){\n\n   id,\n  \timpl,\n  stock,\n  money\n}\n\n}`,
                         "variables": null,
                         "operationName": null
                     })
                 })
                const pairs=await res.json();
                return pairs?.data?.pairs.map(t=>`"${t.id}"`);
            }catch(e){
                return [];
            }
     
         },[]) 
    
  
    useEffect(() => {
        const getOrderList= async ()=>{
            // if(orderType!==ordeTypeRef.current){
            //    ordeTypeRef.current=orderType;
            //    if(!(ordeTypeRef.current && selectToken)){
            //       return;
            //    } 
            // }
      

            if (refresh === true || account || activeTab===4) {
                setOrderPedding(false);
                filter.current="";
                switch(activeTab){
                    case 1:
                        filter.current=`{operator:"${account}",expireTime_gte: "${parseInt(`${new Date().getTime()/1000}`)}",deleted:false}`
                    break;   
                    case 2:
                        filter.current=`{operator:"${account}",deleted:true}`
                    break;       
                    case 3:
                        filter.current=`{operator:"${account}",expireTime_lt: "${parseInt(`${new Date().getTime()/1000}`)}",deleted:false}`
                    break;       
                    case 4:
                        if(stockAddress || moneyAddress){
                            const pairIds=await getPairIds(stockAddress,moneyAddress);
                            filter.current=`{pair_in:[${pairIds.join(",")}]}`
                        }else{
                            filter.current=`{}`
                        }
                    break; 
                }
    
                fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-limitex`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "query": `{orders(first: ${defaultPageSize+1},orderDirection: desc,orderBy: createBlockNumber,
                        where:${filter.current}
                        ){\n    maxAllowance,\n    remainingAllowance,\n    usedAllowance,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n, stock,money    }\n  }\n}`,
                        "variables": null,
                        "operationName": null
                    })
                })
                    .then(res => res.json())
                    .then(async (r: any) => {
                        const orders = r?.data?.orders || []
                        const tokens = Array.from(new Set([].concat(...orders.map(order => [order.pair.stock, order.pair.money]))))
                        const decimals = (await multicall(ERC20_ABI, tokens.map((v) => ({
                            address: v,
                            name: 'decimals',
                        })))).map((v) => parseInt(v))
    
                        for (let index = 0; index < orders.length; index++) {
                            const order = orders[index];
                            const stockDecimals = decimals[tokens.findIndex(x => x === order.pair.stock)]
                            const moneyDecimals = decimals[tokens.findIndex(x => x === order.pair.money)]
                            order.stockAmount = new BigNumber(order.maxAllowance).div(10 ** stockDecimals).toString()
                            let priceMul = ethers.BigNumber.from(1);
                            let priceDiv = ethers.BigNumber.from(1);
                            if (moneyDecimals >= stockDecimals) {
                                // (10 ** (moneyDecimals - stockDecimals))
                                priceMul = ethers.BigNumber.from(10).pow(moneyDecimals - stockDecimals);
                            } else {
                                // (10 ** (stockDecimals - moneyDecimals))
                                priceDiv = ethers.BigNumber.from(10).pow(stockDecimals - moneyDecimals);
                            }
                            const amount = (ethers.BigNumber.from(order.price).mul(priceMul).mul(order.maxAllowance).div(UNIT.mul(priceDiv))).toString()
                            order.moneyAmount = new BigNumber(amount).div(10 ** moneyDecimals).toString()
                        }
                        // console.log("orders", orders);
    
                        setIsReadMore(orders.length>defaultPageSize);
                        if(orders.length>defaultPageSize){
                            orders.pop();
                        }
                        setData(orders);
                        setOrderPedding(true);
                    })
                    .catch((error)=>{
                        setOrderPedding(true);
                        setIsReadMore(false);
                        setData([]);
                    })
            }else{
                setIsReadMore(false);
                setData([]);
            }
        }
        
        getOrderList();
    }, [account,web3,refresh,activeTab,defaultPageSize,getPairIds,stockAddress,moneyAddress])



    useEffect(() => {
        if (pageSize>0 && filter.current!=="") {
            setReadMorePedding(false);
            fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-limitex`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "query": `{orders(skip: ${pageSize},first: ${defaultPageSize+1},orderDirection: desc,orderBy: createBlockNumber,
                    where:${filter.current}
                    ){\n    maxAllowance,\n    remainingAllowance,\n    usedAllowance,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n, stock,money    }\n  }\n}`,
                    "variables": null,
                    "operationName": null
                })
               })
                .then(res => res.json())
                .then(async (r: any) => {
                    const orders = r?.data?.orders || []
                    const tokens = Array.from(new Set([].concat(...orders.map(order => [order.pair.stock, order.pair.money]))))
                    const decimals = (await multicall(ERC20_ABI, tokens.map((v) => ({
                        address: v,
                        name: 'decimals',
                    })))).map((v) => parseInt(v))

                    for (let index = 0; index < orders.length; index++) {
                        const order = orders[index];
                        const stockDecimals = decimals[tokens.findIndex(x => x === order.pair.stock)]
                        const moneyDecimals = decimals[tokens.findIndex(x => x === order.pair.money)]
                        order.stockAmount = new BigNumber(order.maxAllowance).div(10 ** stockDecimals).toString()
                        let priceMul = ethers.BigNumber.from(1);
                        let priceDiv = ethers.BigNumber.from(1);
                        if (moneyDecimals >= stockDecimals) {
                            // (10 ** (moneyDecimals - stockDecimals))
                            priceMul = ethers.BigNumber.from(10).pow(moneyDecimals - stockDecimals);
                        } else {
                            // (10 ** (stockDecimals - moneyDecimals))
                            priceDiv = ethers.BigNumber.from(10).pow(stockDecimals - moneyDecimals);
                        }
                        // console.log(`${order.price}=======${priceMul}==========${order.maxAllowance}=========${priceDiv}`)
                        // console.log("js中最大安全整数：", ethers.BigNumber.from('10000000000000000'))
                        const amount = (ethers.BigNumber.from(order.price).mul(priceMul).mul(order.maxAllowance).div(UNIT.mul(priceDiv))).toString()
                        order.moneyAmount = new BigNumber(amount).div(10 ** moneyDecimals).toString()
                    }
                    // console.log("orders", orders);

                    setIsReadMore(orders.length>defaultPageSize);
                    if(orders.length>defaultPageSize){
                        orders.pop();
                    }
                   
                    setData(pre=>[...pre,...orders]);
                    setReadMorePedding(true);
               })
               .catch((error)=>{
                    setReadMorePedding(true);
                    setIsReadMore(false);
                    setData([]);
               })
        }
    }, [pageSize,defaultPageSize])
    return { data, orderPedding,readMorePedding,isMoreStatus }
}

export const useOrderTotal= (): { total:number } => {
    const web3 = useWeb3();
    const { account } = useWeb3React();
    const [total, setTotal] = useState<number>(0);
    const { refresh } = useLimitListState();
    const filter=useRef<string>("");

    useEffect(() => {
        if (refresh === true || account) {
            filter.current=`{operator:"${account}"}`;
            // filter.current=`{operator:"${account}",expireTime_gte: "${parseInt(`${new Date().getTime()/1000}`)}",deleted:false}`;
            fetch(`https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-limitex`, {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "query": `{orders(orderDirection: desc,orderBy: createBlockNumber,
                    where:${filter.current}
                    ){\n    maxAllowance,\n    remainingAllowance,\n    usedAllowance,\n    operator,\n    id,\n    price,\n    expireTime,\n    deleted,\n    pair{\n      id\n, stock,money    }\n  }\n}`,
                    "variables": null,
                    "operationName": null
                })
            })
                .then(res => res.json())
                .then(async (r: any) => {
                    const orders = r?.data?.orders || []
                    setTotal(orders.length);
                })
        }
    }, [account,web3,refresh])

    return {total}
}

export const useLimtiExOrderFuc = (pairAddress: string | null) => {
    const limitExContract = useLimitExContract(pairAddress || "");
    const { account } = useWeb3React();


    // delOrder order
    const delOrder = useCallback(async (orderId: string, setResHash: any) => {
        try {
            const txHash = await limitExContract.methods.changeMaxAllowanceOfOrder(orderId, "0")
                .send({ ...{}, from: account })
                .on('transactionHash', (tx) => {
                    setResHash(tx)
                })
            return { status: true, res: txHash };
        } catch (error: any) {
            console.log(error);
            return { status: false, res: error.message ? error.message : error.toString() };
        }
    }, [limitExContract, account])

    // updateOrder order
    const updateOrder = useCallback(async (orderId: string, amount: string, setResHash: any) => {
        try {
            const txHash = await limitExContract.methods.changeMaxAllowanceOfOrder(orderId, amount)
                .send({ ...{}, from: account })
                .on('transactionHash', (tx) => {
                    setResHash(tx)
                })
            return { status: true, res: txHash };
        } catch (error: any) {
            console.log(error);
            return { status: false, res: error.message ? error.message : error.toString() };
        }

    }, [limitExContract, account])

    return { delOrder, updateOrder };
}
export default useGetOrderData


