import { Card, Flex,Text,Button, Modal} from '@pancakeswap-libs/uikit'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import SelectCurrency from 'components/SelectSearchModal/SelectCurrency'
import { isBch } from 'utils/gidex/gridex'
import { getBchAddress } from 'utils/addressHelpers'
import { HelpCircle } from 'react-feather'




const StyleSelectPanel =styled(Flex)`
   height: 40px;
`
const StyleHelpPanel =styled(Flex)`
 color: ${({ theme }) => theme.colors.textSubtle};
 ${Text} {
    color: ${({ theme }) => theme.colors.textSubtle};
    /* 其他您想要的样式 */
  }
`

interface DlgImportTokenProps {
    onDismiss?:()=>void;
}

const DlgImportToken: React.FC<DlgImportTokenProps>= ({onDismiss}) => {
    const TranslateString = useI18n();
    const { isDesktop } = useMatchBreakpoints();
    const [tokenA,setTokenA]=useState("");
    const [tokenB,setTokenB]=useState("");
    const handSave=useCallback(()=>{
        let imports = JSON.parse(window.localStorage.getItem('importToken')||"[]");
        if(imports.length>0){
            imports.push([tokenA,tokenB]);
        }else{
            imports=[[tokenA,tokenB]];
        }
        window.localStorage.setItem('importToken',JSON.stringify(imports));
        (onDismiss as any)();
    },[tokenA,tokenB,onDismiss])

    return <>
           <Modal title={TranslateString(11149,"Import")} onDismiss={onDismiss}>
             <Flex flexDirection="column">
                <StyleHelpPanel  mb={3}>
                    <HelpCircle size={16} />
                    <Text ml={1} fontSize='12px'>{TranslateString(13094,"If liquidity is not displayed, you can try to manually import it here.")}</Text>
                </StyleHelpPanel>

                <StyleSelectPanel>
                   <SelectCurrency disabaleAddress={tokenB}  value={tokenA} 
                   onSelect={(token:string)=>{
                       if(token==="SBCH"){
                        token=getBchAddress();
                       }
                       if(token===tokenB){
                        setTokenA(tokenB)
                        setTokenB(tokenA)
                       }else{
                        setTokenA(token)
                       }
                   }} />
                </StyleSelectPanel>
                <StyleSelectPanel mt={3}>
                     <SelectCurrency disabaleAddress={tokenA}  value={tokenB} 
                     onSelect={(token:string)=>{
                         if(token==="SBCH"){
                            token=getBchAddress();
                         }
                         if(token===tokenA){
                            setTokenA(tokenB)
                            setTokenB(tokenA)
                         }else{
                            setTokenB(token)
                         }
                         
                    }} />
                </StyleSelectPanel>
             </Flex>
             <Flex justifyContent="center" mt={3}>
                 <Button  size="sm" onClick={handSave}>
                     {TranslateString(11139,"Confirm")}
                 </Button>
             </Flex>
           </Modal>
    </>
    
    
 
}
export default DlgImportToken

