import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
// eslint-disable-next-line
import * as echarts from 'echarts';
// eslint-disable-next-line
import { EChartsOption } from 'echarts'
import BigNumber from 'bignumber.js';
import { Flex } from '@pancakeswap-libs/uikit'

import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import toPrecision, { toFormatPrice } from '../../utils/getPrecision';
import { useGriLiquidityActionHandlers, useGriLiquidityState } from '../../state/griDexLiquidity/hooks';
import useI18n from '../../hooks/useI18n';









const StyledPanel = styled(Flex)`
  width:100%;
  height: 280px;
`


interface PageHeaderProps {
  echartData:Array<any>,
  currentPrice:string,
  leftValue:string,
  rigthValue:string,
  onLeftRangeInput: (typedValue: string) => void;
  onRightRangeInput: (typedValue: string) => void;
}

const GridexChart: React.FC<PageHeaderProps>= ({ 
    onLeftRangeInput,
    onRightRangeInput,
    leftValue,
    rigthValue,
    echartData,
    currentPrice}) => {
     const TranslateString = useI18n();
    const {onSetAllrange}=useGriLiquidityActionHandlers();
    const { isMobile,isTablet } = useMatchBreakpoints();
    const { priceInfo }=useGriLiquidityState();
    // const TranslateString = useI18n();
    const {isDark} = useTheme();
    const mainDom=useRef<any>();
    const chartObj=useRef<any>();
    const doomZoomData=useRef<any>({start:0,end:100});


    const upColor = '#00da3c';
    const downColor = '#ec0000';

    function splitData(rawData: any[][]) {
    const categoryDatas:any = [];
    const valuess :any= [];
    const volumess :any= [];
    for (let i = 0; i < rawData.length; i++) {
        categoryDatas.push(i);
        valuess.push(rawData[i]);
        volumess.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
    }
    const categorySplit=rawData.filter(t=>(t as any).activeLiquidity!=="-1");
    // return {
    //     categoryData:categorySplit.map(t=>(t as any).price0),
    //     value:rawData.map(t=>(t as any).activeLiquidity),
    //     volumes:[]
    // }

    return {
        categoryData:rawData.map(t=>(t as any).price0),
        value:categorySplit.map(t=>(t as any).activeLiquidity),
        volumes:[]
    }

    
    }

    const backRang=useRef<any>({
        left:"0",
        right:"0"
    });
    useEffect(()=>{
        backRang.current.left=leftValue;
        backRang.current.right=rigthValue;
    },[leftValue,rigthValue])
    

    const roladEchart=useCallback((init:boolean)=>{
        // const chartDom = document.getElementById('main');
        // const myChart = echarts.init((chartDom as any));
        // chartObj.current=myChart;
        let option: EChartsOption;
        const data = splitData(echartData);
        let yMax=data.value.reduce((a,b)=>{
            return new BigNumber(a).gt(new BigNumber(b))?a:b
        })
        yMax=yMax==='0.00'?1:yMax
        chartObj.current.setOption(
            (option = {
            animation: false,
            // legend: {
            //     bottom: 10,
            //     left: 'center',
            //     data: ['price', '']
            // },
            tooltip: {
                trigger: 'axis',
                borderWidth: 1,
                borderColor: '#ccc',
                padding: 10,
                textStyle: {
                color: '#000'
                },
                position: (pos, params, el, elRect, size)=>{
                const obj: Record<string, number> = {
                    top: 10
                };
                obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                return obj;
                },
                formatter:  (params)=>{
                    return ""
                },
                axisPointer: { // 显示坐标轴指示器
                    type: 'cross', // 指示器类型为十字准星
                    label: { // 显示刻度坐标值
                      show: true,
                      formatter: ({ value, axisDimension }) => {
                        return `${axisDimension === 'x' ? TranslateString(11165,"Price") : TranslateString(74,"Liquidity")}: ${toFormatPrice(new BigNumber(`${value}`),4)}`;
                      },
                    },
                  },
                // extraCssText: 'width: 170px'
            },
            axisPointer: {
                link: [
                {
                    xAxisIndex: 'all'
                }
                ],
                label: {
                backgroundColor: '#777'
                }
            },
            toolbox: {
                show:false,
                feature: {
                dataZoom: {
                    yAxisIndex: false
                },
                brush: {
                    type: ['lineX', 'clear']
                }
                }
            },
            brush: {
                throttleDelay:2,
                throttleType:"debounce",
                xAxisIndex: 'all',
                brushLink: 'all',
                outOfBrush: {
                    colorAlpha: 1
                },
                brushStyle:{
                    borderWidth: 3,
                    color: 'rgba(120,140,180,0.3)',
                    borderColor: '#1570f1'
                }
            },
            visualMap: {
                show: false,
                seriesIndex: 5,
                dimension: 2,
                pieces: [
                {
                    value: 1,
                    color: downColor
                },
                {
                    value: -1,
                    color: upColor
                }
                ]
            },
            grid: [
                {
                top:"5%",
                left: '12%',
                right: '8%',
                height: '60%',
                tooltip: {
                    show: true
                 }
                },
                {
                left: '10%',
                right: '8%',
                top: '100%',
                height: '16%'
                }
            ],
            xAxis: [
                {
                type: 'category',
                data: data.categoryData,
                boundaryGap: false,
                axisLine: { onZero: false,
                    // y轴线的配置
                    
                    lineStyle: {
                        color: isDark?"#fff":"#000"
                        // y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                    },
                },
                axisLabel:{
                    formatter:  (value)=>{
            
                        if(new BigNumber(0.0000001).gt(new BigNumber(value))){
                            return `${toFormatPrice(new BigNumber(value),4,4)}`;
                        }
                        return `${toFormatPrice(new BigNumber(value),4,4)}`;
                    }
                },
                splitLine: { show: false },
                min: 'dataMin',
                max: 'dataMax',
                axisPointer: {
                    z: 100
                }
                },
                {
                type: 'category',
                gridIndex: 1,
                data: data.categoryData,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: { show: false },
                min: 'dataMin',
                max: 'dataMax'
                }
            ],
            yAxis: [
                {
                splitLine:{
                        show:false
                },
                scale: true,
                max:yMax,
                axisLine: {
                    // y轴线的配置
                    lineStyle: {
                        color: isDark?"#fff":"#000",
                        // y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                    },
                },
                axisLabel: {
                    show: true,
                    formatter: (value, index)=>{
                        const str=`${value}`
                        if (str.length > 4) {
                            return `${str.substring(0, 4)}...`;
                        }
                        return str;
                    }
                 }
                },
                {
                    scale: false,
                    gridIndex: 1,
                    splitNumber: 1,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false }
                }
               
            ],
            dataZoom: [
                {
                type: 'inside',
                xAxisIndex: [0, 1],
                start: doomZoomData.current.start,
                end: doomZoomData.current.end
                },
                {
                show: true,
                xAxisIndex: [0, 1],
                type: 'slider',
                top: '75%',
                start: 98,
                end: 100,
                textStyle:{
                    color: isDark?"#fff":"#000",
                },
                labelFormatter:(value)=>{
                    const Option=chartObj.current.getOption();
                    const valueStr=Option.xAxis[0].data[value];
                    return toFormatPrice(new BigNumber(valueStr),4,4);
                 }
                }
            ],
            series: [
            
                    {
                    name: 'price',
                    type: 'line',
                    symbol: 'none',
                    smooth: true,
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 100, [
                        {
                            offset: 0,
                            color: '#4d84d1'
                        },
                        {
                            offset: 1,
                            color: '#4d84d1'
                        }
                        ])
                    },
                    lineStyle: {
                        width: 1,
                        color:'#4d84d1'
                        // backgroundColor:'#ccc'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data:data.value
                    },
                    
                    {
                        name: '',
                        type: 'bar',
                        itemStyle:{
                            color:"#6ccd6f",
                        },
                        
                        barWidth:3,
                        data:[[currentPrice,yMax]]
                        }
            ]
            }),
            true
        );
        chartObj.current.dispatchAction({
            type: 'brush',
            areas: [
            {
                brushType: 'lineX',
                coordRange: [leftValue, rigthValue],
                xAxisIndex: 0
            }
            ]
        });
        if(init){
            chartObj.current.on('brushEnd',  (params:any)=>{
                if(params.areas[0]){
                    const left=params.areas[0].coordRange[0];
                    const right=params.areas[0].coordRange[1];
                    const Option=chartObj.current.getOption();
                    let leftRangeVal=Option.xAxis[0].data[left];
                    let righRangetVal=Option.xAxis[0].data[right];

                    // migGridinitialized 为false 重置为默认值
                    if(new BigNumber(leftRangeVal).gt(new BigNumber(currentPrice)) && !priceInfo.migGridinitialized){
                        const idx=Option.xAxis[0].data.findIndex((t:string)=>t===currentPrice); 
                        leftRangeVal=Option.xAxis[0].data[idx-1]
                    }
                    if(new BigNumber(righRangetVal).lt(new BigNumber(currentPrice)) && !priceInfo.migGridinitialized){
                        const idx=Option.xAxis[0].data.findIndex((t:string)=>t===currentPrice); 
                        righRangetVal=Option.xAxis[0].data[idx+1]
                    }
                    // 拖动价格为currentPrice  重置为默认值
                    if(leftRangeVal===currentPrice){
                       leftRangeVal=Option.xAxis[0].data[left-1]
                    }
                    if(righRangetVal===currentPrice){
                       righRangetVal=Option.xAxis[0].data[right+1]
                    }
                    onSetAllrange([leftRangeVal,righRangetVal]);
                    onLeftRangeInput(leftRangeVal);
                    onRightRangeInput(righRangetVal);
                } 
                // console.log(params); // 用某种方式输出统计值。
            });
            chartObj.current.on('dataZoom', function (event) {
                if (event.batch) {
                  doomZoomData.current.start = event.batch[0].start
                  doomZoomData.current.end = event.batch[0].end
                } else {
                  doomZoomData.current.start = event.start
                  doomZoomData.current.end = event.end
                }
            })
        }
        
    },[TranslateString,priceInfo.migGridinitialized,isDark,echartData,currentPrice,leftValue,rigthValue,onSetAllrange,onLeftRangeInput,onRightRangeInput])

    useEffect(()=>{
        if(chartObj.current){
            roladEchart(false);
        }else{
            chartObj.current=echarts.init((mainDom.current as any))
            roladEchart(true);
        }
    },[roladEchart])

  return (<Flex flex={1}>
  <StyledPanel id="main" ref={mainDom} />
  </Flex>
    
  )
}

export default GridexChart


