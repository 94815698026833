import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useMulticallContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/eggPriceContracts'

type ApiResponse = {
  prices: {
    [key: string]: string
  }
  update_at: string
}

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
// const api = 'https://api.pancakeswap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){
          const {cakeAddress, wbchAddress, lpAddress} = priceContracts;
          const calls = [
            [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
            [wbchAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [cakeAmount, wbchAmount] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const cake = new BigNumber(cakeAmount);
          const wbch = new BigNumber(wbchAmount);
          const wbchPrice = await getBchPrice();
          
          const cakePrice = wbch.times(new BigNumber(wbchPrice)).div(cake).toNumber();
          setData(cakePrice)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}

export const getBchPrice = async() => {
  let price:any = null;

  try {
    await fetch("https://api2.benswap.cash/sbchPrice").then((response)=>{
      return response.ok ? response.json() : null;
    }).then((r) =>{
      if (r && r.isSuccess) {
          price = Number.isNaN(Number(r.price))?null:r.price;
      }
    });
  } catch (e) {
    console.error(e);
  }

  if (!price) {
    try {
      await fetch("https://api.benswap.cash/api/smartbch/price").then((response)=>{
        return response.ok ? response.text() : null;
      }).then((text) =>{
        price = Number.isNaN(Number(text))?null:text;
      });
    } catch (e) {
      console.error(e);
    }
  }

  return price;
}


export default useGetPriceData
