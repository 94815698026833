import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {setRefreshlist, setAllowPartDeal }  from './actions'
import { AppDispatch, AppState } from '../index'


export function useP2pListState(): AppState['limit'] {
  return useSelector<AppState, AppState['limit']>((state) => state.limit)
}

export function useP2pListStateHandlers(): {
  onRefreshPage:(refresh:boolean)=>void
  onSetAllowPartDeal:(refresh:boolean)=>void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onRefreshPage = useCallback((refresh:boolean) => {
    dispatch(setRefreshlist({refresh}))
  }, [dispatch])

  const onSetAllowPartDeal = useCallback((allowPartDeal:boolean) => {
    dispatch(setAllowPartDeal({allowPartDeal}))
  }, [dispatch])


  return {
    onRefreshPage,
    onSetAllowPartDeal
  }
}
