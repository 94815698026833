import { getAvggerAddress, getBchAddress, getGridexAvggerAddress } from 'utils/addressHelpers';
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { useWeb3React } from '@web3-react/core';
import { useActiveWeb3React } from 'hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import Web3 from 'web3';
import {Subscription} from 'web3-core-subscriptions';
import {BlockHeader} from 'web3-eth';
import { AbiItem } from 'web3-utils'
import { getNodeUrl } from 'connectors/index';
import aggregateDexAbi from '../../../constants/abis/gridex-AggregateAudit.json'

const NetWorkUrl = getNodeUrl()

const useCreateSmartSwap = () => {
    const { account,library, chainId } = useWeb3React()
    const web3 = new Web3(Number(chainId) === ChainId.MAINNET && library?.provider || new Web3.providers.HttpProvider(NetWorkUrl));
    const myContract=useMemo(()=>{
        return new web3.eth.Contract((aggregateDexAbi as unknown) as AbiItem,getGridexAvggerAddress())
    },
    [web3.eth.Contract])

    const refTimer = useRef<any>();
    const subBlock = useCallback((refFuc):Subscription<BlockHeader> => {
         const  subscription=web3.eth.subscribe('newBlockHeaders',(error, result)=>{
            if (!error) {
                console.log("新区块：：",result);
                if (refTimer.current) {
                    clearTimeout(refTimer.current);
                }
                refTimer.current = setTimeout(() => {
                    refFuc();
                }, 1000);

                return;
            }
            console.error(error);
         })
      
         return subscription;
      },
      [web3.eth],
    )

    const getExpectedReturn = useCallback(
        async (fromToken:string,destToken:string,amount:string,disableFlags:boolean[]) => {
            try {
                // console.log('myContract',myContract) 
                // disableFlags = new Array(disableFlags.length).fill(false) // debug
                // disableFlags[disableFlags.length -1] = true
                // disableFlags[disableFlags.length -2] = true

                const txHash=await myContract.methods.getExpectedReturnWithGas(
                    fromToken,
                    destToken,
                    amount,
                    20,
                    disableFlags,
                    0
                ).call({gas:1000000000000})
                return txHash
            } catch (e) {
                return false
            }
        },
        [myContract],
    )
    const swap = useCallback(
         async (tokens:Array<string>,amount:string,toMinAmount:string,distribution:Array<number>,args:Array<any>,time:string,sureFuc:(hash:string)=>void) => {
            try {
                const txHash=await myContract.methods.swap(
                    tokens,
                    amount,
                    toMinAmount,
                    distribution,
                    args,
                    time
                ).send({
                    gas:'',
                    from:account,
                    value:tokens[0]===getBchAddress()?amount:""
                })
                .on('transactionHash', (tx) => {
                    sureFuc(tx)
                })
                return [true,{...txHash,status:txHash.status===true?1:0}]
            } catch (e) {
                return [false,e]
            }
        },
        [myContract,account],
    )

    const calcArgs = useCallback(
        async (fromToken:string,destToken:string,amount:string,distribution:Array<number>) => {
           try {
               const txHash=await myContract.methods.calcArgs(
                   fromToken,
                   destToken,
                   amount,
                   distribution,
               ).call({gas:1000000000000})
               return txHash
           } catch (e) {
               return false
           }
       },
       [myContract],
     )
    return {getExpectedReturn,swap,subBlock,calcArgs};
}
export default useCreateSmartSwap
