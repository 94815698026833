import { createReducer } from '@reduxjs/toolkit'
import {addNotif, reomoveNotif } from './actions'


export interface NotifState {
  readonly notifs: Array<{
    id:number
    title?:string
    variant?:any|string
    content?:any
    i18n?:{index:number,value:string}
  }>
}

const initialState: NotifState = {
  notifs:[]
}

export default createReducer<NotifState>(initialState, (builder) =>
  builder
    .addCase(addNotif, (state, { payload:{notifItem}}) => {
      // return {...state,notifItem}
      state.notifs.unshift({id:new Date().getTime(),...notifItem});
    })
    .addCase(reomoveNotif, (state, { payload:{id}}) => {
      // return {...state,notifItem}
      const index=state.notifs.findIndex(t=>t.id===id);
      if(index!==-1){
        state.notifs.splice(index,1);
        console.log(state.notifs)
      }
      
    })
)
