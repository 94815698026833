import React from 'react'
import styled from 'styled-components'
import { Flex,ChevronDownIcon,Button, useModal,Modal, CloseIcon } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import RomveDlg from './RomveDlg'


interface RemoveBtnProps {
  orderId:string
  pairsAddress:string
}

const Container = styled(Flex)`
    border-radius: 5px;
    padding: 2px;
    :hover{
      background-color: ${({ theme })=>theme.colors.background};
    }
    
    svg{
      fill: #ff0000;
    }
`



const CloseIconBtn: React.FC<RemoveBtnProps> = ({orderId,pairsAddress}) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const [onPresentSettings] = useModal(<RomveDlg pairsAddress={pairsAddress} orderId={orderId}  />)

  return (<Container title={TranslateString(13081,"Order Cancellation")}>
           <CloseIcon  style={{cursor:'pointer',width:!isDesktop?'':""}} onClick={onPresentSettings}/>
          </Container>
  )
}

export default CloseIconBtn
