import { Modal,ArrowDownIcon, Button, CardBody, Flex, IconButton,Text, useModal } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import LimitCardNav from 'components/LimitCardNav'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import {  RowBetween } from 'components/Row'
import {  BottomGrouping } from 'components/swap/styleds'
import AppBody from 'pages/AppBody'
import SwapHeader from 'pages/SmartSwap/xSwapHeaderx'
import React, {  useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'
import Card from 'components/Card'
import { useCurrencyBalances, useTokenBalance } from 'state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'
import Loading from 'components/Loading'
import { useCurrency, useTokenInfo } from '../../hooks/Tokens'
import Rate from './rate'
import { EBEN, WBCH } from '../../constants'
import { useApprovefuc, useGetPrice, useLimitExFactory } from './hooks/useLimit'
import { getBchAddress, getLimitFactoryAdress } from '../../utils/addressHelpers'
import ConfirmModal from './ConfirmModal'
import Select, { OptionProps } from '../../components/SelectInput/SelectInput'
import ProgressSteps from '../../components/ProgressSteps'
import Loader from '../../components/Loader'


const StyledLoadingContainer = styled(Flex)`
  height: 60px;
  align-items: center;
  justify-content: center;
`

const StyledLoadingContainerInner = styled.div`
  margin-top: 2px;
  display: inline-block;
  width: 98%;
  max-width: 200px;
`
export const Wrapper = styled.div`
  position: relative; 
`
export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const StyleSelectTimePanel = styled(Flex)`
  height: 40px;
`

const Limit = () => {
    const { account } = useWeb3React();
    const stepStatus=useRef<any>(false);

    const defaultInput:any=useTokenInfo(getBchAddress());
    const defaultOutput=useCurrency('');
    const [currentSelect,setCurrentSelect]=useState({
          INPUT:defaultInput,
          OUTPUT:defaultOutput
    })
    const [tokenAmount,setTokenAmount]=useState<any>(['0','0'])
    const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
      currentSelect?.INPUT ?? undefined,
    ])

    const {rate,isPedding,setRate,getPriceFuc}=useGetPrice(currentSelect?.INPUT?(currentSelect.INPUT as any)?.address:'',(currentSelect?.OUTPUT as any)?.address||'',currentSelect?.INPUT?.decimals||0,currentSelect?.OUTPUT?.decimals||0)
    // useEffect(()=>{setRate(ratePrice)},[ratePrice]);
    // const tokenABalance=useTokenBalance(account ?? undefined,currentSelect?.INPUT as any);
    // init Data
    const claerData=()=>{
      setCurrentSelect({
        INPUT:defaultInput,
        OUTPUT:defaultOutput
      })
      setRate(1);
      setTokenAmount(['0','0']);
      A_approve.setApproved(A_approve.approved+1);
      stepStatus.current=false;
    }
    // expiration time
    const [selectTime,setSelectTime]=useState<{i18n:{index:number,value:string},label:string,value:string}>({i18n:{index:13007,value:"1 Hour"},label:"1 Hour",value:`${1*3600}`});
    const handleSortOptionChange = (option: OptionProps): void => {
        setSelectTime({i18n:(option.i18n as any),label:option.label,value:option.value})
    }
    const A_approve=useApprovefuc((currentSelect.INPUT as any)?.address,getLimitFactoryAdress(),tokenAmount[0],currentSelect.INPUT?.decimals||0);
    // limitFactory
    const {pairAddress,pairPedding,createPair,createStatus}=useLimitExFactory((currentSelect.INPUT as any)?.address,(currentSelect.OUTPUT as any)?.address);
    const [onPresentSettings] = useModal(<ConfirmModal 
      rate={rate} 
      pairAddress={pairAddress}
      tokenAAmount={tokenAmount[0]}
      tokenBAmount={tokenAmount[1]}
      tokenA={currentSelect.INPUT}
      tokenB={currentSelect.OUTPUT}
      expirationTime={selectTime}
      onClearData={claerData}
      />);
  
    const switchFnc=()=>{
      setCurrentSelect({INPUT:currentSelect.OUTPUT,OUTPUT:currentSelect.INPUT})
      setTokenAmount(pre=>[pre[1],new BigNumber(pre[1]||"0").times(new BigNumber(rate||"0")).toFixed()])
    }
    useEffect(()=>{
      setTokenAmount(pre=>[pre[0],new BigNumber(pre[0]||"0").times(new BigNumber(rate||"0")).toFixed()])
    },[rate])
    // token change init step status
    useEffect(()=>{
      stepStatus.current=false;
    },[currentSelect])
    
    const onSetMax=()=>{
      const maxValue=new  BigNumber(relevantTokenBalances[0]?.toExact()||"0").toFixed(); 
      const val=new BigNumber(maxValue||"0").times(new BigNumber(rate)).toFixed();
      setTokenAmount(pre=>[maxValue,val])
    }  
    const isWbchtoBch=useMemo(()=>
    (currentSelect?.INPUT?.symbol==='SBCH' && (currentSelect?.OUTPUT as any)?.address===WBCH.address) ||
    (currentSelect?.OUTPUT?.symbol==='SBCH' && (currentSelect?.INPUT as any)?.address===WBCH.address)
    ,[currentSelect?.INPUT,currentSelect?.OUTPUT])
    const showButton=()=>{
        // amount check
        if(tokenAmount[0]==="" || new BigNumber(tokenAmount[0]).lte(0) || new BigNumber(tokenAmount[0]).lte(0)){
         return<Button fullWidth disabled>
                 {TranslateString(10026, 'Enter an amount')}
               </Button>
        }
        // balane check
        const BalanceIn=new BigNumber(relevantTokenBalances[0]?.toExact()||"0");
        const tokenAInputAmount=new BigNumber(tokenAmount[0]);
        if(BalanceIn && currentSelect?.INPUT && (BalanceIn.lte(new BigNumber(0)) || tokenAInputAmount.gt(BalanceIn))
          ){
          return <RowBetween>
          <Button fullWidth disabled>
            {TranslateString(10089, 'Insufficient')} {currentSelect?.INPUT?.symbol} {TranslateString(10090, 'balance')}
          </Button>
         </RowBetween>
        }
        // select token
        if(!currentSelect.INPUT?.symbol){
          return <Button  disabled  id="agg-swap-button" fullWidth >
                      {TranslateString(11102, 'Select a token')}
                 </Button>
        }
        // Approve
        if(!A_approve.tokenAllowedStatus){
          return <Button fullWidth  id="agg-swap-button" disabled={A_approve.requestedApproval} onClick={A_approve.handleApprove}>
                   {TranslateString(564, 'Approve')} {currentSelect.INPUT?.symbol}
                 </Button>
        }

     
       

        // create Pair  
        if((!pairAddress || stepStatus.current===true) && currentSelect.INPUT?.symbol && currentSelect.OUTPUT?.symbol){
          return <Flex flexDirection='column'>
                    <Flex>
                      <Button fullWidth  id="agg-swap-button" disabled={pairPedding || pairAddress!=null || createStatus||isWbchtoBch}
                              onClick={async()=>{
                                await createPair();
                                stepStatus.current=true;
                              }}>
                            {TranslateString(13055, 'Create Pair')}
                            {createStatus?<Flex ml={1}><Loader stroke="white" /></Flex>:null}
                      </Button>
                      <Button ml={4}  onClick={onPresentSettings} disabled={pairPedding || pairAddress==null || isWbchtoBch} id="agg-swap-button" fullWidth >
                            {TranslateString(13001, 'Create Order')}
                      </Button>
                    </Flex>
                    
                    <ProgressSteps steps={[pairAddress!==null]} />

                </Flex>
               
        }

        
        return <Button 
                  disabled={new BigNumber(tokenAmount[1]).lte(0) || !(currentSelect.INPUT?.symbol && currentSelect.OUTPUT?.symbol)}  
                  onClick={onPresentSettings} id="agg-swap-button" fullWidth >
                  {TranslateString(13001, 'Create Order')}
               </Button>
        

    }

   
    
   
   return (<>
      <LimitCardNav activeIndex={0} />
      <AppBody overflow='unset'>
        <Wrapper id="agg-swap-page">
          <SwapHeader isOpera={false}  title={TranslateString(13000, 'Limit Order')} description={TranslateString(13033, 'Trade tokens with desired rates')}/>
          <CardBody>
           
            <AutoColumn gap="md">
              <CurrencyInputPanel
                label={TranslateString(76, 'From')}
                value={tokenAmount[0]}
                showMaxButton
                currency={currentSelect.INPUT}
                onUserInput={(value:string)=>{
                  const val=new BigNumber(value||"0").times(new BigNumber(rate)).toFixed();
                  setTokenAmount([value,val])
                }}
                onMax={onSetMax}
                onCurrencySelect={(selectCurrency)=>{
                  if(selectCurrency.symbol===currentSelect.OUTPUT?.symbol){
                    switchFnc();
                  }else{
                    const selectObj=[selectCurrency][0];
                    if(selectObj.symbol==='SBCH'){(selectObj as any).address=getBchAddress();}
                    setCurrentSelect(pre=>({...pre,INPUT:selectObj}))
                  }
                }}
                otherCurrency={currentSelect.OUTPUT}
                id="agg-swap-currency-input"
              />

              <AutoColumn justify="space-between">
            
                  <Flex flexDirection="row" alignItems="center">
                    
                      <Flex flex={1} flexDirection="column">

                      <Rate 
                        isLoding={isPedding}
                        getCurrentRate={getPriceFuc}
                        form={currentSelect.INPUT?.symbol}
                        to={currentSelect.OUTPUT?.symbol}
                        currentRate={rate} 
                        onSetRate={setRate}/>
                      </Flex>

                      <Flex ml={3}>
                        <IconButton 
                              variant="tertiary"
                              onClick={switchFnc}
                              style={{ borderRadius: '50%' }}
                              size="sm">
                          <ArrowDownIcon color="primary" width="24px" />
                        </IconButton>
                      </Flex>

                   
                      
                </Flex>
               
              </AutoColumn>

               {/* 反向禁用   */}
              <CurrencyInputPanel
                disableInput
                value={tokenAmount[1]}
                onUserInput={(value:string)=>{
                  const val=new BigNumber(value||"0").div(new BigNumber(rate)).toFixed();
                  setTokenAmount(([val,value])
                )}}
                label={TranslateString(80, 'To')}
                showMaxButton={false}
                currency={currentSelect.OUTPUT}
                onCurrencySelect={(selectCurrency)=>{
                  if(selectCurrency.symbol===currentSelect.INPUT?.symbol){
                    switchFnc();
                  }else{
                    const selectObj=[selectCurrency][0];
                    if(selectObj.symbol==='SBCH'){
                      (selectObj as any).address=getBchAddress();
                    }
                    setCurrentSelect(pre=>({...pre,OUTPUT:selectObj}))
                 }
                }}
                otherCurrency={currentSelect.INPUT}
                id="agg-swap-currency-output"
              />

              <Flex justifyContent='space-between' alignItems='center' flex={1}>
                  <Text> {TranslateString(13002, 'Expires In:')}</Text>
                  <StyleSelectTimePanel>
                    <Select options={[
                        {
                          i18n:{index:13007,value:"1 Hour"},
                          label: TranslateString(13007,"1 Hour"),
                          value: `${1*3600}`,
                        },
                        {
                          i18n:{index:13008,value:"24 Hour"},
                          label: TranslateString(13008,"24 Hour"),
                          value: `${24*3600}`,
                        },
                        {

                          i18n:{index:13009,value:"1 Week"},
                          label: TranslateString(13009,"1 Week"),
                          value: `${7*24*3600}`,
                        },
                        {
                          i18n:{index:13010,value:"30 Days"},
                          label: TranslateString(13010,"30 Days"),
                          value: `${30*24*3600}`,
                        },
                        {
                          i18n:{index:13030,value:"Never"},
                          label: TranslateString(13030,"Never"),
                          value: `100000000000`,
                        }
                      ]}
                      onOptionChange={handleSortOptionChange}
                    />
                  </StyleSelectTimePanel>
                 
              </Flex>
                
            </AutoColumn>
   
            <BottomGrouping>
             {showButton()}
            </BottomGrouping>

          </CardBody>
        </Wrapper>
      </AppBody>
    </>)
}

export default Limit


