import React, { useContext, useState } from 'react'
import { Flex, Modal, SyncAltIcon, Text } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import CurrencyLogo from 'components/CurrencyLogo'
import { Currency } from '@pancakeswap-libs/sdk-v2'
import styled, { ThemeContext } from 'styled-components'
import { ArrowDown, AlertTriangle } from 'react-feather'
import { TranslateString } from 'utils/translateTextHelpers'
import { ContentHeader } from 'components/TransactionConfirmationModal/helpers'
import toPrecision from 'utils/getPrecision'
import { useUserSlippageTolerance } from 'state/user/hooks'
import SwapModalFooter from './SwapModalFooter'





const PriceInfoText = styled(Text)`
  font-style: italic;
  line-height: 1.3;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
`

interface ConfirmContentProps {
    inputCurrency:Currency;
    outputCurrency:Currency;
    inputAmount:string;
    outputAmount:string;
    onConfirm?:()=>void;
    onDismiss:()=>void;
}
const ConfirmContent: React.FC<ConfirmContentProps> = ({  
   inputCurrency,
   outputCurrency,
   inputAmount,
   outputAmount,
   onConfirm,
   onDismiss
  }) => {
  const [userSlippageTolerance] = useUserSlippageTolerance()    
  const theme = useContext(ThemeContext)
  const big=(str:string | number)=>{
    return new BigNumber(str)
  }
  const minReturn =()=>{
    const minAmount=big(outputAmount).minus(big(outputAmount).times(big((userSlippageTolerance/10000)))) 
    return  toPrecision(new BigNumber(minAmount),6)
  }

  return (

    <AutoColumn gap="md">

      <ContentHeader onDismiss={onDismiss}>{TranslateString(10118,"Confirm Swap")}</ContentHeader> 

      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={inputCurrency} size="24px" style={{ marginRight: '12px' }} />
          <Text
            fontSize="24px"
            // color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.colors.primary : 'text'}
          >
            {/* {inputAmount.toSignificant(6)} */}
            {toPrecision(new BigNumber(inputAmount),6)}
          </Text>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" style={{ marginLeft: '10px', fontWeight: 500 }}>
            {inputCurrency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowFixed>
        <ArrowDown size="16" color={theme.colors.textSubtle} style={{ marginLeft: '4px', minWidth: '16px' }} />
      </RowFixed>
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={outputCurrency} size="24px" style={{ marginRight: '12px' }} />
          <Text
            fontSize="24px"
            style={{ marginLeft: '10px', fontWeight: 500 }}
            // color={
            //   priceImpactSeverity > 2
            //     ? theme.colors.failure
            //     : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
            //     ? theme.colors.primary
            //     : 'text'
            // }
          >
            {toPrecision(new BigNumber(outputAmount),6)}
          </Text>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" style={{ marginLeft: '10px', fontWeight: 500 }}>
            {outputCurrency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      {/* {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
              <Main color={theme.colors.primary}>{TranslateString(10121,'Price Updated')}</Main>
            </RowFixed>
            <Button onClick={onAcceptChanges}>{TranslateString(10122,'Accept')}</Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null} */}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '16px 0 0' }}>
         <PriceInfoText>
            {TranslateString(10119,'Output is estimated. You will receive at least')}
            <span>
            {minReturn()} {outputCurrency.symbol}
            </span>
            {TranslateString(10120,'or the transaction will revert')}.
          </PriceInfoText>
       
       
        {/* {trade.tradeType === TradeType.EXACT_INPUT ? (
          <PriceInfoText>
            {TranslateString(10119,'Output is estimated. You will receive at least')}
            <span>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </span>
            {TranslateString(10120,'or the transaction will revert')}.
          </PriceInfoText>
        ) : (
          <PriceInfoText>
            {`Input is estimated. You will sell at most `}
            <span>
              {inputAmount} {inputCurrency.symbol}
            </span>
            {' or the transaction will revert.'}
          </PriceInfoText>
        )} */}
        
         {/* <PriceInfoText>
            {TranslateString(10119,'Output is estimated. You will receive at least')}
            <span>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </span>
            {TranslateString(10120,'or the transaction will revert')}.
          </PriceInfoText> */}
        
        {/* {trade.tradeType === TradeType.EXACT_INPUT ? (
          <PriceInfoText>
            {TranslateString(10119,'Output is estimated. You will receive at least')}
            <span>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </span>
            {TranslateString(10120,'or the transaction will revert')}.
          </PriceInfoText>
        ) : (
          <PriceInfoText>
            {`Input is estimated. You will sell at most `}
            <span>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </span>
            {' or the transaction will revert.'}
          </PriceInfoText>
        )} */}
      </AutoColumn>


    
     <SwapModalFooter
       minAmount={minReturn()}
       inputCurrency={inputCurrency}
       outputCurrency={outputCurrency}
       inputAmount={inputAmount}
       outputAmount={outputAmount}
       onConfirm={onConfirm}
     />


      {/* {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '16px 0 0' }}>
          <Main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </Main>
        </AutoColumn>
      ) : null} */}
    </AutoColumn>
  )
}

export default ConfirmContent
