import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import setRefreshlist  from './actions'

export function useGriLiquidityListState(): AppState['griLiquidityList'] {
  return useSelector<AppState, AppState['griLiquidityList']>((state) => state.griLiquidityList)
}

export function useGriLiquidityListStateHandlers(): {
  onRefreshLiquidity:(refreshLiquidityState:boolean)=>void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onRefreshLiquidity = useCallback((refreshLiquidityState:boolean) => {
    dispatch(setRefreshlist({refreshLiquidityState}))
  }, [dispatch])

  return {
    onRefreshLiquidity
  }
}
