// import BigNumber from "bignumber.js"

// export default function toPrecision(amount:BigNumber,num:number): string {
//     const BN = BigNumber.clone()
//     // 重新制定小数位数，不使用科学计数法
//     BN.config({EXPONENTIAL_AT:[-30,30]})
//     return (new BN(amount.toString()).sd(num,BN.ROUND_HALF_UP)).toString()
// }

// export  function toPrecisionDown(amount:BigNumber,num:number): string {
//     const BN = BigNumber.clone()
//     // 重新制定小数位数，不使用科学计数法
//     BN.config({EXPONENTIAL_AT:[-30,30]})
//     return (new BN(amount.toString()).sd(num,BN.ROUND_DOWN)).toString()
// }

// export  function toFormatPrice(amount:BigNumber,validnum:number,fixNum?:number): string {
//     if(amount.gt(1)){
//        return amount.toFixed(fixNum||2)  
//     }
//     const BN = BigNumber.clone()
//     // 重新制定小数位数，不使用科学计数法
//     BN.config({EXPONENTIAL_AT:[-30,30]})
//     return (new BN(amount.toString()).sd(validnum,BN.ROUND_DOWN)).toString()
// }


import BigNumber from "bignumber.js"

export default function toPrecision(amount:BigNumber,num:number): string {
    const  BN = BigNumber.clone();
    // 重新制定小数位数，不使用科学计数法
    BN.config({EXPONENTIAL_AT:[-30,30]})
    let    tranVal="";
    let    float="";
    const  str=amount.toString().split('.');
    if(str[1]){
        float=(new BN(`0.${str[1]}`).sd(num,BN.ROUND_HALF_UP)).toString();
        if(float==='1'){
            tranVal+=new BN(`${str[0]}`).plus(new BN(`${float}`)).toFixed();
        }else{
            tranVal+=`${str[0]}.${float.split("0.")[1]}`;
        }
       
        return tranVal;
    }
    return amount.toString();

}

export  function toPrecisionDown(amount:BigNumber,num:number): string {
    const BN = BigNumber.clone()
    // 重新制定小数位数，不使用科学计数法
    BN.config({EXPONENTIAL_AT:[-30,30]})
    return (new BN(amount.toString()).sd(num,BN.ROUND_DOWN)).toString()
}

export  function toFormatPrice(amount:BigNumber,validnum:number,fixNum?:number): string {
    const  BN = BigNumber.clone();
    // 重新制定小数位数，不使用科学计数法
    BN.config({EXPONENTIAL_AT:[-30,30]})
    let    tranVal="";
    let    float="";
    const  str=amount.toString().split('.');
    if(str[1]){
        float=(new BN(`0.${str[1]}`).sd(validnum,BN.ROUND_DOWN)).toString();
        if(float==='1'){
            tranVal+=new BN(`${str[0]}`).plus(new BN(`${float}`)).toFixed();
        }else{
            tranVal+=`${str[0]}.${float.split("0.")[1]}`;
        }
        return tranVal;
    }
    return amount.toString();

}


