import React from 'react'
import styled from 'styled-components'
import { ChevronDownIcon,Button, useModal,Modal, CoreIcon } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import RomveDlg from './RomveDlg'
import TransferDlg from './TransferDlg'


interface RemoveBtnProps {
  pairAddress?: string;
  ids:any;
  removedAmounts:any;
  amount:string;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 0px;
  }
`

const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 20px;
`

const StyledSvg = styled.svg`
  stroke: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`

const StyledPath = styled.path`
  fill:none;stroke:inherit;stroke-width:30;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1
`

const SendIcon = () => (
  <StyledSvg width="12" height="12" viewBox="0 0 682.66669 682.66669" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)"><g><g><g transform="translate(286,255.9995)">
      <StyledPath d="M 0,0 C -110.316,0 -212.164,-63.327 -261.499,-161.996 L -271,-183.899 v 32.9 C -271,-1.882 -149.117,120 0,120 V 224.5 L 202,60 0,-105 Z"/>
    </g></g></g></g>
  </StyledSvg>
)

const TransferBtn: React.FC<RemoveBtnProps> = ({ amount,ids,pairAddress,removedAmounts  }) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints()
  const [onPresentSettings] = useModal(<TransferDlg amount={amount} ids={ids} pairAddress={pairAddress} removedAmounts={removedAmounts}/>)
  return (<>
         {!isDesktop?(
          <span title={TranslateString(11135,"Send")}>
            <StyledSvg width="12" height="12" viewBox="0 0 682.66669 682.66669" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onPresentSettings}>
              <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)"><g><g><g transform="translate(286,255.9995)">
                <StyledPath d="M 0,0 C -110.316,0 -212.164,-63.327 -261.499,-161.996 L -271,-183.899 v 32.9 C -271,-1.882 -149.117,120 0,120 V 224.5 L 202,60 0,-105 Z"/>
              </g></g></g></g>
            </StyledSvg>
          </span>
         ):
           <Button  variant="secondary"  size="sm" onClick={onPresentSettings}> 
               {TranslateString(11135,"Send")}
           </Button>
         }
  </>
  )
}

export default TransferBtn
