import React, { useState } from 'react'
import {Flex} from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { ChainId, Currency } from '@pancakeswap-libs/sdk-v2'
import { isMobile } from 'react-device-detect'
import Modal from 'components/Modal'
import { Wrapper,Section, ContentHeader} from 'components/TransactionConfirmationModal/helpers'
import { TranslateString } from 'utils/translateTextHelpers'
import { ConfirmationPendingContent, TransactionErrorContent, TransactionSubmittedContent } from 'components/TransactionConfirmationModal'
import toPrecision from 'utils/getPrecision'
import ConfirmContent from './ConfirmContent'


interface ConfirmModalProps {
    inputCurrency:Currency;
    outputCurrency:Currency;
    inputAmount:string;
    outputAmount:string;
    isOpen:boolean;
    onDismiss:()=>void;
    onConfirm?:()=>void;
    isPendding:boolean;
    swapErrorMessage:string;
    onErroDismiss:()=>void;
    successHash:string;
    onSuccessDismiss:()=>void;
}


const ConfirmModals: React.FC<ConfirmModalProps> = ({  
    inputCurrency,
    outputCurrency,
    inputAmount,
    outputAmount,
    isOpen,
    onDismiss,
    onConfirm,
    isPendding,
    swapErrorMessage,
    onErroDismiss,
    successHash,
    onSuccessDismiss
  }) => {
  const big=(str:string | number)=>{
    return new BigNumber(str)
  }
  
  const resModal=()=>{
    if(successHash){
      return <TransactionSubmittedContent chainId={ChainId.MAINNET} hash={successHash} onDismiss={onSuccessDismiss} />
    }
    if(swapErrorMessage){
      return <TransactionErrorContent onDismiss={onErroDismiss} message={swapErrorMessage} />
    }
    if(isPendding){
      return  <ConfirmationPendingContent onDismiss={onDismiss} 
      pendingText={`Swapping ${toPrecision(new BigNumber(inputAmount),6)} ${inputCurrency.symbol} for ${toPrecision(new BigNumber(outputAmount),6)} ${outputCurrency.symbol}`} />
    }
    if(!successHash && !swapErrorMessage && !isPendding){
      return (
        <Section><ConfirmContent 
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency} 
        inputAmount={inputAmount} 
        outputAmount={outputAmount} 
        onConfirm={onConfirm}  
        onDismiss={onDismiss}  /></Section>
      );
    }

    return "";
  }

  return (<>
    <Modal isOpen={isOpen} maxHeight={90} onDismiss={onDismiss}>
      <Wrapper>
        {resModal()}
        {/* {
          successHash?
          <TransactionSubmittedContent chainId={ChainId.MAINNET} hash={successHash} onDismiss={onSuccessDismiss} />
          :swapErrorMessage?
          (<TransactionErrorContent onDismiss={onErroDismiss} message={swapErrorMessage} />)
          :isPendding?
          (<ConfirmationPendingContent onDismiss={onDismiss} 
          pendingText={`Swapping ${toPrecision(new BigNumber(inputAmount),6)} ${inputCurrency.symbol} for ${toPrecision(new BigNumber(outputAmount),6)} ${outputCurrency.symbol}`} />)
          :!successHash?(<ConfirmContent 
          inputCurrency={inputCurrency}
          outputCurrency={outputCurrency} 
          inputAmount={inputAmount} 
          outputAmount={outputAmount} 
          onConfirm={onConfirm}  
          onDismiss={onDismiss}  />):""
        } */}
      </Wrapper>
    </Modal>
  </>)
}

export default ConfirmModals
