import React, { useState } from 'react'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'
import { Flex,Text,ChevronDownIcon, IconButton, Progress} from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useTokenInfo } from 'hooks/Tokens'
import { getChecksumAddress } from 'utils/addressHelpers'
import toPrecision, { toFormatPrice } from 'utils/getPrecision'
import Logo from 'components/Logo'
import EditBtn from './EditBtn'
import CloseIconBtn from './CloseIconBtn'
import formatDate, { formatDates } from '../utils/tranDate'




interface ListContentProps {
  item: {
    deleted: boolean
    expireTime:string
    id: string
    maxAllowance: string
    operator: string
    pair: {id: string}
    price:string
    remainingAllowance:string
    usedAllowance:string
    stockAmount:string
    moneyAmount:string
  },
  pair:{
    id: string,
    // impl: string,
    money: string,
    stock: string
  }
  activeTab:number
}


const StyleRow= styled(Flex)<{width?:string,isMobile:boolean}>`
      display: flex;
      width:${props => props.width?props.width:'20%'};
      align-items: flex-start;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
      justify-content: center;
      align-items: center;
`
// const StyleImg = styled.img<{isMobile?:boolean}>`
//       display: flex;
// `
const Image = styled(Logo)`
  height: 24px;
  width: auto;
  display: inline-block;
  z-index: 1;
`
const TokenImage = styled(Image)<{isMobile?: boolean}>`
  height: ${props => props.isMobile ? "16px" : '20px'};
  width: ${props => props.isMobile ? "16px" : '20px'};
`
const Label = styled(Text)`
 font-size:13px ;
 word-break: break-all;
`


const StyleRow25 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:25%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`

const StyleDown = styled(Text)`
    display: flex;
    align-items: center;
    width:2%;
`
const ChildenTr = styled(Flex)`
   background-color:${({ theme })=>theme.colors.input};
   border-radius: 10px;
`

const CogIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73 9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.54 5.05 19.27 4.97 19.05 5.05L16.56 6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99996C9.74996 2 9.53996 2.18 9.50996 2.42L9.12996 5.07C8.51996 5.32 7.95996 5.66 7.43996 6.05L4.94996 5.05C4.71996 4.96 4.45996 5.05 4.33996 5.27L2.33996 8.73C2.20996 8.95 2.26996 9.22 2.45996 9.37L4.56996 11.02C4.52996 11.34 4.49996 11.67 4.49996 12C4.49996 12.33 4.52996 12.66 4.56996 12.98L2.45996 14.63C2.26996 14.78 2.21996 15.05 2.33996 15.27L4.33996 18.73C4.45996 18.95 4.72996 19.03 4.94996 18.95L7.43996 17.95C7.95996 18.35 8.51996 18.68 9.12996 18.93L9.50996 21.58C9.53996 21.82 9.74996 22 9.99996 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87 18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.28 19.04 19.54 18.95 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM12 15.5C10.07 15.5 8.49996 13.93 8.49996 12C8.49996 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z"
      fill="currentColor"
    />
  </svg>
)
const StyleColPanel = styled(Flex)`
   border-bottom:2px solid ${({ theme })=>theme.colors.background};
`
const StyleNumberPanel = styled(Flex)`
   overflow: auto;
   ::-webkit-scrollbar{
    width:5px;
    height:5px;
   }
`
const StyleProgress = styled(Flex)`

`


const ListContent: React.FC<ListContentProps> = ({ item ,pair,activeTab}) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints();
  const [status,setStatus]=useState(false);
  const tokenFrom=useTokenInfo(pair.stock);
  const tokenTo=useTokenInfo(pair.money);
 
  const tranNumber=(value:string)=>{
    const val=new BigNumber(value).div(new BigNumber(10).pow(tokenFrom?.decimals||0))
    return toPrecision(val,2)
  }
  const getRate=(direction:boolean,price:string)=>{
    let val=new BigNumber(0);
    const priceValue=new BigNumber(price).div(new BigNumber(10).pow(18));
    if(direction){
       val=new BigNumber(1).times(new BigNumber(priceValue))
    }else{
       val=new BigNumber(1).div(new BigNumber(priceValue))
    }
    return toPrecision(val,2)
  }
  const getUsedAllowance=(value:string)=>{
    const val=new BigNumber(value).div(new BigNumber(10).pow(tokenFrom?.decimals||0))
    return toFormatPrice(val,2)
  }
  const getPercentage=(value:string,value1:string)=>{
    const val=new BigNumber(value).div(new BigNumber(value1)).toFixed(2,BigNumber.ROUND_DOWN);
    return parseFloat(val)*100
  }
  
  const isExpired = parseInt(`${new Date().getTime()/1000}`)>parseInt(item.expireTime);
  const statusString = parseInt(item?.remainingAllowance) === 0 ? TranslateString(13021,'Filled') : (
    item?.deleted ? TranslateString(13047,'Cancelled') : (
      isExpired ? TranslateString(13051,'Expired') : (
        parseInt(item.usedAllowance) > 0 ? TranslateString(13049,'Partially Filled') : TranslateString(13050,'Active')
      )
    )
  );

  const expirySeconds =  parseInt(item.expireTime);
  const expiryString = (expirySeconds > 75000000000) ? TranslateString(13053,"Forever") : formatDates(expirySeconds*1000);
  
  return (
    <>
      {isDesktop?
       <ChildenTr flex={1} p={3} >

          <StyleRow width='15%' isMobile={!isDesktop}>
              <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenFrom?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
              <StyleNumberPanel flexDirection='column' ml={2} >
                <Label color='#a0a0a0'>{tokenFrom?.symbol}</Label>
                <Label>{toPrecision(new BigNumber(item.stockAmount),2)}</Label>
              </StyleNumberPanel>
          </StyleRow>
          <StyleRow width='15%' isMobile={!isDesktop}>
              <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenTo?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
              <StyleNumberPanel flexDirection='column' ml={2}>
                <Label color='#a0a0a0'>{tokenTo?.symbol}</Label>
                <Label>{toPrecision(new BigNumber(item.moneyAmount),2)}</Label>
              </StyleNumberPanel>
          </StyleRow>

            <StyleRow isMobile={!isDesktop} flexDirection='column'>
              <Label>1 {tokenFrom?.symbol} = {getRate(true,item.price)} {tokenTo?.symbol}</Label>
              <Label>1 {tokenTo?.symbol} = {getRate(false,item.price)} {tokenFrom?.symbol}</Label>
            </StyleRow>
            <StyleRow isMobile={!isDesktop} flexDirection='column'>
              <Label>{expiryString}</Label>
            </StyleRow>
            <StyleRow isMobile={!isDesktop} flexDirection='column'>
              <Label>{TranslateString(13048,'Sold')} {getUsedAllowance(item.usedAllowance)} {tokenFrom?.symbol} ({getPercentage(item.usedAllowance,item.maxAllowance)}%)</Label>
              <Label>{statusString}</Label>
            </StyleRow>
            <StyleRow width='10%' isMobile={!isDesktop} >

              {
              activeTab!==4
              &&
              !item.deleted
              &&
              !isExpired
              ?<>
                <EditBtn key={item.id} amount={new BigNumber(item.maxAllowance).div(new BigNumber(10).pow(tokenFrom?.decimals||0)).toFixed()} tokenA={tokenFrom} orderId={item.id.slice(42)} pairAddress={item.pair.id}/>
                <Flex ml={2}/>
                <CloseIconBtn orderId={item.id.slice(42)} pairsAddress={item.pair.id}/>
              </>:""}
             
            </StyleRow>

        
       </ChildenTr>
      : 

      <ChildenTr flex={1} p={2} flexDirection='column'>
        <Flex justifyContent='flex-end' flex={1}>
          {
           activeTab!==4
           && 
           !item.deleted
           && 
           parseInt(`${new Date().getTime()/1000}`)<=parseInt(item.expireTime)
          ?<>
            <EditBtn amount={new BigNumber(item.maxAllowance).div(new BigNumber(10).pow(tokenFrom?.decimals||0)).toFixed()} tokenA={tokenFrom} orderId={item.id.slice(42)} pairAddress={item.pair.id}/>
            <Flex ml={2}/>
            <CloseIconBtn orderId={item.id.slice(42)} pairsAddress={item.pair.id}/>
            </>
          :""}
          
        </Flex>

        <StyleColPanel p={2} flex={1} mb={2}>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13013,"Maker Pays")}</Label>
           <Flex alignItems="center">
             <Flex>
              <Label>{toPrecision(new BigNumber(item.stockAmount),2)} {tokenFrom?.symbol}</Label>
             </Flex>
             <Flex ml={2} mr={2} style={{minWidth:'16px'}}>
               <TokenImage isMobile={!isDesktop} srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenFrom?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
             </Flex>
           </Flex>

          </Flex>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13014,"Maker Receives")}</Label>
           <Flex alignItems="center">
             <Label>{toPrecision(new BigNumber(item.moneyAmount),2)} {tokenTo?.symbol}</Label>
             <Flex ml={2} mr={2} style={{minWidth:'16px'}}>
              <TokenImage isMobile={!isDesktop}  srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenTo?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
             </Flex>
           </Flex>
          </Flex>
        </StyleColPanel>

        <StyleColPanel p={2}  flex={1} mb={2}>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13015,"Rate")}</Label>
           <Label>1 {tokenFrom?.symbol} = {getRate(true,item.price)} {tokenTo?.symbol}</Label>
           <Label>1 {tokenTo?.symbol} = {getRate(false,item.price)} {tokenFrom?.symbol}</Label>
          </Flex>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13016,"Valid Until")}</Label>
           <Label>{expiryString}</Label>

          </Flex>
        </StyleColPanel>

        <Flex p={2}   flex={1} mb={2}>
          <Flex flexDirection='column' flex={1}>
            <Label>{TranslateString(13048,'Sold')} {getUsedAllowance(item.usedAllowance)} {tokenFrom?.symbol} ({getPercentage(item.usedAllowance,item.maxAllowance)}%)</Label>
            <Label>{statusString}</Label>
          </Flex>
        </Flex>

      </ChildenTr>
      
      }
     
    </>
  )
}

export default ListContent
