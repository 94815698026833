import { Card,Modal,ArrowDownIcon, Button, CardBody, Flex, IconButton,Text, useModal } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import CardNav from 'components/CardNav'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import {  BottomGrouping } from 'components/swap/styleds'
import AppBody from 'pages/AppBody'
import SwapHeader from 'pages/SmartSwap/xSwapHeaderx'
import React, {  useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { TranslateString } from 'utils/translateTextHelpers'
import { useCurrencyBalances, useTokenBalance } from 'state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'
import P2pCardNav from 'components/P2pCardNav'
import OrderList from './OrderList'




const StylePanle = styled(Flex)`
  position: relative;
  max-width:880px;
  width: 100%;
  z-index: 5;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding:0px;
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding:30px;
    max-width:880px;
  }
`
const BodyWrapper = styled(Card)`
  position: relative;
  max-width:880px;
  width: 100%;
  z-index: 4;
  padding:16px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    max-width:880px;
  }
  overflow: unset;
`
 const Wrapper = styled.div`
  position: relative;
`
export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const StyleSelectTimePanel = styled(Flex)`
  height: 40px;
`

const P2pOrder = () => {
   const { account } = useWeb3React();
   const [rate,setRate]=useState<any>(1);

   return (<>
           {/* <P2pCardNav activeIndex={1} /> */}
           <BodyWrapper>
                      <Wrapper id="agg-swap-page">
                        <Flex  flexDirection="column">
                              <OrderList/>
                        </Flex>
                      </Wrapper>
           </BodyWrapper>
    </>)
}

export default P2pOrder


