import { Contract, BigNumber } from 'ethers';
import Web3 from 'web3'
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import { getNodeUrl } from 'connectors/index';
import LIMIEX_ABI from '../../constants/abis/LimitEx.json'

function getWeb3() {
    return new Web3(Number(window.web3?.currentProvider?.chainId) === ChainId.MAINNET && window.web3.currentProvider || new Web3.providers.HttpProvider(getNodeUrl()));
  }

export async function getStatusOfOrders(pairAddr: string, orderIds: any[]): Promise<BigNumber[]> {
    try {
        const web3 = getWeb3();
        const contract = new web3.eth.Contract(LIMIEX_ABI as any, pairAddr)
        await contract.methods.getStatusOfOrders(orderIds).call({ gas: 10000000000000000 })
        throw new Error("getOrderResults error")
    } catch (error: any) {
        if (!error.message.includes("data")) {
            return []
        }
        let { data } = error
        data = error.message.slice(error.message.indexOf("data") + 8)
        data = data.slice(0, data.indexOf('"'))
        const result = new Array((data as any).replace("0x", "").length / 64).fill(0).map((_, i) => `0x${(data as any).replace("0x", "").slice(i * 64, (i + 1) * 64)}`)
        result.pop()
        result.shift()
        return result.map(v => BigNumber.from(v))
    }
}

export async function getvalidOrders(pairAddr: string, orderIds: any[]) {
    const availableAllownances = await getStatusOfOrders(pairAddr, orderIds)
    const validOrderIds = orderIds.filter((_, i) => !availableAllownances[i].isZero())
    return {
        validOrderIds,
        availableAllownances: availableAllownances.filter((v) => !v.isZero())
    }
} 