import { Card, Flex,Text,Button, AddIcon, useModal} from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DlgImportToken from './DlgImportToken'
import LiquidityCard from './LiquidityCard'


const StylePanle = styled(Flex)`
  position: relative;
  max-width:880px;
  width: 100%;
  z-index: 5;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding:0px;
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding:30px;
    max-width:880px;
  }
`

const BodyWrapper = styled(Card)`
  position: relative;
  max-width:880px;
  width: 100%;
  z-index: 5;
  padding:16px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    max-width:880px;
  }
`
 const Wrapper = styled.div`
  position: relative;
`
const StyleAddIcon = styled(AddIcon)`
  fill:#fff;
`


const GriDexLiquidityList: React.FC= () => {
    const TranslateString = useI18n();
    const history = useHistory();
    const [onImportDlg] = useModal(<DlgImportToken/>)

    
    return <StylePanle flexDirection="column" >
           <Flex flex={1} mb={3} alignItems="center" justifyContent="space-between">
              <Text>{TranslateString(11128,"Liquidity Overview")}</Text>
              <Flex alignItems="center">       
                 <Button mr={2} size="sm" onClick={onImportDlg}>
                    {TranslateString(11149,"Import")}
                 </Button>
                 <Button  size="sm" onClick={()=>{history.push({ pathname: `/GriDexAddLiquidity`})}}>
                    <StyleAddIcon/>
                    {TranslateString(11127,"New Position")}
                 </Button>
                </Flex>
           </Flex>
          <BodyWrapper>
              <Wrapper id="agg-swap-page">
                <Flex  flexDirection="column">
                      {/* <Flex p={2}>
                          <Text fontSize='14px'>{TranslateString(11129,"Your Position")}(1)</Text>
                      </Flex>
                      <Flex p={2} mt={2}> */}
                        <LiquidityCard />
                      {/* </Flex>
                 */}
                </Flex>
              </Wrapper>
        </BodyWrapper>
      </StylePanle>
}
export default GriDexLiquidityList

