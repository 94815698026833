import { Currency, Trade, TradeType } from '@pancakeswap-libs/sdk-v2'
import React, { useMemo, useState } from 'react'
import { Text , Button, Flex } from '@pancakeswap-libs/uikit'
import { TranslateString } from 'utils/translateTextHelpers'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import { BigNumber } from 'bignumber.js'
import { Repeat } from 'react-feather'
import { StyledBalanceMaxMini } from 'components/swap/styleds'
import toPrecision from 'utils/getPrecision'
import HelpInfo from './HelpInfo'

interface SwapModalFooterProps {
    inputCurrency:Currency;
    outputCurrency:Currency;
    inputAmount:string;
    outputAmount:string;
    onConfirm?:()=>void
    disabledConfirm?:boolean
    minAmount:string;
}
const SwapModalFooter: React.FC<SwapModalFooterProps> = ({
  inputCurrency,
  outputCurrency,
  inputAmount,
  outputAmount,
  onConfirm,
  disabledConfirm,
  minAmount
})=> {

const [btnStatus,setBtnStatus]=useState(true)
const big=(str:string | number)=>{
      return new BigNumber(str)
}

const priceCom=()=>{
        return <Flex>
          {btnStatus?
           `${toPrecision(big(outputAmount).div(big(inputAmount)),6)} ${outputCurrency.symbol} per ${inputCurrency.symbol}`
           :`${toPrecision(big(inputAmount).div(big(outputAmount)),6)} ${inputCurrency.symbol} per ${outputCurrency.symbol}`
           
          }
            <StyledBalanceMaxMini onClick={() => setBtnStatus(!btnStatus)}>
              <Repeat size={14} />
            </StyledBalanceMaxMini>        
        </Flex>
   }

  return (
    <Flex flexDirection='column' pt='24px'>
      <AutoColumn gap="0px">
        <RowBetween align="center">  
          <RowFixed>
             <Text fontSize="14px">
             {TranslateString(10086,'Price')}
             </Text>
          </RowFixed>
          <RowFixed>
             <Text fontSize="14px">
                {priceCom()}
               </Text>
          </RowFixed>
        </RowBetween>

        <HelpInfo
         outputCurrency={outputCurrency}
         outputAmount={outputAmount}
        />
      </AutoColumn>

      <AutoRow>
        <Button
          onClick={onConfirm}
          disabled={disabledConfirm}
        //   variant={severity > 2 ? 'danger' : 'primary'}
          mt="10px"
          id="confirm-swap-or-send"
          fullWidth
        >
             {TranslateString(10118,'Confirm Swap')}
          {/* {severity > 2 ? 'Swap Anyway' : TranslateString(10118,'Confirm Swap')} */}
        </Button>

        {/* {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
      </AutoRow>
    </Flex>
  )
}
export default SwapModalFooter