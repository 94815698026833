import React, { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button, Text, Flex, useModal,Input } from '@pancakeswap-libs/uikit'
import UnlockButton from 'components/ConnectWalletButton';
import { useActiveWeb3React } from 'hooks';
import { addShares } from 'utils/gidex';
import { useGriLiquidityState } from 'state/griDexLiquidity/hooks';
import BigNumber from 'bignumber.js';
import useI18n from 'hooks/useI18n';
import ConfirmModal from './ConfirmModal';
import { getBchAddress, getBenswapGridexRouterAddress } from '../../utils/addressHelpers';
import { useTokenInfo } from '../../hooks/Tokens';
import { useTokenBalance } from '../../state/wallet/hooks';
import { useApprovefuc } from './hooks/useApprovefuc';


interface ButtonTipsProp {
    result:any;
    pircie:string;
  }

  
const ButtonTips: React.FC<ButtonTipsProp>= ({result,pircie}) => {
  const TranslateString = useI18n();
  const { account, chainId } = useActiveWeb3React();
  const {priceInfo,leftRangeTypedValue,rightRangeTypedValue,sharesResult,selectToken,tokenAamountValue,tokenBamountValue}=useGriLiquidityState();

  const tokenA=useTokenInfo(selectToken.tokenA);
  const tokenB=useTokenInfo(selectToken.tokenB);
  const tokenABalance=useTokenBalance(account ?? undefined,tokenA as any);
  const tokenBBalance=useTokenBalance(account ?? undefined,tokenB as any);

  const gridexAddress=getBenswapGridexRouterAddress();
  const A_approve=useApprovefuc(selectToken.tokenA,gridexAddress,tokenAamountValue,tokenA?.decimals||0);
  const B_approve=useApprovefuc(selectToken.tokenB,gridexAddress,tokenBamountValue,tokenB?.decimals||0);
  const [onPresentSettings] = useModal(<ConfirmModal result={result} pircie={pircie}/>)

  const bchAddress=getBchAddress();
    const big=(str:string|0)=>{
     return new BigNumber(str);
    }

    const showTipBtn=():any=>{
        if(!account){
         return <UnlockButton fullWidth />
        }

        // 初始化提示
        if(!(selectToken.tokenA && selectToken.tokenB)){
          return <Button fullWidth disabled>{TranslateString(11119,"Invalid token pair")}</Button>
        }
        
        const rightBool=big(leftRangeTypedValue).gt(big(priceInfo.price)) && big(rightRangeTypedValue).gt(big(priceInfo.price))
        const leftBool=big(leftRangeTypedValue).lt(big(priceInfo.price)) && big(rightRangeTypedValue).lt(big(priceInfo.price))
        
        if(leftBool){
          if(big(tokenBamountValue||0).lte(0)){
            return <Button fullWidth disabled>{TranslateString(11118,"Enter an amount")}</Button>
          }
        }

        if(rightBool){
          if(big(tokenAamountValue||0).lte(0)){
            return <Button fullWidth disabled>{TranslateString(11118,"Enter an amount")}</Button>
          }
        }

        if(!leftBool && !rightBool){
          if(!(big(tokenAamountValue).gt(0) && big(tokenBamountValue).gt(0))){
            return <Button fullWidth disabled>{TranslateString(11118,"Enter an amount")}</Button>
          }
        }
        

        // 权限不足提示
        if(selectToken.tokenA!==bchAddress && selectToken.tokenB!==bchAddress &&
          !A_approve.tokenAllowedStatus && !B_approve.tokenAllowedStatus){
         return <>
            <Button disabled={A_approve.requestedApproval} fullWidth onClick={A_approve.handleApprove}>{TranslateString(564,"Approve")} {tokenA?.symbol}</Button>
            <Button ml={3} disabled={B_approve.requestedApproval} fullWidth onClick={B_approve.handleApprove}>{TranslateString(564,"Approve")} {tokenB?.symbol}</Button>
         </>
        }
        // SBCH 不需要授权
        if(selectToken.tokenA!==bchAddress && !A_approve.tokenAllowedStatus){
          return <Button disabled={A_approve.requestedApproval} fullWidth onClick={A_approve.handleApprove}>{TranslateString(564,"Approve")} {tokenA?.symbol}</Button>
        }
        if(selectToken.tokenB!==bchAddress && !B_approve.tokenAllowedStatus){
          return <Button disabled={B_approve.requestedApproval} fullWidth onClick={B_approve.handleApprove}>{TranslateString(564,"Approve")} {tokenB?.symbol}</Button>
        }

        // 余额不足
        const balanceA=big(tokenABalance?.raw.toString()||"0").div(new BigNumber(10).pow(tokenA?.decimals||0))
        if(balanceA.lt(big(tokenAamountValue))
        ){
           return  <Button style={{opacity:0.2,pointerEvents:'none'}} variant='danger' fullWidth >{tokenA?.symbol} {TranslateString(11117,"Insufficient Balance")}</Button>
        }

        const balanceB=big(tokenBBalance?.raw.toString()||"0").div(new BigNumber(10).pow(tokenB?.decimals||0))
        if(balanceB.lt(big(tokenBamountValue))
        ){
           return  <Button style={{opacity:0.2,pointerEvents:'none'}} variant='danger' fullWidth >{tokenB?.symbol} {TranslateString(11117,"Insufficient Balance")}</Button>
        }

        if(account){
           return <Button fullWidth onClick={onPresentSettings}>{TranslateString(11120,"Confirm to add")}</Button>
        }
        return "";
    } 

  return (
      <Flex flex={1}  pt={2} >
            {showTipBtn()}
      </Flex>
    )
}

export default ButtonTips
