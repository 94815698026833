import multicall from 'utils/multicall';
import BigNumber from 'bignumber.js'
import { isBch, WBCH } from './gridex';
import ERC20_ABI from '../../constants/abis/erc20.json';

const RatioBase = new BigNumber(10 ** 19)
const PriceBase = new BigNumber(2 ** 68)

export async function getStockAndMoney(tokenFrom, tokenTo) {
  if (isBch(tokenFrom)) {
    tokenFrom = WBCH
  }
  if (isBch(tokenTo)) {
    tokenTo = WBCH
  }

  const wbch = "0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04";
  const eben = "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B";
  const Law = "0x0b00366fBF7037E9d75E4A569ab27dAB84759302";
  const BcBCH =
    "0xBc9bD8DDe6C5a8e1CBE293356E02f5984693b195";
  const BcUSDT =
    "0xBc2F884680c95A02cea099dA2F524b366d9028Ba";
  const TanGO =
    "0x73BE9c8Edf5e951c9a0762EA2b1DE8c8F38B5e91";
  const Mist = "0x5fA664f69c2A4A3ec94FaC3cBf7049BD9CA73129";
  const ccUSDT =
    "0x383FF5f3f171cF245A33f650A7E9f0b2F7A8e4FB";
  const bbBUSD =
    "0xbb1Fcb08961d7fc7ab58dC608A0448aa30E66269";
  const bbUSDC =
    "0xbb2A35cc3e3dDb679fe30A82051633bC822e4191";
  const bUSDP =
    "0xbbEDC71c1e982c5416dEcc221B5c9DcA8ff49E94";
  const bbUSDT =
    "0xbbb3700F33fCb64437Dc28A7Beb6b21f5cC76FB9";
  const bbUSDP = "0xbbEDC71c1e982c5416dEcc221B5c9DcA8ff49E94"
  const bbETH =
    "0xbb10B6D11db70f33417b08e0B87042275C933Bb9";
  const bbBTC =
    "0xbbeF77270d6425E113e1E37f008cf141a9FC215A";
  const bcBUSD =
    "0xbC6aEA0c2Cd7bFA073903ebdc23c8aAe79C7c826";
  const bcUSDC =
    "0xBcbd9990dcEC6a64741ea27BeC0cA8ff6B91Bc26";
  const ccUSDC =
    "0x75A695F13e59ddd19a327C8Af98D5a6E379a8105";
  const bcDAI =
    "0xBCCD70794BB199B0993E56Bfe277142246c2f43b";
  const ccDAI =
    "0x3ccb815805453D7828cc887E3cCeF17522C7bBac";
  const defaultMoneyWeights = [bbBUSD, bbUSDC, bUSDP, bbUSDT, BcUSDT, bcBUSD, bcUSDC, bbUSDP, bcDAI, ccUSDT, ccUSDC, ccDAI, wbch, BcBCH, bbBTC, bbETH, eben, Law, TanGO, Mist]
  let [stock, money] = [tokenFrom, tokenTo]
  let stockIndex = defaultMoneyWeights.findIndex(x => x === stock)
  let moneyIndex = defaultMoneyWeights.findIndex(x => x === money)
  stockIndex = stockIndex === -1 ? 1000000000000 : stockIndex
  moneyIndex = moneyIndex === -1 ? 1000000000000 : moneyIndex

  if (stockIndex < moneyIndex) {
    [stock, money] = [tokenTo, tokenFrom]
  }
  if (stockIndex === moneyIndex) {
    const decimals = (await multicall(ERC20_ABI, [tokenFrom, tokenTo].map((v) => ({
      address: v,
      name: 'decimals',
    })))).map((v) => parseInt(v))
    if (decimals[0] > decimals[1]) {
      [stock, money] = [tokenTo, tokenFrom]
    } else if (decimals[0] < decimals[1]) {
      [stock, money] = [tokenFrom, tokenTo]
    } else {
      [stock, money] = tokenFrom.toLowerCase() < tokenTo.toLowerCase() ? [tokenFrom, tokenTo] : [tokenTo, tokenFrom]
    }
  }

  let isValidPair = false
  if (stock === tokenFrom || (isBch(tokenFrom) && stock === WBCH)) {
    isValidPair = true
  }
  return { stock, money, isValidPair }
}



export function getTruePrice(params, tokenFrom, tokenTo, price, includeDecimals = false) {  // 没有被priceBase的真实价格
  if (isBch(tokenFrom)) {
    tokenFrom = WBCH
  }
  if (params.stock === tokenFrom) {
    let truePrice = new BigNumber(price)
    if (includeDecimals) {
      truePrice = truePrice.multipliedBy(params.priceMul).dividedBy(params.priceDiv)
    }
    return truePrice.dividedBy(PriceBase)
  }
  let truePrice = price.toString() !== "0" ? PriceBase.multipliedBy(PriceBase).div(price.toString()) : new BigNumber(0);
  truePrice = truePrice.multipliedBy(params.priceDiv).dividedBy(params.priceMul)
  return truePrice.dividedBy(PriceBase)
}

export function soreGrids_addAmounts(lowGrid, midGrid, highGrid, grids_addAmounts) {
  if (lowGrid > midGrid) {
    // 只有stock
    return grids_addAmounts;
  }
  if (highGrid < midGrid) {
    // 只有money
    return [...grids_addAmounts].reverse();
  }
  // 先midgrid 后onlyMoneyGrid  后onlyStockGrid
  return [grids_addAmounts[midGrid - lowGrid], ...grids_addAmounts.slice(0, midGrid - lowGrid).reverse(), ...grids_addAmounts.slice(midGrid - lowGrid + 1, highGrid - lowGrid + 1)]
}