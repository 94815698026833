import React, { ReactNode, useContext, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button,Text, Flex, useModal, Input } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'

interface PageHeaderProps {
    tokenA?:string;
    tokenB?:string;
}

const StylePanel= styled(Flex)`
  background-color:${({ theme })=>theme.isDark?"#383633f2":"#faf9faf0"};
  border:1px solid ${({ theme })=>theme.colors.borderColor};
  border-radius: 20px;
 
  .textColor{
    color: ${({ theme })=>theme.colors.primary};
  }
`
const StyleButton= styled(Button)`
  font-weight: 500;
  font-size: 13px;
`

interface EfficiencyWaringProps {
  sureAllRange: () => void;
}
const EfficiencyWaring: React.FC<EfficiencyWaringProps>= ({sureAllRange}) => {
  const TranslateString = useI18n();

  return (
        <StylePanel p={3} flex={1} flexDirection='column'  justifyContent='space-evenly'>
             <Text className='textColor' fontSize="15px">{TranslateString(11146,"Efficiency Warning")}</Text>
             <Text className='textColor' mt={2} fontSize="13px">{TranslateString(11147,"Full range positions will earn less fees than concentrated positions. Please read docs to learn more.")}</Text>
             <StyleButton  mt={2} variant='primary' size="sm" onClick={sureAllRange}>
                {TranslateString(11148,"I understand")}
             </StyleButton>
        </StylePanel>
    )
}

export default EfficiencyWaring
