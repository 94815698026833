import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {addNotif, reomoveNotif }  from './actions'
import { AppDispatch, AppState } from '../index'


export function useNotifState(): AppState['GlobalNotifications'] {
  return useSelector<AppState, AppState['GlobalNotifications']>((state) => state.GlobalNotifications)
}

export function useNotifStateHandlers(): {
  onAddNotif:(notifItem:{
    id?:number
    title?:string
    variant?:any|string
    content?:any
    i18n?:{index:number,value:string}
  })=>void
  onRemoveNotif:(id:number)=>void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onAddNotif = useCallback((notifItem:any) => {
    dispatch(addNotif({notifItem}))
  }, [dispatch])
  const onRemoveNotif = useCallback((id:number) => {
    dispatch(reomoveNotif({id}))
  }, [dispatch])
  
  return {
    onAddNotif,
    onRemoveNotif
  }
}
