import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex,ChevronDownIcon,Button, useModal,Modal, CloseIcon } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useI18n from 'hooks/useI18n'
import { Repeat } from 'react-feather'




interface RemoveBtnProps {
  orderId:string
  pairsAddress:string
}

const Container = styled(Flex)<{isCopy?:boolean}>`
    border-radius: 5px;
    padding: 4px;
    color: ${({ theme })=>theme.colors.primary};
    /* width: 22px; */
    height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
      background-color: ${({ theme })=>theme.colors.background};
    }
    cursor: pointer;
    
    font-size: 13px;
`



const SwapIcon: React.FC<RemoveBtnProps> = ({pairsAddress,orderId}) => {
  const TranslateString = useI18n()
  const openSwapHref=()=>{
        window.open(`${window.location.origin}/#/P2pSwap/${pairsAddress}/${orderId}`)
  }
  return (<Container title={TranslateString(13071,"View Trading Page")} onClick={openSwapHref}>
           {TranslateString(13069,"Trade")}
          </Container>
  )
}

export default SwapIcon
