import BigNumber from "bignumber.js";
import { useCallback, useMemo, useState } from "react";
import {useAllowance} from "../../../hooks/useAllowance";
import {useTokenApprove} from "../../../hooks/useApprove";
import { useERC20 } from "../../../hooks/useContractV2";

export  const useApprovefuc = (tokenAddress: string, spenderAddress: string,amount:string,decimals:number) => {
    
    const [approved, setApproved] = useState(0)
    const tokenContract=useERC20(tokenAddress);
    const tokenAllowance = useAllowance(tokenContract, spenderAddress,approved);
    const onTokenApprove = useTokenApprove(tokenContract, spenderAddress);
    const [requestedApproval, setRequestedApproval] = useState(false);
    // const tokenAllowedStatus = tokenAllowance && !tokenAllowance.isLessThan(new BigNumber(10).pow(77));
    
    const tokenAllowedStatus =useMemo(()=>
    tokenAllowance && !tokenAllowance.isLessThan(
     new BigNumber(amount).times(new BigNumber(10).pow(decimals||0))
    ),
    [amount,tokenAllowance,decimals]);
    // 授权
    const handleApprove = useCallback(async () => {
        try {
            setRequestedApproval(true)
            const txHash = await onTokenApprove()
            setRequestedApproval(false)
            if (txHash) {setApproved(approved + 1);}
        } catch (e) {
            console.error(e)
        }
    }, [onTokenApprove,approved])
  
    return {approved,setApproved,handleApprove,tokenAllowedStatus,requestedApproval}
  }
  export default useApprovefuc