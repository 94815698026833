import { useEffect, useMemo, useState } from 'react'
import { AbiItem } from 'web3-utils'
import { ContractOptions } from 'web3-eth-contract'
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import ERC20_ABI from '../constants/abis/erc20.json'
import LIMIT_FACTORY_ABI from '../constants/abis/LimitExFactory.json'
import LIMIEX_ABI from '../constants/abis/LimitEx.json'


import P2PEX_ABI from '../constants/abis/P2PEx.json'
import P2P_EXFACTORY_ABI from '../constants/abis/P2PExFactory.json'

import useWeb3 from './useWeb3';
import { getLimitFactoryAdress, getP2PExFactoryAddress } from '../utils/addressHelpers';


const useContract = (abi: AbiItem, address: string, contractOptions?: ContractOptions) => {
  const web3 = useWeb3()
  const [contract, setContract] = useState(new web3.eth.Contract(abi, address, contractOptions))

  useEffect(() => {
    setContract(new web3.eth.Contract(abi, address, contractOptions))
  }, [abi, address, contractOptions,web3])

  return contract
}

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const erc20Abi = (ERC20_ABI as unknown) as AbiItem
  return useContract(erc20Abi, address)
}
export const useLimitFactoryContract=()=>{
  const abi = (LIMIT_FACTORY_ABI as unknown) as AbiItem
  return useContract(abi,getLimitFactoryAdress())
}

export const useLimitExContract=(address:string)=>{
  const abi = (LIMIEX_ABI as unknown) as AbiItem
  return useContract(abi,address)
}

export const useP2pFactoryEx=()=>{
  const abi = (P2P_EXFACTORY_ABI as unknown) as AbiItem
  return useContract(abi,getP2PExFactoryAddress())
}

export const useP2pEx=(address:string)=>{
  const abi = (P2PEX_ABI as unknown) as AbiItem
  return useContract(abi,address)
}

export default {useERC20}
