import { createReducer } from '@reduxjs/toolkit'
import { selectDex} from './actions'

export interface SmartSwapState {
  readonly dexs: Array<boolean>
}

const initialState: SmartSwapState = {
  dexs:[true,true,true,true,true,true,true,true,true]
}
// dexs:['MISTSWAP','TANGOSWAP','LAWSWAP','COWSWAP','ONEBCH','TROPICALFINANCE','VERSEDEX','EMBERSWAP']

export default createReducer<SmartSwapState>(initialState, (builder) =>
  builder
    .addCase(selectDex, (state, { payload: { dexs } }) => {
      return {...state,dexs:[...dexs]}
    })
)
