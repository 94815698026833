import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'

import { selectDex } from './actions'

export function useGriDexSwapState(): AppState['griDexSwap'] {
  return useSelector<AppState, AppState['griDexSwap']>((state) => state.griDexSwap)
}

export function useGriDexSwapStateHandlers(): {
  onSelectDex:(dexs:Array<boolean>)=>void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onSelectDex = useCallback((dexs:Array<boolean>) => {
    dispatch(selectDex({dexs}))
  }, [dispatch])

  return {
    onSelectDex
  }
}
