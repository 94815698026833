// AMM Related
export const DEX = {
    BENSWAP: "ben",
    MISTSWAP: "mist",
    COWSWAP: "cow",
    TANGOSWAP: "tango",
    ONEBCH: "onebch",
    TROPICALFINANCE: "tropical",
    EMBERSWAP: "ember",
    LAWSWAP: "law",
    VERSEDEX: "verse"
}

export const FACTORY = {
    [DEX.BENSWAP]: {
        address: "0x8d973bAD782c1FFfd8FcC9d7579542BA7Dd0998D",
        name: "BenSwap"
    },
    [DEX.MISTSWAP]: {
        address: "0x6008247F53395E7be698249770aa1D2bfE265Ca0",
        name: "MistSwap"
    },
    [DEX.COWSWAP]: {
        address: "0x72cd8c0B5169Ff1f337E2b8F5b121f8510b52117",
        name: "CowSwap"
    },
    [DEX.TANGOSWAP]: {
        address: "0x2F3f70d13223EDDCA9593fAC9fc010e912DF917a",
        name: "TangoSwap"
    },
    [DEX.ONEBCH]: {
        address: "0x3dC4e6aC26df957a908cfE1C0E6019545D08319b",
        name: "1BCH"
    },
    [DEX.TROPICALFINANCE]: {
        address: "0x138504000feaEd02AD75B1e8BDb904f51C445F4C",
        name: "TropicalFinance"
    },
    [DEX.EMBERSWAP]: {
        address: "0xE62983a68679834eD884B9673Fb6aF13db740fF0",
        name: "EmberSwap"
    },
    [DEX.LAWSWAP]: {
        address: "0x3A2643c00171b1EA6f6b6EaC77b1E0DdB02c3a62",
        name: "LawSwap"
    },
    [DEX.VERSEDEX]: {
        address: "0x16bc2B187D7C7255b647830C05a6283f2B9A3AF8",
        name: "Verse"
    }
};