import { useWeb3React } from '@web3-react/core'
import useENSName from './useENSName'

const useProfile = (): any | null => {
    const { account } = useWeb3React()

  const name = useENSName(account || undefined)?.ENSName;

  return name ?
    {
      username: name,
      image: `https://metadata.bch.domains/smartbch/avatar/${name}`,
      profileLink: `https://app.bch.domains/name/${name}`,
      noProfileLink: `https://app.bch.domains/name/address/${account}`
    } : null;

}

export default useProfile
