import React, { useEffect, useState } from 'react'
import { Checkbox, Flex, InjectedModalProps, Modal, Toggle,Text } from '@pancakeswap-libs/uikit'
import { TranslateString } from 'utils/translateTextHelpers'
import styled from 'styled-components'
import { useSmartSwapActionHandlers, useSmartSwapState } from 'state/smartSwap/hooks'
import SlippageToleranceSetting from 'components/PageHeader/SlippageToleranceSetting'
import TransactionDeadlineSetting from 'components/PageHeader/TransactionDeadlineSetting'
import ExpertModeSetting from 'components/PageHeader/ExpertModeSetting'
import { useLimitListState, useLimitListStateHandlers } from 'state/limit/hooks'

const SwapList = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.input};
  border-radius: 20px;
`


type SettingsModalProps = {
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal: React.FC<InjectedModalProps>  = ({ onDismiss = defaultOnDismiss }: SettingsModalProps) => {
  const swap=[
    {name:'EmberSwap',value:0},
    {name:'Verse',value:1},
    {name:'TropicalFinance',value:2},
    {name:'1BCH',value:3},
    {name:'CowSwap',value:4},
    {name:'LawSwap',value:5},
    {name:'TangoSwap',value:6},
    {name:'MistSwap',value:7},
    {name:'BenSwap',value:8}
  ]


  // 读取redux 全局变量
  const { dexs }=useSmartSwapState();
  const [selectSwap,setSelectSwap]=useState<Array<boolean>>([...dexs]);
  const { onSetAllowPartDeal }=useLimitListStateHandlers();
  const { allowPartDeal }=useLimitListState();

  // const changeCheck=(e)=>{
  //       if(e.target.checked){
  //         const select=selectSwap;
  //         select[e.target.value]=true;
  //         setSelectSwap([...select])
  //         onSelectDex([...select])
  //       }else{
  //         const select=selectSwap;
  //         select[e.target.value]=false;
  //         setSelectSwap([...select])
  //         onSelectDex([...select])
  //       }
  //       console.log('结果',selectSwap)
  // }

  return (
     <Modal title={TranslateString(10013,'Settings')} onDismiss={onDismiss}>
             {/* checked={isChecked} onChange={toggle} */}
{/*       
       <SlippageToleranceSetting />
       <TransactionDeadlineSetting /> */}
       {/* <TransactionDeadlineSetting /> */}
       {/* <ExpertModeSetting /> */}
        
      <Flex flexDirection='column'>
        <Flex flexDirection='column'>
          <Label>{TranslateString(13036,'Allow Partial Success')}</Label>
          <Toggle checked={allowPartDeal} onChange={()=>{onSetAllowPartDeal(!allowPartDeal)}} />
        </Flex>
      </Flex>
      
      
 
       
       {/* <SlippageToleranceSetting />
       <TransactionDeadlineSetting />
       <ExpertModeSetting /> */}
     </Modal>
  )
}

export default SettingsModal

const Label = styled(Text)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  font-weight: 600;
`