import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'
import { Flex,Text,ChevronDownIcon, IconButton, Progress, LinkExternal} from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useTokenInfo } from 'hooks/Tokens'
import { get0xAddress, getChecksumAddress } from 'utils/addressHelpers'
import toPrecision, { toFormatPrice } from 'utils/getPrecision'
import Logo from 'components/Logo'
import { Repeat } from 'react-feather'
import CloseIconBtn from './CloseIconBtn'
import formatDate, { formatDates } from '../utils/tranDate'
import ShareIconBtn from './ShareIconBtn'
import SwapIcon from './SwapIcon'





interface ListContentProps {
  item: {
    target: string | undefined
    deleted: boolean
    expireTime:string
    id: string
    orderId:string
    maxAllowance: string
    operator: string
    pair: {id: string}
    price:string
    remainingAllowance:string
    usedAllowance:string
    stockAmount:string
    moneyAmount:string
    filled:boolean
  },
  pair:{
    id: string,
    money: string,
    stock: string
  }
  activeTab:number
}


const StyleRow= styled(Flex)<{width?:string,isMobile:boolean}>`
      display: flex;
      width:${props => props.width?props.width:'10%'};
      align-items: flex-start;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
      justify-content: center;
      align-items: center;
`
// const StyleImg = styled.img<{isMobile?:boolean}>`
//       display: flex;
// `
const Image = styled(Logo)`
  height: 24px;
  width: auto;
  display: inline-block;
  z-index: 1;
`
const TokenImage = styled(Image)<{isMobile?: boolean}>`
  height: ${props => props.isMobile ? "16px" : '20px'};
  width: ${props => props.isMobile ? "16px" : '20px'};
`
const Label = styled(Text)`
 font-size:13px ;
`
const AddressLabel = styled(Label)`
 word-break: break-all;

`

const ChildenTr = styled(Flex)`
   background-color:${({ theme })=>theme.colors.input};
   border-radius: 10px;
`

const StyleColPanel = styled(Flex)`
   border-bottom:2px solid ${({ theme })=>theme.colors.background};
`
const StyleMtColPanel = styled(Flex)`
   border-top:2px solid ${({ theme })=>theme.colors.background};
`

const StyleNumberPanel = styled(Flex)`
   overflow: auto;
   ::-webkit-scrollbar{
    width:5px;
    height:5px;
   }
`
const StyleLabel = styled(Label)`
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: default;
`
const StyleAddressLabel = styled.a`
    color: ${({ theme })=>theme.colors.primary};
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
`
const StyleLinkExternal = styled(LinkExternal)`
   font-weight: unset;
   font-size: 13px;
`



const ListContent: React.FC<ListContentProps> = ({ item ,pair,activeTab}) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints();
  const tokenFrom=useTokenInfo(pair.stock);
  const tokenTo=useTokenInfo(pair.money);
 
  const getRate=(direction:boolean,price:string)=>{
    let val=new BigNumber(0);
    const priceValue=new BigNumber(price).div(new BigNumber(10).pow(18));
    if(direction){
       val=new BigNumber(1).times(new BigNumber(priceValue))
    }else{
       val=new BigNumber(1).div(new BigNumber(priceValue))
    }
    return toPrecision(val,2)
  }

  
  const isExpired = parseInt(`${new Date().getTime()/1000}`)>parseInt(item.expireTime);
  const statusString = item?.filled=== true ? TranslateString(13021,'Filled') : (
    item?.deleted ? TranslateString(13047,'Cancelled') : (
      isExpired ? TranslateString(13051,'Expired') : TranslateString(13050,'Active')
    )
  );

  const expirySeconds =  parseInt(item.expireTime);
  const expiryString = (expirySeconds > 75000000000) ? TranslateString(13053,"Forever") : formatDates(expirySeconds*1000);
  const addressComponent=(address:string|undefined)=>{
    return address===get0xAddress()?
      <StyleLabel title={TranslateString(13067,"Everyone")}>{TranslateString(13067,"Everyone")}</StyleLabel>
      :
      <StyleLinkExternal  title={address} href={`https://smartscout.cash/address/${address}`}>{address?.substring(0, 8)}</StyleLinkExternal>

      // <StyleAddressLabel href={`https://smartscout.cash/address/${address}`} target="_blank"  title={address}>{address?.substring(0, 8)}</StyleAddressLabel>
  }
  return (
    <Flex flex={1} flexDirection='column'>
      {isDesktop?
       <ChildenTr flex={1} p={3} >
          <StyleRow width='15%'  isMobile={!isDesktop}>
              <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenFrom?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
              <StyleNumberPanel flexDirection='column' ml={2} >
                <Label color='#a0a0a0'>{tokenFrom?.symbol}</Label>
                <Label>{toPrecision(new BigNumber(item.stockAmount),2)}</Label>
              </StyleNumberPanel>
          </StyleRow>
          <StyleRow width='15%'   isMobile={!isDesktop}>
              <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenTo?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
              <StyleNumberPanel flexDirection='column' ml={2}>
                <Label color='#a0a0a0'>{tokenTo?.symbol}</Label>
                <Label>{toPrecision(new BigNumber(item.moneyAmount),2)}</Label>
              </StyleNumberPanel>
          </StyleRow>

            <StyleRow width='20%'   isMobile={!isDesktop} flexDirection='column'>
              <Label>1 {tokenFrom?.symbol} = {getRate(true,item.price)} {tokenTo?.symbol}</Label>
              <Label>1 {tokenTo?.symbol} = {getRate(false,item.price)} {tokenFrom?.symbol}</Label>
            </StyleRow>
            <StyleRow width='15%' flex={2} isMobile={!isDesktop} flexDirection='column'>
              <Label>{expiryString}</Label>
            </StyleRow>
            {
            (activeTab===1 || activeTab===4) &&
            <StyleRow width='10%' style={{maxWidth:'160px'}} flex={2} isMobile={!isDesktop} flexDirection='column'>
              {addressComponent(item?.target)}
            </StyleRow>
            }
            
            {
            (activeTab===2 || activeTab===5 || activeTab===3) &&
            <StyleRow width='10%' style={{maxWidth:'160px'}} flex={2} isMobile={!isDesktop} flexDirection='column'>
               {addressComponent(item?.operator)}
            </StyleRow>
            }

           {
            activeTab===4 &&
            <StyleRow flex={1} isMobile={!isDesktop} flexDirection='column'>
              <Label>{statusString}</Label>
            </StyleRow>
            }
            
            { activeTab===1 || activeTab===2 || activeTab===5?
            <StyleRow flex={1} isMobile={!isDesktop} >
                {(activeTab===2 || activeTab===5) && <SwapIcon pairsAddress={item.pair.id} orderId={item.orderId}/>}
              
              
                { activeTab===1 &&  
                 <>
                  <ShareIconBtn pairsAddress={item.pair.id} orderId={item.orderId}/>
                  <Flex ml={2}/> 
                  <CloseIconBtn orderId={item.id.slice(42)} pairsAddress={item.pair.id}/>
                </> }
            </StyleRow>:""}

        
       </ChildenTr>
      : 

      <ChildenTr flex={1} p={2} flexDirection='column'>
        <Flex justifyContent='flex-end' flex={1}>
          {
          activeTab===1 || activeTab===2 || activeTab===5
          ?<>
            {(activeTab===2 || activeTab===5) && <SwapIcon pairsAddress={item.pair.id} orderId={item.orderId}/>}
            {activeTab===1 && 
            <>
              <ShareIconBtn pairsAddress={item.pair.id} orderId={item.orderId}/>
              <Flex ml={2}/>
              <CloseIconBtn orderId={item.id.slice(42)} pairsAddress={item.pair.id}/>
            </>}
            </>
          :""}

        </Flex>

        <StyleColPanel p={2} flex={1} mb={2}>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13013,"Maker Pays")}</Label>
           <Flex alignItems="center">
            <Label>{toPrecision(new BigNumber(item.stockAmount),2)} {tokenFrom?.symbol}</Label>
              <Flex ml={2} mr={2} style={{minWidth:'16px'}}>
                <TokenImage isMobile={!isDesktop} srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenFrom?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13014,"Maker Receives")}</Label>
           <Flex alignItems="center">
             <Label>{toPrecision(new BigNumber(item.moneyAmount),2)} {tokenTo?.symbol}</Label>
             <Flex ml={2} mr={2} style={{minWidth:'16px'}}>
              <TokenImage isMobile={!isDesktop}  srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenTo?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt='0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B Logo' />
             </Flex>
           </Flex>
          </Flex>
        </StyleColPanel>

        <StyleColPanel p={2}  flex={1} mb={2}>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13015,"Rate")}</Label>
           <Label>1 {tokenFrom?.symbol} = {getRate(true,item.price)} {tokenTo?.symbol}</Label>
           <Label>1 {tokenTo?.symbol} = {getRate(false,item.price)} {tokenFrom?.symbol}</Label>
          </Flex>
          <Flex flexDirection='column' flex={1}>
           <Label color='#a0a0a0'>{TranslateString(13016,"Valid Until")}</Label>
           <Label>{expiryString}</Label>

          </Flex>
        </StyleColPanel>

        <Flex p={2}   flex={1} mb={2}>

          {(activeTab===1 || activeTab===4) &&
          <Flex flexDirection='column'  flex={1}>
            <Label color='#a0a0a0'>{TranslateString(13057,"Taker Address")}</Label>
            <AddressLabel title={item?.target}>{item?.target===get0xAddress()?TranslateString(13067,"Everyone"):item?.target}</AddressLabel>
          </Flex>
          }

          {(activeTab===2 || activeTab===5 ||  activeTab===3) &&
          <Flex flexDirection='column' flex={1}>
            <Label color='#a0a0a0'>{TranslateString(13070,"Maker Address")}</Label>
            <AddressLabel title={item?.operator}>{item?.operator}</AddressLabel>
          </Flex>
          }

         
          
        </Flex>

        {
            activeTab===4 && 
            <StyleMtColPanel p={2}  flex={1} mb={2}>
              <Flex flex={1} flexDirection='column'>
                  <Label color='#a0a0a0'>{TranslateString(13017,"Status")}</Label>
                  <Label>{statusString}</Label>
                </Flex>
            </StyleMtColPanel>
        }
      </ChildenTr>
      
      }

    </Flex>
  )
}

export default ListContent
