import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Tag,Heading, IconButton, Text, Flex, useModal, Input } from '@pancakeswap-libs/uikit'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints';
import useI18n from 'hooks/useI18n';
import { useHistory } from 'react-router-dom';
import { useGriLiquidityActionHandlers, useGriLiquidityState, useTokenEchangeInfo } from '../../state/griDexLiquidity/hooks';
import { useTokenInfo } from '../../hooks/Tokens';





interface PageHeaderProps {
    onChange:(typedValue: number) => void
    status:number;
}

const StylePanel= styled(Flex)`
  /* background-color:${({ theme })=>theme.colors.background}; */
  border-radius: 20px;
`
const StyleTokenFlex= styled(Flex)`
  background-color:${({ theme })=>theme.isDark?theme.colors.background:theme.colors.input};
  border-radius: 10px;
  padding: 3px;
`
const StyleText= styled(Text)`
  cursor: pointer;
  padding:6px 10px;
  background-color:${({ theme })=>theme.isDark?theme.colors.background:theme.colors.input};

  border-radius: 6px;
`


const BuySwitchBtn: React.FC<PageHeaderProps>= ({onChange,status}) => {
  const TranslateString = useI18n();
  const [state,setState]=useState(status);
  const theme = useContext(ThemeContext);  
  const [toggltData,setToggltData]=useState<Array<any>>([]);
  const handCheckout=(t,t1)=>{
       onChange(t);
       setState(t)
  }

  return (
        <StylePanel  
             flex={1} 
             alignItems="center" 
             justifyContent="center" >
             <StyleTokenFlex mr={2} >
              <StyleText onClick={()=>{handCheckout(2,(toggltData[0] as any)?.address)}} style={{backgroundColor:state===2?(theme.isDark?"rgb(94 93 93)":"#fff"):""}} fontSize='12px'>{TranslateString(13082,"To Me")}</StyleText>
              <StyleText onClick={()=>{handCheckout(5,(toggltData[1] as any)?.address)}} style={{backgroundColor:state===5?(theme.isDark?"rgb(94 93 93)":"#fff"):""}} fontSize='12px'>{TranslateString(13083,"To Everyone")}</StyleText>
             </StyleTokenFlex>
        </StylePanel>
    )
}

export default BuySwitchBtn
