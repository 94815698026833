 function formatDate(millinSeconds){
    const date = new Date(millinSeconds);
    const monthArr = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"];
    const suffix =["st","nd","rd","th"];
    
    const year = date.getFullYear(); // 年
    const month = monthArr[date.getMonth()]; // 月
    let ddate:any= date.getDate(); // 日
    
    if(ddate % 10 < 1 || ddate % 10 > 3) {
      ddate += suffix[3];
    }else if(ddate % 10 === 1) {
      ddate +=  suffix[0];
    } else if(ddate % 10 === 2) {
      ddate += suffix[1];
    }else {
      ddate +=suffix[2];
    }
    return `${ddate} ${month} ${year}`
  }

  export function formatDates(strDate){
    const date = new Date(strDate);
    const Y = `${date.getFullYear()}-`;
    const M = (date.getMonth()+1 < 10 ? `0${(date.getMonth()+1)}-`: `${(date.getMonth()+1)}  -`);
    const D = `${date.getDate() }  `;
    const h = `${date.getHours() }  :`;
    const m = `${date.getMinutes()}  :`;
    const s = date.getSeconds(); 
    return Y+M+D+h+m+s;
  }



  export default formatDate;